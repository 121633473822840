import React from 'react';
import {observer} from 'mobx-react'
import {classNames as cn} from "helpers";
import FileUploader from "./files/FileUploader";
import Checkbox from "../../Checkbox";
import MediaSelector from "./StudyTest/TestQuestion/components/MediaSelector";
import SelectCustom from "../../Select/SelectCustom";
import st from "./Quad.module.scss"
import asideModalStore from "Stores/asideModalStore";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormInput from "components/UI/AsideModal/Form/FormInput";

const AsideQuad = () => {
    const {modalSettings: ms, modalValues: values, changeValue: changeValues, check} = asideModalStore

    const onChangeTextHandler = (e, field) => {
        let new_val = e.target.value
        if (typeof field.onChange === 'string') {
            switch (field.onChange) {
                case 'int':
                    new_val = +e.target.value
                    break;
                default:
                    break;
            }
        }
        if (typeof field.onChange === 'function') {
            new_val = field.onChange(values[field.name], e.target.value)
        }
        changeValues(new_val, field.name)
    }

    const renderField = field => {
        switch (field.type) {
            case 'text':
                return <div className="super-modal__form-field">
                    {field.label &&
                        <div className={cn({
                            'super-modal__form-label': true,
                            'super-modal__form-label--required': field.required,
                            [`${st.label}`]: true
                        })}>{field.label}</div>}
                    <div>
                        <div className={st.groupForm}>
                            <FormField>
                                <FormInput isError={check(field.name)}>
                                    <input type="text"
                                           name={field.name}
                                           placeholder={field.placeholder}
                                           value={field.getValue ? field.getValue(values[field.name]) : values[field.name] || ''}
                                           onChange={e => onChangeTextHandler(e, field)}
                                    />
                                </FormInput>
                            </FormField>
                        </div>
                    </div>
                </div>
            case 'textarea':
                return <div className="super-modal__form-field">
                    {field.label &&
                        <div className={cn({
                            'super-modal__form-label': true,
                            'super-modal__form-label--required': field.required,
                            [`${st.label}`]: true
                        })}>{field.label}</div>}
                    <div>
                        <textarea name={field.name}
                                  placeholder={field.placeholder}
                                  value={values[field.name] || ''}
                                  onChange={e => onChangeTextHandler(e, field)}
                                  className={check(field.name) ? 'super-modal__input--error' : ''}
                        />
                    </div>
                </div>
            case 'file-upload':
                return <FileUploader onUploadEnd={o => {
                    changeValues(o.file, field.name)
                }} error={check(field.name)}/>
            case 'media-upload':
                return <MediaSelector types={field.types || ['jpg', 'gif', 'png']}
                                      value={values[field.name] || ''}
                                      onChange={val => changeValues(val, field.name)}/>
            case 'checkbox':
                return <div>
                    <Checkbox id={field.name}
                              label={field.label}
                              checked={values[field.name] || false}
                              isChecked={() => changeValues(!values[field.name], field.name)}/>
                </div>

            case 'radioList':
                return <form className={st.radioList}>{field.list.map((item, key) =>
                    <div key={key} className={st.radioList__item} onClick={() => {
                        changeValues(item.value, field.name)
                    }}>
                        <input className={st.radioInput} type="radio" checked={item.value === values[field.name]}/>
                        <label>{item.name}</label>
                    </div>)}
                </form>

            case 'select':
                return <div>
                    <SelectCustom error={check(field.name)}
                                  placeholder={field.placeholder}
                                  options={field.options}
                                  apiLink={field.apiLink}
                                  apiConfParams={field.apiConfParams}
                                  apiGetResult={field.apiGetResult}
                                  optionValueKey={field.optionValueKey}
                                  optionLabelKey={field.optionLabelKey}
                                  sortKey={field.sortKey}
                                  valueID={values[field.name]}
                                  onChange={o => field.onChange ?
                                      field.onChange(o, changeValues) :
                                      changeValues(o[field.optionValueKey || 'id'], field.name)}
                                  isSearchable={field.isSearchable}
                    />
                </div>
            default:
                return null;
        }
    }

    return (
        <div className="super-modal__form-column">{Array.isArray(ms.fields) && ms.fields.map((field, i) =>
            <div key={i}>{renderField(field)}</div>)}
        </div>
    );
}

export default observer(AsideQuad)
