import React from 'react'
import styles from './ChatMessagesList.module.scss'
import ChatMessage from '../ChatMessage/ChatMessage'
import { formatDateChatAI } from '../helpers'
import { observer } from 'mobx-react'

const ChatMessagesList = observer(({ messages }) => {
	let lastMessageDate = null

	return (
		<>
			{messages
				.slice()
				.reverse()
				.map((msg, index) => {
					const messageDate = new Date(msg.date)
					const showSeparator = !lastMessageDate || messageDate.toDateString() !== lastMessageDate.toDateString()

					if (showSeparator) lastMessageDate = messageDate

					return (
						<React.Fragment key={index}>
							{showSeparator && (
								<div className={styles.separator}>
									<div />
									{formatDateChatAI(messageDate, 'section')}
									<div />
								</div>
							)}
							<div className={msg.alien ? styles.alienMessage : styles.userMessage}>
								<ChatMessage message={msg.message} alien={msg.alien} date={msg.date} />
							</div>
						</React.Fragment>
					)
				})}
		</>
	)
})

export default ChatMessagesList
