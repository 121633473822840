import { dateFormat } from 'helpers/date'

export const formatDateChatAI = (date, type = 'message') => {
	const messageDate = new Date(date)
	const today = new Date()
	const yesterday = new Date(today)
	yesterday.setDate(today.getDate() - 1)

	if (messageDate.toDateString() === today.toDateString()) {
		return type === 'message' ? 'Today ' + dateFormat(date, 'hh:ii:ss') : 'Today'
	} else if (messageDate.toDateString() === yesterday.toDateString()) {
		return type === 'message' ? 'Yesterday ' + dateFormat(date, 'hh:ii:ss') : 'Yesterday'
	} else {
		if (type === 'message') {
			return dateFormat(date, 'dd.mm.YYYY hh:ii:ss')
		} else {
			const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
			return date.toLocaleDateString(undefined, options)
		}
	}
}
