import React from 'react';
import {ReactComponent as LineInformation} from "components/UI/icons/information-line.svg";
import st from './TestInformation.module.scss'

const TestInformation = ({questionsCount, score, levelName, levelSubname, sublevelName, text}) => {
    return (
        <div className={st.container}>
            <LineInformation className={st.icon}/>

            <div className={st.wrapper}>

                <div className={st.params}>
                    <div className={st.block}>
                        <span>Questions:</span>
                        <span className={st.quantity}>{questionsCount}</span>
                    </div>
                    <div className={st.line}></div>
                    <div className={st.block}>
                        <span>Score:</span>
                        <span className={st.quantity}>{score}</span>
                    </div>
                </div>

                <div className={`${st.params} ${st.bottomRow}`}>
                    <span>{levelName}</span>
                    <div className={st.line}></div>
                    <span>{levelSubname}</span>
                    <div className={st.line}></div>
                    <span>{sublevelName}</span>
                </div>

                {text && <div className={st.text}>{text}</div>}
            </div>

        </div>
    );
};

export default TestInformation;
