import React from 'react';
import PropTypes from 'prop-types';
import {getInitAnswer} from "../TestQuestion"
import st from "./MatchWords.module.scss"
import NewVariantDel from "./NewVariants/NewVariantDel/NewVariantDel";
import NewVariantAdd from "./NewVariants/NewVariantAdd/NewVariantAdd";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";

const MatchWords = props => {

    const action = (type, pl) => {
        let arr = []
        switch (type) {
            case 'change':
                arr = props.values.map((x, i) => i === pl.index ? {...x, value: pl.val} : x)
                break
            case 'add':
                arr = props.values.concat([getInitAnswer('word_matching')])
                break
            case 'delete':
                arr = props.values.filter((x, i) => i !== pl.index)
                break
        }
        props.onChange(arr, props.name)
    }

    const val = item => item.value.split('|')

    return (
        <div className={st.box}>
            <div className={st.title}>
                <FormLabel isRequired>Слова для сопоставления</FormLabel>
                <div>
                    Напишите начало и конец фразы (или пары слов) в одной строке. После сохранения они автоматически
                    перемешаются.
                </div>
            </div>

            <div className={st.variants}>{props.values.map((item, index) =>
                <div className={st.variant} key={index}>
                    <div>
                        <input
                            placeholder="Слово 1"
                            value={val(item)[0]}
                            onChange={e => action('change', {
                                val: `${e.target.value}|${val(item)[1]}`,
                                index
                            })}
                            className={`${props.error && val(item)[0].length < 1 ? 'super-modal__input--error' : ''} ${st.inputWord}`}
                        />
                    </div>
                    <div>
                        <input
                            placeholder="Слово 2"
                            value={val(item)[1]}
                            onChange={e => action('change', {
                                val: `${val(item)[0]}|${e.target.value}`,
                                index
                            })}
                            className={`${props.error && val(item)[1].length < 1 ? 'super-modal__input--error' : ''} ${st.inputWord}`}
                        />
                    </div>
                    {props.values.length > 2 &&
                    <NewVariantDel onClick={() => action('delete', {index})}/>}
                </div>)}
            </div>

            <NewVariantAdd onClick={() => action('add')}/>
        </div>
    )
};

MatchWords.propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
}

export default MatchWords
