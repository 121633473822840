import React from 'react';
import styles from "./MakeWordFromLetters.module.scss";

export const MakeWordFromLetters = ({question, answer}) => {

    return (
        <>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                <div className={styles.answers && answer.correct_answers[0] ? styles.rightAnswer : styles.wrongAnswer}>
                    {
                        answer.answers.join('').split('').map((letter, index) =>
                            <span key={index} className={styles.answSpanSize}>
                            {letter.toUpperCase()}
                                {index !== answer.answers[0].length - 1 && <span className={styles.delimiter}> | </span>}
                        </span>)
                    }
                </div>
            </div>
            <div className={`${styles.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor} `}>Correct Answer</div>
                <div className={styles.answers && styles.rightAnswer}>
                    {
                        question.answers?.[0]?.value.split('').map((letter, index) =>
                            <span key={index} className={styles.answSpanSize}>
                            {letter.toUpperCase()}
                                {index !== question.answers?.[0]?.value.length - 1 && <span className={styles.delimiter}> | </span>}
                        </span>)
                    }
                </div>
            </div>
        </>

    );
};

