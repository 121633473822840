import React from 'react';
import reactStringReplace from "react-string-replace";
import Store from "Stores";
import st from "./AnswerQuestion.module.scss";
import {ReactComponent as TrueAnswer} from "components/ContentBox/img/true.svg";
import {ReactComponent as WrongAnswer} from "components/ContentBox/img/false.svg";
import {ReactComponent as Exchange} from "components/ContentBox/img/exchange.svg";

export default function AnswerQuestion(props) {
    const {question, ...answer} = props.answer

    const image = question.files.find(f => f.is_question)
    const renderVariant = answer => {
        switch (question.type){
            case 'complete_sentence':
                return <span>{answer.value.replaceAll('|', ' ')}</span>
            case 'word_matching':
                return (
                    <span className={st.valueWords}>
                        {reactStringReplace(answer.value, '|', (m, i) => <Exchange key={i}/>)}
                    </span>
                )
            case 'words_in_blanks':
                return <span dangerouslySetInnerHTML={{
                    __html: answer.value.replaceAll('[', '<b>').replaceAll(']', '</b>')
                }}/>
            case 'match_words_images':
                const [src, word] = answer.value.split('|')
                return (
                    <span className={st.valueWords}>
                        <img src={Store.baseLink() + src} alt=""/>
                        <Exchange/>
                        {word}
                    </span>
                )
            case 'image_matching':
                const [src1, src2] = answer.value.split('|')
                return (
                    <span className={st.valueWords}>
                        <img src={Store.baseLink() + src1} alt=""/>
                        <Exchange/>
                        <img src={Store.baseLink() + src2} alt=""/>
                    </span>
                )
            case 'question_image_variants':
                const [varSrc, varText] = answer.value.split('|')
                return (
                    <span className={st.valueWords}>
                        <img src={Store.baseLink() + varSrc} alt=""/>
                        {varText}
                    </span>
                )
            default:
                return <span dangerouslySetInnerHTML={{__html: answer.value}}/>
        }
    }

    const renderAnswer = ({answers, correct_answers}) => {
        const value = val => val.split('|')

        const icon = i => correct_answers[i] ? <TrueAnswer/> : <WrongAnswer/>

        switch (question.type){
            case 'complete_sentence':
                return (
                    <ul>
                        <li><i>{icon(0)}</i><span>{answers.join(' ')}</span></li>
                    </ul>
                )
            case 'word_matching':
                return (
                    <ul>{answers.map((a, i) =>
                        <li key={i}>
                            <i><TrueAnswer/></i>
                            <span className={st.valueWords}>
                                {reactStringReplace(a, '|', (m, i) => <Exchange key={i}/>)}
                            </span>
                        </li>)}
                    </ul>
                )
            case 'words_in_blanks':
                return (
                    <ul>
                        <li>
                            <i>{correct_answers.includes(false) ? <WrongAnswer/> : <TrueAnswer/>}</i>
                            <span dangerouslySetInnerHTML={{
                                __html: answers[0].replaceAll('[', '<b>').replaceAll(']', '</b>')
                            }}/>
                        </li>
                    </ul>
                )
            case 'match_words_images':
                return (
                    <ul>{answers.map((a, i) =>
                        <li key={i}>
                            <i>{icon(i)}</i>
                            <span className={st.valueWords}>
                                <img src={Store.baseLink() + value(a)[0]} alt=""/>
                                <Exchange/>
                                {value(a)[1]}
                            </span>
                        </li>)}
                    </ul>
                )
            case 'image_matching':
                return (
                    <ul>{answers.map((a, i) =>
                        <li key={i}>
                            <i>{icon(i)}</i>
                            <span className={st.valueWords}>
                                <img src={Store.baseLink() + value(a)[0]} alt=""/>
                                <Exchange/>
                                <img src={Store.baseLink() + value(a)[1]} alt=""/>
                            </span>
                        </li>)}
                    </ul>
                )
            case 'question_image_variants':
                return (
                    <ul>{answers.map((a, i) =>
                        <li key={i}>
                            <i>{icon(i)}</i>
                            <span className={st.valueWords}>
                                <img src={Store.baseLink() + value(a)[0]} alt=""/>
                                {value(a)[1]}
                            </span>
                        </li>)}
                    </ul>
                )
            case 'sort_sentences':
                return (
                    <ul>{answers.map((a, i) =>
                        <li key={i}>
                            <i>{icon(0)}</i>
                            <span>{a}</span>
                        </li>)}
                    </ul>
                )
            default:
                return (
                    <ul className={props.modal && st.textMissingWordsList}>{answers.map((a, i) =>
                        <li key={i}>
                            <i>{icon(i)}</i>
                            <span>{a}</span>
                        </li>)}
                    </ul>
                )
        }
    }

    return (
        <div className={st.question}>
            <div className={st.text} dangerouslySetInnerHTML={{__html: question.text_question}}/>
            <div className={st.container}>
                {image &&
                <div className={st.image}>
                    <img src={Store.baseLink() + image.file} alt=""/>
                </div>}
                <div className={st.content}>
                    <ul>{question.answers.map(a =>
                        <li key={a.id}>
                            <i>{a.is_correct ? <TrueAnswer/> : <WrongAnswer/>}</i>
                            {renderVariant(a)}
                        </li>)}
                    </ul>
                    <div className={st.divider}/>
                    {renderAnswer(answer)}
                </div>
            </div>
        </div>
    );
};
