import React from 'react';
import styles from  './ImageMatching.module.scss'
import Stores from "Stores";

export const ImageMatching = ({question, answer}) => {
    return (
        <>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                {
                    answer.answers?.map((el, index)=>
                        <div key={index} className={`${styles.container} ${answer.correct_answers[index] ? styles.rightAnswer : styles.wrongAnswer}`}>
                            <img className={styles.imgSize} src={Stores.baseLink() + el.split('|')[0]} alt="picture"/>
                            <div className={`${styles.line} ${answer.correct_answers[index] ? styles.rightAnswer : styles.wrongAnswer}`}></div>
                            <img className={styles.imgSize} src={Stores.baseLink() + el.split('|')[1]} alt="picture"/>
                        </div>
                    )
                }
            </div>
            <div className={`${styles.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>
                {
                    question.answers?.map((el, index)=>
                        <div key={index} className={`${styles.container} ${styles.rightAnswer}`}>
                            <img className={styles.imgSize} src={Stores.baseLink() + el.value.split('|')[0]} alt="picture"/>
                            <div className={`${styles.line} ${styles.rightAnswer}`}></div>
                            <img className={styles.imgSize} src={Stores.baseLink() + el.value.split('|')[1]} alt="picture"/>
                        </div>
                    )
                }
            </div>
        </>
    );
};