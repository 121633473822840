import React, {useState, useEffect} from 'react';
import {localisationMaster} from 'Localisation/Localisation';
import {classNames as cn} from "helpers";
import st from './NewVariants.module.scss';
import NewVariantAdd from "./NewVariantAdd/NewVariantAdd";
import NewCheckbox from "./NewCheckbox/NewCheckbox";
import NewVariantDel from "./NewVariantDel/NewVariantDel";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";

const NewVariants = (props) => {
    const [list, setList] = useState([
        {[props.value_name || 'value']: '', is_correct: false},
        {[props.value_name || 'value']: '', is_correct: false}
    ])

    let Link = 'tests/questions'
    let Tag = 'question_plus_variants'

    const chckbx = (e, pkn, index) =>{
        let arr = list
        arr[index].is_correct = !arr[index].is_correct
        setList([...arr])
    }

    const changeHandler = (e, index) =>{
        let arr = list
        arr[index][props.value_name || 'value'] = e.target.value
        setList([...arr])
    }

    const addLine = () =>{
        let arr = list
        arr.push({[props.value_name || 'value']: '', is_correct: false})
        setList([...arr])
    }

    const deleteLine = (index) =>{
        let arr = []
        for(let key in list) +key !== index && arr.push(list[key])

        setList([...arr])
    }

    useEffect(() => {
        props.och(list, props.name || 'answers')
    }, [list])

    useEffect(() => {
        if(!props.data || props.data.length < 1) return;
        setList([...props.data]);
    }, [])

    return (
        <div className={st.box}>
            <div className={st.title}>
                <FormLabel isRequired>{localisationMaster('possible-answer', Link, Tag)}</FormLabel>
                <div>{localisationMaster('check-mark', Link, Tag)}</div>
            </div>

            <div className={st.variants}>{list.map((item, index) => {
                const key = props.value_name || 'value';
                return (
                    <div className={st.variant} key={index}>
                        <NewCheckbox
                            id={`variant${index}`}
                            checked={item.is_correct}
                            isChecked={(e, pkn) => chckbx(e, pkn, index)}
                            error={props.error && !list.some(x => x.is_correct)}
                        />
                        <div style={{flexGrow: 1}}>
                            <input
                                value={list[index][key]}
                                maxLength={300}
                                placeholder={"Enter answer"}
                                onChange={(e) => changeHandler(e, index)}
                                className={`${cn({
                                    "super-modal__input--error": props.error && (!item[key].length || list.filter(x => x[key] === item[key]).length >= 2)
                                })} ${st.inpt}`}
                            />
                        </div>
                        {list.length > 2 &&
                            /*<div className={st.delete} onClick={() => deleteLine(index)}>&#215;</div>}*/
                            <NewVariantDel onClick={() => deleteLine(index)}/>}
                    </div>
                )
            })}

            </div>

            <NewVariantAdd onClick={addLine}/>
        </div>
    )
};

export default NewVariants
