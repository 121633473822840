import React, { useRef, useEffect } from 'react'
import styles from './AIChat.module.scss'
import Input from 'components/UI/Input/Input'
import CustomAlert from 'components/UI/CustomAlert/CustomAlert'
import { ReactComponent as MessageIcon } from 'components/UI/icons/message-icon.svg'
import { alertText, alertTitle } from './constants'
import { CloseButton, SuperButton } from 'components/UI/SuperButton/SuperButton'
import { observer } from 'mobx-react'
import { useAIChat } from 'hooks/useAIChat'
import ChatMessagesList from './ChatMessagesList/ChatMessagesList'
import Loader from 'components/ContentBox/Global/Loader/Loader'

const AIChat = observer(({ toggleChat }) => {
	const { inputValue, messages, handleInputChange, handleSubmit, isAIChatOpen, loadMoreMessages, isLoadingMoreMessages, isAwaitingResponse } =
		useAIChat()

	const messagesEndRef = useRef(null)
	const messagesContainerRef = useRef(null)

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const handleLoadMoreMessages = async () => {
		if (messagesContainerRef.current && messagesContainerRef.current.scrollTop === 0) {
			const scrollHeight = messagesContainerRef.current.scrollHeight
			const scrollTop = messagesContainerRef.current.scrollTop

			await loadMoreMessages()

			messagesContainerRef.current.scrollTop = scrollTop + (messagesContainerRef.current.scrollHeight - scrollHeight)
		}
	}

	useEffect(() => {
		if (isAIChatOpen) scrollToBottom()
	}, [isAIChatOpen])

	useEffect(() => scrollToBottom(), [messages])

	return (
		<div className={styles.chatContainer}>
			<div className={styles.chatHeader}>
				<span className={styles.title}>Chat GPT-4</span>
				<CloseButton onClick={toggleChat} />
			</div>

			<div className={styles.chatMessages} ref={messagesContainerRef} onScroll={handleLoadMoreMessages}>
				<div className={styles.alert}>
					<CustomAlert icon={MessageIcon} title={alertTitle} text={alertText} />
				</div>

				{isLoadingMoreMessages && (
					<div className={styles.loader}>
						<Loader />
					</div>
				)}

				<ChatMessagesList messages={messages} />

				<div ref={messagesEndRef} />
			</div>

			<div className={styles.chatFooter}>
				<Input
					withLabel={false}
					label=''
					placeholder={'Your question...'}
					classes={styles.inputMessage}
					onChange={handleInputChange}
					value={inputValue}
					onKeyDown={e => !isAwaitingResponse && e.key === 'Enter' && handleSubmit()}
				/>
				<div className={styles.sendBtn}>
					<SuperButton onClick={handleSubmit} variant='confirm' disable={isAwaitingResponse}>
						Send
					</SuperButton>
				</div>
			</div>
		</div>
	)
})

export default AIChat
