import React from 'react';
import Store from "Stores";
import st from "./AnswerQuestionNew.module.scss";
import {CustomAnswer} from "./CustomAnswer/CustomAnswer";
import Stores from "Stores";
import QuestionAudios from "components/Modules/Ebook/Common/Exercise/types/QuestionAudios/QuestionAudios";
import VideoPlayer from 'components/ContentBox/Global/MediaPlayer/VideoPlayer';
import styles from "./CustomAnswer/QuestionPlusText/QuestionPlusText.module.scss";

export default function AnswerQuestionNew(props) {
    const {question, ...answer} = props.answer

    const image = question.files.find(f => f.is_question)
    const mainAudio = question.audio_file
    const additionalFile = question.files?.[1]?.file

    return (
        <div className={st.question}>
            {image &&
                <div className={st.image}>
                    <img src={Store.baseLink() + image.file} alt=""/>
                </div>}
            {mainAudio &&
                <QuestionAudios items={[{file: question.audio_file}]}/>
            }
            {question.type === "audio_plus_variants" &&
                <QuestionAudios items={[{file: additionalFile}]}/>
            }
            {question.type === "video_plus_variants" &&
                <div className={st.video}>
                    <VideoPlayer v_link={Stores.baseLink() + additionalFile}/>
                </div>
            }
            <div className={`${st.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor}`}>Condition</div>
                <span className={`${styles.rightAnswer} ${styles.span}`}>
                    <div className={st.text} dangerouslySetInnerHTML={{__html: question.text_question}}/>
                    {/*className={st.text}*/}
                </span>
            </div>
            <div className={st.container}>
                <CustomAnswer question={question} answer={answer} item={props.item}/>
            </div>
        </div>
    );
};
