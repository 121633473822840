import React, {useState, useEffect} from 'react';
import {superAxiosRequest} from 'axiosApi';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import MainTableRow from '../Global/MainTable/MainTableRow/MainTableRow';
import NoResults from './NoResults';
import Loader from "components/ContentBox/Global/Loader/Loader";
import './StudyTestMain.scss'
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores";
import StudyTestResult from "./components/StudyTestResult";


export default function StudyTestMain(props) {
    let apiConf;
    const [values, setValues] = useState([])
    const [initTestsList, setInitTestsList] = useState([false, []])
    const [filterList, setFilterList] = useState({list: [], loading: true})
    const [loading, setLoading] = useState(true);
    const id = props.match.params['id'];

    const colsSettings = {
        action: [
            {
                name: localisationMaster('text_8', 'online-schedule/future-slots'),
                action: 'detail',
                permission: ['purposetestteacher', 'add']
            },
            {
                name: localisationMaster('button-three', 'tests/settings', 'add_bundle'),
                action: 'update_study_test',
                permission: ['purposetestteacher', 'add']
            },
        ],
        /* link: 'study_test',
        prefix: 'study', */
        id: {name: 'id'},
        update_link: 'study/student_init_test/',
        main_rows: [
            {name: 'full_name', depence: false},
            {name: ''},
            {name: ''},
            {name: ''},
            {
                compiled: [
                    {
                        name: 'list',
                        function: 'array_count',
                        arr: true,
                        f_title: `${localisationMaster('text_23', 'study/study-test')}: `,
                        past: ` ${localisationMaster('of', 'pagination')} ${values.try_count}`
                    },
                ],
                classname: 'tar'
            }
        ],
        is_init_test: true,
        is_open: false,
        sub_rows: []
    }

    const updateRow = data => {
        setInitTestsList([true, data.data.result])
    }

    useEffect(() => {
        apiConf = {
            method: 'get',
            link: 'study/purpose_test/' + id
        }
        superAxiosRequest(apiConf).then(res => {
            setValues(res.data)
            setLoading(false);
        })

        apiConf = {
            method: 'get',
            link: 'study/teacher_init_test',
            params: {
                //is_finish: false,
                purpose_test: id,
                limit: 999,
            }
        }
        superAxiosRequest(apiConf).then(res => setInitTestsList([true, res.data.result]))
    }, [id])

    useEffect(() => {
        if (!initTestsList[0]) return;
        let studentList = []
        let tryList = []
        values.students && values.students.map(el => !studentList.find(x => x.id === el.id) && studentList.push({
            id: el.user,
            full_name: `${el.last_name} ${el.first_name}`
        }))
        studentList.map(el => {
            let a = initTestsList[1].filter(x => x.student.id === el.id)
            tryList.push({...a[0], ...el, list: a})
        })
        setFilterList({list: [...tryList], loading: false})
    }, [initTestsList, values])

    if (loading) return <Loader/>

    return (
        values.length !== 0 &&
        <>
            <ContentHeader
                title={`${localisationMaster('text_1', 'study/test')}: ${values.name}`}
                breadСrumbs={[
                    {title: 'Тесты, назначенные клиентам', route: '/c/study/purpose-test'}
                ]}
            />
            <div className="studyTestMain">
                <StudyTestResult values={values}/>
                {filterList.loading
                    ? <Loader/>
                    : (
                        <div>{filterList.list.length > 0 ?
                            filterList.list.map((item, index) =>
                                <MainTableRow key={index}
                                              colsSettings={colsSettings}
                                              items={{
                                                  ...item,
                                                  full_name: Stores.transliterate(item.full_name),
                                                  student: {
                                                      ...item.student,
                                                      full_name: Stores.transliterate(item?.student?.full_name || '')
                                                  },
                                                  tryCount: values.try_count,
                                                  timeLeft: {
                                                      seconds_until_start: values.seconds_until_start,
                                                      seconds_until_finish: values.seconds_until_finish,
                                                      created_at: values.created_at,
                                                      date_until: values.date_until,
                                                      finish_at: values.finish_at
                                                  },
                                                  study_test: values.study_test.id
                                              }}
                                              index={index}
                                              updateRow={updateRow}
                                              off_padding
                                              forcedID={id}
                                              isDropDownHidden={true}
                                />)
                            : <NoResults/>}
                        </div>
                    )
                }
            </div>
        </>
    )
}
