import React from 'react';
import styles from "./SortSentences.module.scss";

export const SortSentences = ({question, answer}) => {
    return (
        <>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                {answer.answers.map((el, index) => (
                    <div key={index} style={{display: "flex", gap: '10px', alignSelf: "stretch"}}>
                        <div className={`${styles.variant} ${styles.numberContainer} 
                        ${question.answers?.map(answer => answer.value).indexOf(el) === index ? styles.checkedRight : styles.checkedWrong }`}>
                            {question.answers?.map(answer => answer.value).indexOf(el) + 1}
                        </div>
                        <div className={`${styles.variant} ${styles.unchecked}`}>
                            {el}
                        </div>
                    </div>

                ))}
            </div>
            <div className={`${styles.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>
                {question.answers?.map((answer, index) => (
                    <div key={index} style={{display: "flex", gap: '10px', alignSelf: "stretch"}}>
                        <div className={`${styles.variant} ${styles.numberContainer} ${styles.checkedRight}`}>
                            {index + 1}
                        </div>
                        <div key={index} className={`${styles.variant} ${styles.unchecked}`}>
                            {answer.value}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};