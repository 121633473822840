// users
const USER_PARENT_KEY = 'users';
const CLIENT_PROFILES_KEY = 'clientsprofile';
const PERSONAL_INFORMATION_LIST_KEY = 'personal_information';
const COMMENT_PROFILE_LIST_KEY = 'comment_profile';
const EMPLOYEE_PROFILES_KEY = 'employersprofile';

// ledger
const ENTERIES_PARENT_KEY = 'ledger';
//const ENTERIES_LIST_KEY = 'accountentry';
const GROUP_BALANCES_KEY = 'groupbalance';
const STATISTICS_TEACHER_KEY = 'teachersalaryentry';
const GROUP_WRITE_OFF_BY = 'accountentry'
const SALARY_HOUR_TYPE = 'salaryhourstype'
const BONUS_BALANCE = 'bonusbalance'

// GROUPS
const GROUPS_PARENT_KEY = 'groups';
const GROUP_LIST_KEY = 'studygroup';
const STUDY_GROUP_LOGS_KEY = 'studygrouplogs';
const COMMENT_PROFILE = 'comment_profile';
const GROUP_INTERIM_HOMEWORK = 'interimhomework';
const GROUP_INTERIM_RESULT_TESTS = 'interimresulttests';
const DISCOUNT_REPORT_KEY = 'discount'
const SURCHARGES_REPORT_KEY = 'discount'
const STUDENT_CERT_LIST_KEY = 'studentcertificate';
const STUDENT_PRICE_IN_GROUP_KEY = 'groupstudentdefaultpricerelations';
const STUDENT_DISCOUNT_IN_GROUP_KEY = 'groupstudentdiscountrelations';
const STUDENT_CERT_IN_GROUP_KEY = 'studentcertificate'
const GROUPSSTATUS_LIST_KEY = 'groupsstatus'
const GROUPPRICESUM_LIST_KEY = 'grouppricesum'

//LESSONS && COURSES
const COURSES_PARENT_KEY = 'lessons';
const COURSES_LIST_KEY = 'course';
const ASSIGNEXERCISE_LIST_KEY = 'assignexercise';
const SHARECOURSELINK_LIST_KEY = 'sharecourselink';
const LESSON_KEY = 'lesson';
const INIT_LESSON_KEY = 'initlessonforstudents';
const COURSE_DOCUMENTS_KEY = 'documentcourse';

//PROGRESS REPORT
const PROGRESS_REPORT_PARENT_KEY = 'progress_report';
const REPORT_LIST_KEY = 'report'
const TEACHER_REPORT_LIST_KEY = 'teacherreport'

//SLOTS
const SLOTS_PARENT_KEY = 'slots';
const ONLINE_SLOTS_KEY = 'slotclient';
const TEACHER_SLOT_KEY = 'teacherslots';
const TIME_SLOTS_KEY = 'slotslocations';
const ONLINE_SCHEDULE_PARENT_KEY = 'online_schedule';
const COMPANY_SETTINGS_KEY = 'companysettings'
const SLOTS_LOCATIONS_KEY = 'slotslocations'
const SLOTS_EMPLOYEE_BOOKED_SLOTS_KEY = 'employeebookedslots'
const SLOTS_SLOT_CLIENT_KEY = 'slotclient'
const SLOTS_EMPLOYEE_SETTINGS = 'employeesettings';

// DB
const DB_PARENT_KEY = 'db';
const COMPANY_KEY = 'company';
const LOCATION_KEY = 'location';
const PROGRAMS_KEY = 'program';
const AGE_KEY = 'age';
//const DISCIPLINES_KEY = 'discipline';
//const LEVELS_KEY = 'levels'; // NOT RULE
//const CATEGORIES_KEY = 'categories'; //NO RULE

//WIDGET
const WIDGET_PARENT_KEY = 'widget';
const WIDGET_CLIENT_LIST_KEY = 'widgettestresult';
const PLACEMENT_BUNDLE_LIST_KEY = 'ageprogramlevelbundle';
const PLACEMENT_BUNDLE_KEY = 'placementtestquestion';
const PLACEMENT_TEST_PARENT_KEY = 'placement_test';
const PLACEMENT_TEST_CREATE = 'widget';
const PLACEMENT_TEST_RESULT_LIST = 'testresult';
const PLACEMENT_TEST_LIST_WIDGET = 'widget';

//PAYS
const PAYMENT_PARENT_KEY = 'payments';
const PAYMENT_LIST_KEY = 'orders';
const REFUND_LIST_KEY = 'refundstransfers';

// STYDY TEST
const STUDYTEST_PARENT_KEY = 'study';
const STUDYTEST_LIST_KEY = 'studytest';
const PURPOSED_TEACHER_TEST = 'purposetestteacher';
const STUDY_INIT_TEST_KEY = 'studentinittest';

// SCHEDULE TEST
const SCHEDULE_PARENT_KEY = 'schedule';
const SCHEDULE_CALENDAR = 'holiday';
const SCHEDULE_LIST_KEY = 'schedule'
const EMPLOYEEREPORT_LIST_KEY = 'employeereport'
const SCHEDULE_MY_GROUP = 'schedule'

// EMPLOYEES
const EMPLOYEES_PARENT_KEY = 'employees'
const CONTRACT_LIST_KEY = 'contracts'
const CONTRACTDOCS_LIST_KEY = 'contractdocs'
const WORK_GRAPHIC_RULES_LIST_KEY = 'workgraphicrules'
const VACATION_MINUTES_LIST_KEY = 'vacationminutes'
const DAY_TYPES_LIST_KEY = 'daytypes'
const FILE_DOCUMENTS_KEY = 'filedocuments'

// EBOOKS
const EBOOKS_PARENT_KEY = 'ebooks'
const EBOOK_LIST_KEY = 'ebook'
const EBOOK_DOCUMENTS_KEY = 'documentebook'
const EBOOK_CHAPTER_KEY = 'chapter'
const STUDENTSEBOOKS_KEY = 'studentsebooks'
const STUDYCOURSE_KEY = 'studycourse'
const INIT_LESSON_FOR_STUDENT_KEY = 'initlessonforstudent'

// DOCUMENTS
const DOCUMENTS_PARENT_KEY = 'documents'
const DOCUMENTS_CLIENT_SIGNED_DOCUMENT = 'clientsigneddocument'
const DOCUMENTS_EMPLOYEE_SIGNED_DOCUMENT = 'employeesigneddocument'

// UNIDANCE
const UNIDANCE_PARENT_KEY = 'unidance'
const BARCODE_KEY = 'clientbarcode'
const MASTERSPROFILE_KEY = 'mastersprofile' //Мастер
const SCHEDULE_KEY = 'scheduleteachers'
const SUBSCRIPTIONS_KEY = 'subscriptions'

const EXERCISE_KEY = 'exercise'
const CHAPTER_SECTION_KEY = 'chaptersection'


export const permissions = {
    view_client_profile: {
        link: 'account_clients',
        dynamic: true,
        parent_key: USER_PARENT_KEY,
        key: CLIENT_PROFILES_KEY,
        permissions: ['view_client', 'view_own_client']
    },
    edit_client_profile: {
        link: '',
        dynamic: true,
        parent_key: USER_PARENT_KEY,
        key: CLIENT_PROFILES_KEY,
        permissions: ['change_client']
    },
    view_employee_profile: {
        link: 'account',
        dynamic: true,
        parent_key: USER_PARENT_KEY,
        key: EMPLOYEE_PROFILES_KEY,
        permissions: ['view_all_employers']
    },
    view_group_list: {
        link: 'groups/all-groups',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_study_groups', 'view_own_study_groups']
    },
    view_interim_result_tests: {
        link: 'groups/tests',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_INTERIM_RESULT_TESTS,
        permissions: ['view_interim_result_tests']
    },
    delete_interim_result_tests: {
        link: 'groups/tests',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_INTERIM_RESULT_TESTS,
        permissions: ['delete_interim_result_tests']
    },
    change_interim_result_tests: {
        link: 'groups/tests',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_INTERIM_RESULT_TESTS,
        permissions: ['change_interim_result_tests']
    },
    view_interim_homework: {
        link: 'groups/homeworks',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_INTERIM_HOMEWORK,
        permissions: ['view_interim_homework', 'view_own_interim_homework']
    },
    delete_interim_homework: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_INTERIM_HOMEWORK,
        permissions: ['delete_interim_homework']
    },
    edit_interim_homework: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_INTERIM_HOMEWORK,
        permissions: ['change_interim_homework']
    },
    view_group: {
        link: 'groups/group',
        dynamic: true,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_study_groups', 'view_own_study_groups']
    },
    view_group_visual_schedule: {
        link: 'group/visual-schedule',
        dynamic: true,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_study_groups', 'view_own_study_groups']
    },
    view_group_v2: {
        link: 'groups/group-v2',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_study_groups', 'view_own_study_groups']
    },
    view_homeworks_group: {
        link: 'groups/homeworks',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_study_groups', 'view_own_study_groups']
    },
    view_tests_group: {
        link: 'groups/tests',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_study_groups', 'view_own_study_groups']
    },
    view_reports_group: {
        link: 'groups/reports',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_study_groups', 'view_own_study_groups']
    },
    view_my_group: {
        link: 'groups/my-group',
        dynamic: true,
        parent_key: SCHEDULE_PARENT_KEY,
        key: SCHEDULE_MY_GROUP,
        permissions: ['view_schedule']
    },
    view_group_sign_up: {
        link: 'groups/sign-up',
        dynamic: true,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['add_myself_to_group']
    },
    view_admin_group_sign_up: {
        link: 'groups/sign-up-student',
        dynamic: true,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['add_client_to_group']
    },
    view_group_sign_up_clients: {
        link: 'c/groups/self-sign-up',
        //dynamic: true,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['add_client_to_group']
    },
    add_student_default_price: {
        parent_key: GROUPS_PARENT_KEY,
        key: STUDENT_PRICE_IN_GROUP_KEY,
        permissions: ['add_group_student_default_price_relations']
    },
    add_discount_in_group: {
        parent_key: GROUPS_PARENT_KEY,
        key: STUDENT_DISCOUNT_IN_GROUP_KEY,
        permissions: ['add_group_student_discount_relations']
    },
    add_student_to_group: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['add_client_to_group']
    },
    view_client_balances: {
        parent_key: ENTERIES_PARENT_KEY,
        key: GROUP_BALANCES_KEY,
        permissions: ['view_group_balance']
    },
    view_ledger_teacher_statistics: {
        link: 'reports/teacher-statistics',
        parent_key: ENTERIES_PARENT_KEY,
        key: STATISTICS_TEACHER_KEY,
        permissions: ['view_teacher_salary_operations_report']
    },
    view_report_clients_stages: {
        link: 'reports/clients-stages',
        parent_key: ENTERIES_PARENT_KEY,
        key: STATISTICS_TEACHER_KEY,
        permissions: ['view_teacher_salary_operations_report']
    },
    change_bonus_balance : {
        parent_key: ENTERIES_PARENT_KEY,
        key: BONUS_BALANCE,
        permissions: ['change_bonus_balance']
    },
    add_bonus_balance  : {
        parent_key: ENTERIES_PARENT_KEY,
        key: BONUS_BALANCE,
        permissions: ['add_bonus_balance']
    },
    delete_bonus_balance   : {
        parent_key: ENTERIES_PARENT_KEY,
        key: BONUS_BALANCE,
        permissions: ['delete_bonus_balance']
    },
    view_students_group_conversion_report: {
        link: 'reports/conversion',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_students_group_conversion_report']
    },
    view_formed_groups_report: {
        link: 'reports/formed_groups',
        parent_key: GROUPS_PARENT_KEY,
        key: STUDY_GROUP_LOGS_KEY,
        permissions: ['view_formed_groups_report']
    },
    view_group_launch_speed_report: {
        link: 'reports/groups_launch_speed',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_group_launch_speed_report']
    },
    view_report_birthday: {
        link: 'reports/birthday',
        parent_key: USER_PARENT_KEY,
        key: EMPLOYEE_PROFILES_KEY,
        permissions: ['view_birthday_report']
    },
    view_conversion_report: {
        link: 'reports/conversion_report',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_conversion_report']
    },
    view_certuser_to_group: {
        parent_key: GROUPS_PARENT_KEY,
        key: STUDENT_CERT_IN_GROUP_KEY,
        permissions: ['view_student_certificate']
    },

    add_course_to_catalog: {
        parent_key: COURSES_PARENT_KEY,
        key: COURSES_LIST_KEY,
        permissions: ['add_course_to_catalog']
    },

    // Ebooks
    view_ebook_book: {
        link: 'ebooks/book',
        parent_key: COURSES_PARENT_KEY,
        key: COURSES_LIST_KEY,
        permissions: ['view_course']
    },
    view_chapter: {
        link: 'ebooks/book',
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_CHAPTER_KEY,
        permissions: ['view_chapter']
    },
    // add_ebook | ebooks | Электронный учебник | Добавить электронный учебник
    add_ebook_book: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_LIST_KEY,
        permissions: ['add_ebook']
    },
    //view_ebook | ebooks | Электронный учебник | Просматривать электронные учебники
    view_ebook_books: {
        link: 'ebooks/books',
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_LIST_KEY,
        permissions: ['view_ebook']
    },
    view_ebook_books_student: {
        link: 'ebooks/my-books/',
        parent_key: EBOOKS_PARENT_KEY,
        key: STUDENTSEBOOKS_KEY,
        permissions: ['view_own_student_book']
    },
    view_register_listings: {
        link: 'ebooks/register-listings',
        parent_key: COURSES_PARENT_KEY,
        key: COURSES_LIST_KEY,
        permissions: ['view_course']
    },
    view_ebook_chapter: {
        link: 'ebooks/chapter',
        parent_key: COURSES_PARENT_KEY,
        key: COURSES_LIST_KEY,
        permissions: ['view_course']
    },
    // add_chapter | ebooks | Раздел учебника | Добавить раздел учебника
    add_ebook_chapter: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_CHAPTER_KEY,
        permissions: ['add_chapter']
    },
    // add_document | ebooks | Документ | Добавить документ электронного учебника
    add_ebook_document: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_DOCUMENTS_KEY,
        permissions: ['add_document']
    },
    add_init_lesson: {
        parent_key: EBOOKS_PARENT_KEY,
        key: INIT_LESSON_FOR_STUDENT_KEY,
        permissions: ['add_init_lesson']
    },
    delete_init_lesson: {
        parent_key: EBOOKS_PARENT_KEY,
        key: INIT_LESSON_FOR_STUDENT_KEY,
        permissions: ['delete_init_lesson']
    },
    view_init_lessons: {
        parent_key: EBOOKS_PARENT_KEY,
        key: INIT_LESSON_FOR_STUDENT_KEY,
        permissions: ['view_init_lesson']
    },

    // Slots
    edit_advanced_teachers_history: {
        link: 'online-schedule-v2/advanced-teachers-history',
        dynamic: false,
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_EMPLOYEE_BOOKED_SLOTS_KEY,
        permissions: ['view_employee_booked_slots_per_day']
    },
    edit_advanced_teachers_history_v2: {
        link: 'online-schedule-v2/advanced-teachers-history-v2',
        dynamic: false,
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_EMPLOYEE_BOOKED_SLOTS_KEY,
        permissions: ['view_employee_booked_slots_per_day']
    },
    view_time_slots: {
        link: 'online-schedule/time-slots',
        dynamic: false,
        parent_key: SLOTS_PARENT_KEY,
        key: TIME_SLOTS_KEY,
        permissions: ['view_slot']
    },
    change_slot_client: {
        link: 'online_schedule/resend_notification',
        dynamic: false,
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: ONLINE_SLOTS_KEY,
        permissions: ['change_slot_client']
    },
    view_schedule_settings: {
        link: 'online-schedule/schedule-setting',
        dynamic: false,
        parent_key: SLOTS_PARENT_KEY,
        key: TEACHER_SLOT_KEY,
        permissions: ['view_teacher_slot', 'view_booked_slots', 'view_free_slots', 'view_stats_teacher_slot']
    },
    view_schedule_settings_v2: {
        link: 'online-schedule-v2/schedule-setting',
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_LOCATIONS_KEY,
        permissions: ['add_slot']
    },
    view_teacher_schedule_v2: {
        link: 'online-schedule-v2/own-schedule',
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_EMPLOYEE_BOOKED_SLOTS_KEY,
        permissions: ['view_own_employee_booked_slot']
    },
    record_online_customer_v2: {
        link: 'online-schedule-v2/record-customer',
        //dynamic: true,
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_SLOT_CLIENT_KEY,
        permissions: ['add_slot_client', 'change_slot_client', 'view_slot_client']
    },
    view_booked_online_schedule_v2: {
        link: 'online-schedule-v2/booked-schedule',
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_SLOT_CLIENT_KEY,
        permissions: ['view_schedule_slot_client']
    },
    booked_online_schedule_edit_client: {
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_SLOT_CLIENT_KEY,
        permissions: ['add_slot_client', 'change_slot_client', 'delete_slot_client']
    },
    booked_online_schedule_delete_client: {
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_SLOT_CLIENT_KEY,
        permissions: ['delete_slot_client']
    },
    view_online_slots_v2: {
        link: 'online-schedule-v2/slots',
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: ONLINE_SLOTS_KEY,
        permissions: ['view_employee_own_slot_client']
    },
    view_online__slot_v2: {
        link: 'online-schedule-v2/slot-details',
        dynamic: true,
        parent_key: SLOTS_PARENT_KEY,
        key: ONLINE_SLOTS_KEY,
        permissions: ['view_slot_client', 'view_own_slot_client']
    },
    view_company_settings: {
        link: 'c/online-schedule/companies',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: COMPANY_KEY,
        permissions: ['view_company'],
    },
    view_slot_settings: {
        link: 'online-schedule/company-setting',
        dynamic: true,
        parent_key: SLOTS_PARENT_KEY,
        key: TEACHER_SLOT_KEY,
        permissions: ['view_teacher_slot', 'view_booked_slots', 'view_free_slots', 'view_stats_teacher_slot'],
    },
    view_slot_company_settings: {
        link: 'online-schedule/company-setting',
        //dynamic: true,
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: COMPANY_SETTINGS_KEY,
        permissions: ['view_company_settings'],
    },
    view_clients_after_online: {
        link: 'c/online-schedule/clients-after-online',
        dynamic: true,
        parent_key: SLOTS_PARENT_KEY,
        key: ONLINE_SLOTS_KEY,
        permissions: ['view_slot_client']
    },
    view_clients_after_online_v2: {
        link: 'online-schedule-v2/history-clients',
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: ONLINE_SLOTS_KEY,
        permissions: ['view_history_slot_client']
    },
    record_online_customer: {
        link: 'online-schedule/record-customer',
        dynamic: false,
        parent_key: SLOTS_PARENT_KEY,
        key: ONLINE_SLOTS_KEY,
        permissions: ['add_slot_client']
    },
    view_booked_online_scedule: {
        link: 'online-schedule/booked-online-schedule',
        dynamic: false,
        parent_key: SLOTS_PARENT_KEY,
        key: ONLINE_SLOTS_KEY,
        permissions: ['view_range_slot_client']
    },
    view_booked_teacher_scedule: {
        link: 'online-schedule/booked-teachers-schedule',
        dynamic: false,
        parent_key: SLOTS_PARENT_KEY,
        key: TEACHER_SLOT_KEY,
        permissions: ['view_booked_slots']
    },
    view_extended_teacher_scedule: {
        link: 'online-schedule/extended-teachers-schedule',
        dynamic: false,
        parent_key: SLOTS_PARENT_KEY,
        key: TEACHER_SLOT_KEY,
        permissions: ['view_stats_teacher_slot']
    },
    view_teachers_history: {
        link: 'online-schedule-v2/teachers-history',
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_EMPLOYEE_BOOKED_SLOTS_KEY,
        permissions: ['view_employee_booked_slot']
    },
    view_clients_list: {
        link: 'c/company/clients-profiles',
        dynamic: false,
        parent_key: USER_PARENT_KEY,
        key: CLIENT_PROFILES_KEY,
        permissions: ['view_client']
    },
    // need PERMS
    view_debetors_list: {
        link: 'c/company/debetors',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        //key: DEBETOR_LIST_KEY,
        key: COMPANY_KEY,
        permissions: ['view_debtors_report']
    },
    view_student_discount_list: {
        link: 'c/company/student-discount',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: DISCOUNT_REPORT_KEY,
        permissions: ['view_discount_report']
    },
    view_student_cert_list: {
        link: 'c/company/student-certs',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: STUDENT_CERT_LIST_KEY,
        permissions: ['view_group_certificate_report']
    },
    view_cert_in_groups_list: {
        link: 'c/company/cert-in-group',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: STUDENT_CERT_LIST_KEY,
        permissions: ['view_group_certificate_report']
    },
    // ==============================
    view_company_list: {
        link: 'c/company/companies',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: COMPANY_KEY,
        permissions: ['view_company']
    },
    // ADD SELF PERMS
    view_evaluation_list: {
        link: 'c/progress-report/evaluation',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: COMPANY_KEY,
        permissions: ['view_company']
    },
    view_rating_list: {
        link: 'c/progress-report/rating',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: COMPANY_KEY,
        permissions: ['view_company']
    },
    view_locations_list: {
        link: 'c/company/locations',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: LOCATION_KEY,
        permissions: ['view_location']
    },
    view_employers_list: {
        link: 'c/company/profiles',
        dynamic: false,
        parent_key: USER_PARENT_KEY,
        key: EMPLOYEE_PROFILES_KEY,
        permissions: ['view_all_employers']
    },
    view_worktable: {
        link: 'company/work-table',
        dynamic: false,
        parent_key: USER_PARENT_KEY,
        key: EMPLOYEE_PROFILES_KEY,
        permissions: ['view_all_employers']
    },
    view_single_worktable: {
        link: 'company/single-work-table',
        dynamic: true,
        parent_key: USER_PARENT_KEY,
        key: EMPLOYEE_PROFILES_KEY,
        permissions: ['view_all_employers']
    },
    view_attendance_records: {
        link: 'company/attendance-records',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_profitability_programs: {
        link: 'reports/profitability-programs',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_profitability_programs']
    },
    view_email_list: {
        link: 'c/mailing/email',
        dynamic: false,
        parent_key: USER_PARENT_KEY,
        key: EMPLOYEE_PROFILES_KEY,
        permissions: ['view_all_employers']
    },
    view_sms_list: {
        link: 'c/mailing/sms',
        dynamic: false,
        parent_key: USER_PARENT_KEY,
        key: EMPLOYEE_PROFILES_KEY,
        permissions: ['view_all_employers']
    },
    view_discount_list: {
        link: 'c/pays/discounts',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: DISCOUNT_REPORT_KEY,
        permissions: ['view_discount']
    },
    view_surcharges_list: {
        link: 'c/pays/surcharges',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: SURCHARGES_REPORT_KEY,
        permissions: ['view_surcharges']
    },
    view_salary_list: {
        link: 'c/ledger/salary',
        dynamic: false,
        parent_key: ENTERIES_PARENT_KEY,
        key: SALARY_HOUR_TYPE,
        permissions: ['view_salary_hours_type']
    },
    view_certificates_list: {
        link: 'c/company/certificates',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: DISCOUNT_REPORT_KEY,
        permissions: ['view_discount']
    },
    view_settings_payment_list: {
        link: 'c/pays/setting-payments',
        dynamic: false,
        parent_key: GROUPS_PARENT_KEY,
        key: GROUPPRICESUM_LIST_KEY,
        permissions: ['view_groups_price_sums']
    },
    view_programs_list: {
        link: 'c/company/programs',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: PROGRAMS_KEY,
        permissions: ['view_program']
    },
    view_age_list: {
        link: 'c/company/age',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: AGE_KEY,
        permissions: ['view_age']
    },
    view_disciplines_list: {
        link: 'c/groups/disciplines',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: PROGRAMS_KEY,
        permissions: ['view_program']
    },
    view_levels_list: {
        link: 'c/groups/group-levels',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: PROGRAMS_KEY,
        permissions: ['view_program']
    },
    view_categories_list: {
        link: 'c/groups/categories',
        dynamic: false,
        parent_key: DB_PARENT_KEY,
        key: PROGRAMS_KEY,
        permissions: ['view_program']
    },
    view_course: {
        link: 'lessons/course',
        dynamic: true,
        parent_key: COURSES_PARENT_KEY,
        key: COURSES_LIST_KEY,
        permissions: ['view_course']
    },
    view_shared_course: {
        link: 'lessons/shared_course',
        dynamic: true,
        parent_key: COURSES_PARENT_KEY,
        key: COURSES_LIST_KEY,
        permissions: ['view_course']
    },
    view_lesson: {
        link: 'lessons/lesson',
        dynamic: true,
        parent_key: COURSES_PARENT_KEY,
        key: LESSON_KEY,
        permissions: ['view_lessons', 'view_own_lessons']
    },
    add_lesson: {
        //link: '',
        //dynamic: true,
        parent_key: COURSES_PARENT_KEY,
        key: LESSON_KEY,
        permissions: ['add_lessons']
    },
    // view_init_lesson: {
    //     link: 'lessons/init',
    //     dynamic: true,
    //     parent_key: COURSES_PARENT_KEY,
    //     key: INIT_LESSON_KEY,
    //     permissions: ['view_init_lesson', 'view_own_init_lesson']
    // },
    view_init_lesson: {
        parent_key: EBOOKS_PARENT_KEY,
        key: INIT_LESSON_FOR_STUDENT_KEY,
        permissions: ['view_init_lesson', 'view_own_init_lesson']
    },
    view_widget_list_result: {
        link: 'c/tests/widget-clients',
        dynamic: true,
        parent_key: WIDGET_PARENT_KEY,
        key: WIDGET_CLIENT_LIST_KEY,
        permissions: ['view_widget_result']
    },
    edit_widget_result: {
        link: 'c/tests/widget-clients',
        dynamic: true,
        parent_key: WIDGET_PARENT_KEY,
        key: WIDGET_CLIENT_LIST_KEY,
        permissions: ['change_widget_programagerelation', 'delete_widget_programagerelation']
    },
    view_orders: {
        link: 'c/pays/orders',
        dynamic: false,
        parent_key: PAYMENT_PARENT_KEY,
        key: PAYMENT_LIST_KEY,
        permissions: ['view_own_orders']
    },
    view_client_orders_history: {
        link: 'c/pays/client-orders',
        dynamic: false,
        parent_key: PAYMENT_PARENT_KEY,
        key: PAYMENT_LIST_KEY,
        permissions: ['view_orders', 'view_own_orders']
    },
    view_1C_history: {
        link: 'c/pays/1с-payments',
        dynamic: false,
        parent_key: PAYMENT_PARENT_KEY,
        key: PAYMENT_LIST_KEY,
        permissions: ['view_orders']
    },
    view_refunds: {
        link: 'c/pays/refunds',
        dynamic: false,
        parent_key: PAYMENT_PARENT_KEY,
        key: REFUND_LIST_KEY,
        permissions: ['view_refunds_transfers', 'view_own_refunds_transfers']
    },
    view_transfer: {
        link: 'c/pays/transfer',
        dynamic: false,
        parent_key: PAYMENT_PARENT_KEY,
        key: REFUND_LIST_KEY,
        permissions: ['view_refunds_transfers', 'view_own_refunds_transfers']
    },
    //payments | Перевод и возврат суммы | Добавить перевод и возврат суммы
    add_refunds_transfers: {
        parent_key: PAYMENT_PARENT_KEY,
        key: REFUND_LIST_KEY,
        permissions: ['add_refunds_transfers']
    },
    create_pay: {
        link: 'pays/invoice',
        dynamic: false,
        parent_key: PAYMENT_PARENT_KEY,
        key: PAYMENT_LIST_KEY,
        permissions: ['view_orders', 'view_own_orders']
    },
    change_invoice: {
        link: '',
        dynamic: false,
        parent_key: PAYMENT_PARENT_KEY,
        key: PAYMENT_LIST_KEY,
        permissions: ['change_orders']
    },

    //PROGRESS REPORTS
    view_progress_report: {
        parent_key: PROGRESS_REPORT_PARENT_KEY,
        key: REPORT_LIST_KEY,
        permissions: ['view_report']
    },
    add_progress_report: {
        parent_key: PROGRESS_REPORT_PARENT_KEY,
        key: REPORT_LIST_KEY,
        permissions: ['add_report']
    },
    edit_propgress_report: {
        parent_key: PROGRESS_REPORT_PARENT_KEY,
        key: REPORT_LIST_KEY,
        permissions: ['change_report']
    },
    delete_propgress_report: {
        parent_key: PROGRESS_REPORT_PARENT_KEY,
        key: REPORT_LIST_KEY,
        permissions: ['delete_report']
    },

    // Progress report: Teacher report
    // Просматривать данные оценок учителей
    view_teacher_report: {
        parent_key: PROGRESS_REPORT_PARENT_KEY,
        key: TEACHER_REPORT_LIST_KEY,
        permissions: ['view_teacherreport']
    },
    // Просматривать только свои данные оценок учителя
    view_own_teacher_report: {
        parent_key: PROGRESS_REPORT_PARENT_KEY,
        key: TEACHER_REPORT_LIST_KEY,
        permissions: ['view_teacher_own_report']
    },
    // Добавить данные оценки учителя
    add_teacher_report: {
        parent_key: PROGRESS_REPORT_PARENT_KEY,
        key: TEACHER_REPORT_LIST_KEY,
        permissions: ['add_teacherreport']
    },

    // Для отчетов нужны будут собственные права
    view_report_worked_hours: {
        link: 'reports/worked-hours',
        parent_key: WIDGET_PARENT_KEY,
        key: WIDGET_CLIENT_LIST_KEY,
        permissions: ['view_widget_result']
    },
    view_report_vacation_days: {
        link: 'reports/vacation-days',
        parent_key: EMPLOYEES_PARENT_KEY,
        key: VACATION_MINUTES_LIST_KEY,
        permissions: ['view_vacation_minutes', 'view_own_vacation_minutes']
    },
    view_report_client_transactions: {
        link: 'reports/client-transactions',
        parent_key: PAYMENT_PARENT_KEY,
        key: PAYMENT_LIST_KEY,
        permissions: ['view_orders', 'view_own_orders']
    },
    view_report_receipt_of_funds: {
        link: 'pays/receipt-of-funds',
        parent_key: PAYMENT_PARENT_KEY,
        key: REFUND_LIST_KEY,
        permissions: ['view_refunds_transfers']
    },

    view_studytest_list: {
        link: 'study/study-tests',
        dynamic: false,
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['view_study_test']
    },
    view_work_with_clients_page: {
        link: 'reports/client',
        dynamic: false,
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['view_study_test']
    },
    view_average_check_report: {
        link: 'reports/average_check_report',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_client_lifetime_report: {
        link: 'reports/client_lifetime',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_dropped_students_report: {
        link: 'reports/dropped_students',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_new_students_report: {
        link: 'reports/new_students',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_teacher_rating_report: {
        link: 'reports/teacher_rating',
        dynamic: false,
        parent_key: PROGRESS_REPORT_PARENT_KEY,
        key: TEACHER_REPORT_LIST_KEY,
        permissions: ['view_teacherreport']
    },
    view_employee_rating_report: {
        link: 'reports/employee_rating',
        dynamic: false,
        parent_key: PROGRESS_REPORT_PARENT_KEY,
        key: TEACHER_REPORT_LIST_KEY,
        permissions: ['view_teacherreport']
    },
    view_client_occupancy_report: {
        link: 'reports/occupancy-report',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_average_group_client_report']
    },
    view_group_stages_report: {
        link: 'reports/group_stages',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_group_stages_report']
    },
    view_subscriptions_sold_report: {
        link: 'reports/subscriptions_sold',
        parent_key: UNIDANCE_PARENT_KEY,
        key: SUBSCRIPTIONS_KEY,
        permissions: ['view_subscriptions']
    },
    view_finreport_report: {
        link: 'reports/finreport',
        parent_key: UNIDANCE_PARENT_KEY,
        key: SCHEDULE_KEY,
        permissions: ['view_schedule_teachers']
    },
    view_mailing_report: {
        link: 'reports/zero-balance-sub',
        parent_key: UNIDANCE_PARENT_KEY,
        key: SCHEDULE_KEY,
        permissions: ['view_schedule_teachers']
    },
    view_stats_coaches_report: {
        link: 'reports/stats_coaches',
        dynamic: false,
        parent_key: UNIDANCE_PARENT_KEY,
        key: SCHEDULE_KEY,
        permissions: ['view_schedule_teachers']
    },
    view_attendance_map_report: {
        link: 'reports/attendance_map',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_teacher_loading_report: {
        link: 'reports/loading_teachers',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_audiences_loading_report: {
        link: 'reports/loading_audiences',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_trial_lesson_report: {
        link: 'reports/trial_lesson',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_docs_constructor: {
        link: 'docs/constructor',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_docs_not_signed: {
        link: 'docs/not-signed',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_CLIENT_SIGNED_DOCUMENT,
        permissions: ['add_client_signed_document']
    },
    view_docs_signed: {
        link: 'docs/signed',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_CLIENT_SIGNED_DOCUMENT,
        permissions: ['add_client_signed_document']
    },
    view_docs_template_list: {
        link: 'docs/template-list',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_docs_viewer: {
        link: 'docs/viewer/contract',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_CLIENT_SIGNED_DOCUMENT,
        permissions: ['add_client_signed_document']
    },
    view_template_viewer: {
        link: 'docs/viewer/template',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_CLIENT_SIGNED_DOCUMENT,
        permissions: ['add_client_signed_document']
    },
    view_docs_print: {
        link: 'docs/viewer/print',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_CLIENT_SIGNED_DOCUMENT,
        permissions: ['add_client_signed_document']
    },
    view_docs_template_print: {
        link: 'docs/viewer/template-print',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_CLIENT_SIGNED_DOCUMENT,
        permissions: ['add_client_signed_document']
    },
    view_contract_constructor: {
        link: 'contract/constructor',
        dynamic: true,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_contract_template_list: {
        link: 'contract/template-list',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_contract_viewer: {
        link: 'contract/viewer/contract',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_EMPLOYEE_SIGNED_DOCUMENT,
        permissions: ['view_own_employees_signed_document', 'view_employees_signed_document', 'change_employees_signed_document']
    },
    view_contract_template_viewer: {
        link: 'contract/viewer/template',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_CLIENT_SIGNED_DOCUMENT,
        permissions: ['add_client_signed_document']
    },
    view_contract_not_signed: {
        link: 'contract/not-signed',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_EMPLOYEE_SIGNED_DOCUMENT,
        permissions: ['view_own_employees_signed_document', 'view_employees_signed_document']
    },
    view_contract_signed: {
        link: 'contract/signed',
        dynamic: false,
        parent_key: DOCUMENTS_PARENT_KEY,
        key: DOCUMENTS_EMPLOYEE_SIGNED_DOCUMENT,
        permissions: ['view_own_employees_signed_document', 'view_employees_signed_document']
    },

    // Study
    view_study_tests: {
        link: 'study/study-tests',
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['view_study_test']
    },
    view_purpose_tests: {
        link: 'study/purpose-tests',
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['view_study_test']
    },
    view_studytest_questions: {
        link: 'study/test-questions',
        //dynamic: false,
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['view_study_test']
    },
    view_studytest_purpose_test_list: {
        link: 'study/purpose-tests',
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['view_study_test', 'view_own_study_test']
    },
    //change_study_test | study | Учебное тестирование | Изменить учебное тестирование
    edit_study_test: {
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['change_study_test']
    },
    //delete_study_test | study | Учебное тестирование | Удалить учебное тестирование
    delete_study_test: {
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['delete_study_test']
    },
    view_studytest_purpose_student_test_list: {
        link: 'c/study/my-tests',
        dynamic: false,
        parent_key: STUDYTEST_PARENT_KEY,
        key: PURPOSED_TEACHER_TEST,
        permissions: ['view_own_purposed_tests']
    },
    view_studytest_purpose_student_test_result: {
        link: 'study/my-test',
        dynamic: false,
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDY_INIT_TEST_KEY,
        permissions: ['add_student_init_test']
    },
    view_studytest_purpose_student_simple_result: {
        link: 'study/my-simple-result',
        dynamic: false,
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDY_INIT_TEST_KEY,
        permissions: ['add_student_init_test']
    },
    view_studytest_purpose_teacher_simple_result: {
        link: 'study/simple-result',
        dynamic: true,
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['view_study_test']
    },
    view_study_test: {
        link: 'study/test',
        dynamic: false,
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['view_study_test']
    },
    view_init_test: {
        link: 'study/init-test',
        dynamic: false,
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDY_INIT_TEST_KEY,
        permissions: ['add_student_init_test']
    },
    view_request_test: {
        link: 'c/study/request-test',
        dynamic: false,
        parent_key: STUDYTEST_PARENT_KEY,
        key: STUDYTEST_LIST_KEY,
        permissions: ['view_study_test', 'view_own_study_test']
    },
    //add_purpose_test | study | Назначение тестирования | Добавить назначение теста студентам
    add_purpose_test: {
        parent_key: STUDYTEST_PARENT_KEY,
        key: PURPOSED_TEACHER_TEST,
        permissions: ['add_purpose_test']
    },

    view_widget_bundle_list: {
        link: 'c/widget/bundles',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_BUNDLE_LIST_KEY,
        permissions: ['view_age_program_level_bundle']
    },
    edit_widget_bundle: {
        link: 'c/widget/bundles',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_BUNDLE_LIST_KEY,
        permissions: ['change_age_program_level_bundle']
    },
    view_placement_test_list_result: {
        link: 'c/tests/placement-test-clients',
        dynamic: true,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_RESULT_LIST,
        permissions: ['view_test_result']
    },
    delete_placement_test_result: {
        link: 'c/tests/placement-test-clients',
        dynamic: true,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_RESULT_LIST,
        permissions: ['delete_test_result']
    },
    edit_placement_test_create: {
        link: 'placement-test/create-widget',
        dynamic: true,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_CREATE,
        permissions: ['add_widget', 'change_widget']
    },
    delete_placement_test_widget: {
        link: 'placement-test/create-widget',
        dynamic: true,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_CREATE,
        permissions: ['delete_widget']
    },
    view_placement_test_widget_list: {
        link: 'c/placement_test/widget-list',
        dynamic: true,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_LIST_WIDGET,
        permissions: ['view_widget']
    },
    view_placement_test_result: {
        link: 'placement_test/result',
        dynamic: true,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_TEST_RESULT_LIST,
        permissions: ['view_test_result']
    },
    view_widget_bundle: {
        link: 'c/widget/bundle',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_BUNDLE_KEY,
        permissions: ['view_question']
    },
    edit_placement_test_question_widget: {
        link: 'c/widget/bundle',
        dynamic: false,
        parent_key: PLACEMENT_TEST_PARENT_KEY,
        key: PLACEMENT_BUNDLE_KEY,
        permissions: ["change_question", "add_question"],
    },
    // create_placement_test: {
    //     link: 'placement-test/create-widget',
    //     dynamic: false,
    //     parent_key: PLACEMENT_TEST_PARENT_KEY,
    //     key: PLACEMENT_VIEW_KEY,
    //     permissions: ['view_widget']
    // },
    view_calendar_list: {
        link: 'groups/calendar',
        dynamic: false,
        parent_key: SCHEDULE_PARENT_KEY,
        key: SCHEDULE_CALENDAR,
        permissions: ['view_holiday']
    },

    // employees
    // contracts | Контракт на работу
    // add_contracts | Добавить контракт на работу
    add_contracts: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: CONTRACT_LIST_KEY,
        permissions: ['add_contracts']
    },
    // contractdocs | Приложение к контракту
    // delete_contract_docs | Удалить приложения к контракту
    delete_contract_docs: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: CONTRACTDOCS_LIST_KEY,
        permissions: ['delete_contract_docs']
    },
    add_contract_docs: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: CONTRACTDOCS_LIST_KEY,
        permissions: ['add_contract_docs']
    },
    // workgraphicrules | Правило рабочего дня
    // Добавить правила рабочего дня
    // add_work_graphic_rules | Добавить правила рабочего дня
    add_work_graphic_rules: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: WORK_GRAPHIC_RULES_LIST_KEY,
        permissions: ['add_work_graphic_rules']
    },
    // change_work_graphic_rules | Изменить правила рабочего дня
    edit_work_graphic_rules: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: WORK_GRAPHIC_RULES_LIST_KEY,
        permissions: ['change_work_graphic_rules']
    },
    // Просмотреть правила рабочего дня
    view_work_graphic_rules: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: WORK_GRAPHIC_RULES_LIST_KEY,
        permissions: ['view_work_graphic_rules', 'view_own_work_graphic_rules']
    },
    // daytypes | Тип рабочего дня
    // change_day_types | Изменить тип рабочего дня
    change_day_types: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: DAY_TYPES_LIST_KEY,
        permissions: ['change_day_types']
    },

    // users
    // clientsprofile | Клиент
    // add_client Добавить клиента
    add_client: {
        parent_key: USER_PARENT_KEY,
        key: CLIENT_PROFILES_KEY,
        permissions: ['add_client']
    },
    // change_personal_information | users | Сотрудник | Редактировать персональную информацию
    edit_personal_information: {
        parent_key: USER_PARENT_KEY,
        key: PERSONAL_INFORMATION_LIST_KEY,
        permissions: ['change_personal_information', 'change_own_personal_information']
    },
    // change_comment | users | Сотрудник | Редактировать комментарии к профилю
    edit_profile_comment: {
        parent_key: USER_PARENT_KEY,
        key: COMMENT_PROFILE_LIST_KEY,
        permissions: ['change_comment']
    },

    // db
    // location | Локации
    // add_location | Добавить локацию
    add_location: {
        parent_key: DB_PARENT_KEY,
        key: LOCATION_KEY,
        permissions: ['add_location']
    },

    // lessons
    // course | Курс
    // add_course | Добавить новый курс
    add_course: {
        parent_key: COURSES_PARENT_KEY,
        key: COURSES_LIST_KEY,
        permissions: ['add_course']
    },
    // change_course | Обновить курс
    change_course: {
        parent_key: COURSES_PARENT_KEY,
        key: COURSES_LIST_KEY,
        permissions: ['change_course']
    },
    change_ebook: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_LIST_KEY,
        permissions: ['change_ebook']
    },
    change_ebook_in_catalog: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_LIST_KEY,
        permissions: ['change_ebook_in_catalog']
    },
    copy_ebook: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_LIST_KEY,
        permissions: ['copy_ebook']
    },
    copy_ebook_in_catalog: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_LIST_KEY,
        permissions: ['copy_ebook_in_catalog']
    },
    delete_ebook: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_LIST_KEY,
        permissions: ['delete_ebook']
    },
    delete_ebook_from_catalog: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_LIST_KEY,
        permissions: ['delete_ebook_from_catalog']
    },
    // delete_course | Удалить курс
    delete_course: {
        parent_key: COURSES_PARENT_KEY,
        key: COURSES_LIST_KEY,
        permissions: ['delete_course']
    },
    // Добавить ссылку "поделиться курсом"
    add_share_course_link: {
        parent_key: COURSES_PARENT_KEY,
        key: SHARECOURSELINK_LIST_KEY,
        permissions: ['add_share_course_link']
    },
    add_course_docs: {
        parent_key: COURSES_PARENT_KEY,
        key: COURSE_DOCUMENTS_KEY,
        permissions: ['add_document_lesson']
    },
    view_course_docs: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EBOOK_DOCUMENTS_KEY,
        permissions: ['view_own_document', 'view_document']
    },
    // assignexercise Назначение упражнения студентам
    // view_own_assign_exercise | Просмотр своих назначенных упражнений студентам
    view_own_assign_exercise: {
        parent_key: COURSES_PARENT_KEY,
        key: ASSIGNEXERCISE_LIST_KEY,
        permissions: ['view_own_assign_exercise']
    },

    // groups
    // studygroup | учебная группа
    // add_study_groups | Добавить группу",
    add_study_groups: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['add_study_groups']
    },
    // groupsstatus | статус группы
    // change_groups_statuses | Изменить статусы групп
    change_groups_statuses: {
        link: 'c/groups/change-days-status',
        parent_key: GROUPS_PARENT_KEY,
        key: GROUPSSTATUS_LIST_KEY,
        permissions: ['change_groups_statuses']
    },
    // цена группы по умолчанию
    // Просматривать суммы цен групп
    view_groups_price_sums: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUPPRICESUM_LIST_KEY,
        permissions: ['view_groups_price_sums']
    },

    // schedule
    // schedule | Расписание
    // add_schedule | Добавить расписание
    add_schedule: {
        parent_key: SCHEDULE_PARENT_KEY,
        key: SCHEDULE_LIST_KEY,
        permissions: ['add_schedule']
    },
    view_group_off_by: {
        link: 'reports/groups-money',
        parent_key: ENTERIES_PARENT_KEY,
        key: GROUP_WRITE_OFF_BY,
        permissions: ['view_group_operations_report'],
    },
    // view_employee_report | Просматривать записи дней присутствия студентов
    view_employee_report: {
        parent_key: SCHEDULE_PARENT_KEY,
        key: EMPLOYEEREPORT_LIST_KEY,
        permissions: ['view_employee_report'],
    },
    view_my_slot_settings: {
        link: 'slot-settings',
        parent_key: ONLINE_SCHEDULE_PARENT_KEY,
        key: SLOTS_EMPLOYEE_SETTINGS,
        permissions: ['view_employee_settings']
    },
    view_zoom_link: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_group_zoom_link']
    },
    edit_zoom_link: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['change_group_zoom_link']
    },
    view_telegram_link: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_group_telegram_link']
    },
    edit_telegram_link: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['change_group_telegram_link']
    },
    view_group_near_schedules: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_group_near_schedules']
    },
    view_archived_groups: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_archived_groups'],
    },
    view_automade_groups: {
        parent_key: GROUPS_PARENT_KEY,
        key: GROUP_LIST_KEY,
        permissions: ['view_automade_groups'],
    },
    view_client_group_comment: {
        parent_key: GROUPS_PARENT_KEY,
        key: COMMENT_PROFILE,
        permissions: ['view_client_group_comment'],
    },
    add_client_group_comment: {
        parent_key: GROUPS_PARENT_KEY,
        key: COMMENT_PROFILE,
        permissions: ['add_client_group_comment'],
    },
    change_client_group_comment: {
        parent_key: GROUPS_PARENT_KEY,
        key: COMMENT_PROFILE,
        permissions: ['change_client_group_comment'],
    },
    // view_own_student_book | ebooks | Электронная книга студента | Просматривать свою книгу студентом
    view_own_student_book: {
        parent_key: EBOOKS_PARENT_KEY,
        key: STUDENTSEBOOKS_KEY,
        permissions: ['view_own_student_book']
    },
    // delete_student_book | ebooks | Электронная книга студента | Удалить книгу студенту
    delete_student_book: {
        parent_key: EBOOKS_PARENT_KEY,
        key: STUDENTSEBOOKS_KEY,
        permissions: ['delete_student_book']
    },
    // view_study_course | ebooks | Учебный курс | Просмотр учебного курса
    view_study_course: {
        parent_key: EBOOKS_PARENT_KEY,
        key: STUDYCOURSE_KEY,
        permissions: ['view_study_course']
    },

    // UniDance
    view_client_barcode: {
        link: 'c/unidance/barcode-clients',
        parent_key: UNIDANCE_PARENT_KEY,
        key: BARCODE_KEY,
        permissions: ['view_barcode_client']
    },
    change_client_barcode: {
        link: 'c/unidance/barcode-clients',
        parent_key: UNIDANCE_PARENT_KEY,
        key: BARCODE_KEY,
        permissions: ['change_barcode_client']
    },
    // Страница расписаний
    page_training_lessons: {
        link: 'unidance/training-lessons',
        parent_key: UNIDANCE_PARENT_KEY,
        key: BARCODE_KEY,
        permissions: ['view_barcode_client']
    },
    // Страница учебных занятий
    page_dance_schedules: {
        link: 'c/unidance/schedules',
        parent_key: UNIDANCE_PARENT_KEY,
        key: SCHEDULE_KEY,
        permissions: ['view_schedule_teachers']
    },
    // Страница абонементов
    page_dance_subscriptions: {
        link: 'c/unidance/subscriptions',
        parent_key: UNIDANCE_PARENT_KEY,
        key: SCHEDULE_KEY,
        permissions: ['view_schedule_teachers']
    },
    // add_master | unidance | Мастер | Добавить мастера
    add_master: {
        parent_key: UNIDANCE_PARENT_KEY,
        key: MASTERSPROFILE_KEY,
        permissions: ['add_master']
    },
    // change_master | unidance | Мастер | Изменить мастера
    change_master: {
        parent_key: UNIDANCE_PARENT_KEY,
        key: MASTERSPROFILE_KEY,
        permissions: ['change_master']
    },
    // delete_master | unidance | Мастер | Удалить мастера
    delete_master: {
        parent_key: UNIDANCE_PARENT_KEY,
        key: MASTERSPROFILE_KEY,
        permissions: ['delete_master']
    },
    // view_master | unidance | Мастер | Показать мастера
    view_master: {
        link: 'c/unidance/masters',
        parent_key: UNIDANCE_PARENT_KEY,
        key: MASTERSPROFILE_KEY,
        permissions: ['view_master']
    },
    //add_filedocuments
    add_filedocuments: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: FILE_DOCUMENTS_KEY,
        permissions: ['add_filedocuments']
    },
    view_filedocuments: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: FILE_DOCUMENTS_KEY,
        permissions: ['view_filedocuments']
    },
    view_own_filedocuments: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: FILE_DOCUMENTS_KEY,
        permissions: ['view_own_filedocuments']
    },
    delete_filedocuments: {
        parent_key: EMPLOYEES_PARENT_KEY,
        key: FILE_DOCUMENTS_KEY,
        permissions: ['delete_filedocuments']
    },
    add_exercise: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EXERCISE_KEY ,
        permissions: ['add_exercise']
    }, // создать новое упражнение

    change_exercise: {
        parent_key: EBOOKS_PARENT_KEY,
        key: EXERCISE_KEY,
        permissions: ['change_exercise']
    },
    change_chapter: {
       parent_key: EBOOKS_PARENT_KEY,
       key:  EBOOK_CHAPTER_KEY,
       permissions: ['change_chapter']
    },
    add_chapter_section: {
        parent_key:  EBOOKS_PARENT_KEY,
        key: CHAPTER_SECTION_KEY,
        permissions: ['add_chapter_section']
    }, // добавить раздел

    change_chapter_section: {
        parent_key:  EBOOKS_PARENT_KEY,
        key: CHAPTER_SECTION_KEY,
        permissions: ['change_chapter_section']
    }, //менять места , удалить , редактировать


    // add_ebook_chapter -   ebooks/book/id Action menu  add edit del
    // add_ebook_chapter - добавить новую




}