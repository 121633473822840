import React, {useCallback, useEffect} from "react"
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import StudyStore from "Stores/studyStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import QuestionPoints from "./components/QuestionPoints";
import MatchWords from "./components/MatchWords";
import SortSentences from "./components/SortSentences";
import ImageVariants from "./components/ImageVariants/ImageVariants";
import MatchImages from "./components/MatchImages/MatchImages";
import st from './TestQuestion.module.scss'
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormSelectCustom from "components/UI/AsideModal/Form/FormSelectCustom";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";
import FileSelector from "components/UI/FileSelector/FileSelector";
import TextareaMarkup from "components/UI/TextareaMarkup/TextareaMarkup";
import asideModalStore from "Stores/asideModalStore";
import NewVariants from "./components/NewVariants/NewVariants";
import useLocalization from "hooks/useLocalization";

export const questionsTypes = {
    TEXT: "question_plus_text", // Вопрос с текстовым ответом
    VARIANTS: "variants", // Вопрос с вариантами ответов
    COMPLETE_SENTENCE: "complete_sentence", // Собрать предложение
    MISSING_WORDS: "text_missing_words", // Выбрать пропущенные слова в тексте
    WORDS_IN_BLANKS: "words_in_blanks", // Вписать слова в пропусках
    DRAG_WORDS_IN_BLANKS: "drag_words_in_blanks", // Перетащить слова в пропуски
    AUDIO_PLUS_VARIANTS: "audio_plus_variants", // Аудиофайл с вариантами ответов
    VIDEO_PLUS_VARIANTS: "video_plus_variants", // Видеофайл с вариантами ответов
    WORD_MATCHING: "word_matching", // Сопоставление слов
    MAKE_WORD_FROM_LETTERS: "make_word_from_letters", // Составить слово из букв
    ESSAY: "essay", // Написать сочинение
    SORT_SENTENCES: "sort_sentences", // Сортировать предложения
    QUESTION_IMAGE_VARIANTS: "question_image_variants", // Вопрос с изображениями в качестве вариантов
    MATCH_WORDS_IMAGES: "match_words_images", // Сопоставить слова с изображениями
    IMAGE_MATCHING: "image_matching", // Сопоставить изображения
}

export const getUUID = () => Date.now().toString().split('').sort(() => .5 - Math.random()).join('')

export const getInitAnswer = type => {
    switch (type) {
        case questionsTypes.WORD_MATCHING:
            return {value: '|', is_correct: true}
        case questionsTypes.SORT_SENTENCES:
            return {value: '', is_correct: true}
        case questionsTypes.QUESTION_IMAGE_VARIANTS:
            return {value: '|', is_correct: false, uuid: getUUID()}
        case questionsTypes.MATCH_WORDS_IMAGES:
        case questionsTypes.IMAGE_MATCHING:
            return {value: '|', is_correct: true, uuid: getUUID()}
        default:
            return {}
    }
}

const TestQuestion = () => {
    const defaultValid = {
        type: 'required',
        text_question: 'required',
        point: 'required',
    }

    const {translate} = useLocalization({
        section: 'profile',
        subsection: 'study_tests',
        dict: {
            "select_category": "Select a question category",
            "audio_files": "Audio Files",
            "video_files": "Video Files"
        }
    })

    const {
        modalSettings: ms,
        modalValues,
        changeValue: changeValues,
        changeValues: changeValuesArray,
        setValid,
        check
    } = asideModalStore

    const {questionTypesData, testSections} = StudyStore

    const changeTypeHandler = type => {
        const obj = {
            study_test: modalValues.study_test,
            type,
            text_question: '',
            answers: [],
            files: [],
            point: 1,
        }

        if ([questionsTypes.WORD_MATCHING, questionsTypes.SORT_SENTENCES, questionsTypes.QUESTION_IMAGE_VARIANTS,
            questionsTypes.MATCH_WORDS_IMAGES, questionsTypes.IMAGE_MATCHING].includes(type)) {
            obj.answers = [
                getInitAnswer(type),
                getInitAnswer(type),
            ]
        }

        changeValuesArray(obj)
    }

    const imageChangeHandler = file => {
        let files = modalValues.files.filter(x => !x.is_question)
        if (file)
            files = files.concat([{file, is_question: true}])
        changeValues(files, 'files')
    }

    const singleAnswer = value => {
        const answer = [{value, is_correct: true}]
        changeValues(answer, 'answers')
    }

    const singleHint = value => {
        changeValues(value, 'hint')
    }

    const mediaChangeHandler = file => {
        let files = modalValues.files.filter(x => x.is_question)
        if (file)
            files = files.concat([{file}])
        changeValues(files, 'files')
    }

    const getPointsLabel = useCallback(() => {
        const everyAnswer = [
            questionsTypes.MISSING_WORDS,
            questionsTypes.WORDS_IN_BLANKS,
            questionsTypes.DRAG_WORDS_IN_BLANKS,
            questionsTypes.WORD_MATCHING,
            questionsTypes.MATCH_WORDS_IMAGES,
            questionsTypes.IMAGE_MATCHING
        ]
        if (everyAnswer.includes(modalValues.type))
            return localisationMaster('number-of-points-each-answer', 'groups/group')
        else
            return localisationMaster('points', 'groups/group')
    }, [modalValues.type])

    const renderBody = type => {
        switch (type) {
            case questionsTypes.TEXT:
                return (
                    <div className="super-modal__form-field">
                        <FormLabel isRequired>Answer:</FormLabel>
                        <TextareaMarkup
                            stateName="Answers"
                            value={modalValues.answers[0]?.value || ''}
                            onChange={singleAnswer}
                            error={check('answers')}
                        />
                        <div className={st.textExample}>
                            {localisationMaster('text-info-one', 'tests/questions', 'question_plus_text')}
                            {localisationMaster('text_35', 'study/study-test')}
                        </div>
                    </div>
                )
            case questionsTypes.VARIANTS:
                return (
                    <NewVariants och={changeValues} data={modalValues.answers || []} error={check('answers')}/>
                )
            case questionsTypes.COMPLETE_SENTENCE:
                return (
                    <div className="super-modal__form-field">
                        <FormLabel isRequired>Sentence:</FormLabel>
                        <div className={st.customTextareaWrap}>
                            <TextareaMarkup
                                stateName="Sentence"
                                value={modalValues.answers?.length ? modalValues.answers[0].value : ''}
                                onChange={singleAnswer}
                                error={check('answers')}
                            />
                        </div>
                        <div className={st.textExample}>
                            <p>{localisationMaster('text_37', 'study/study-test')}</p>
                            <br/>
                            <p><strong>{localisationMaster('text_38', 'study/study-test')}:</strong></p>
                            <p className={st.exmpl}>We|moved|to|California|last|summer</p>
                        </div>
                    </div>
                )
            case questionsTypes.MISSING_WORDS:
                return (
                    <div className="super-modal__form-field">
                        <FormLabel isRequired>Text:</FormLabel>
                        <div className={st.customTextareaWrap}>
                            <TextareaMarkup
                                stateName="Text"
                                value={modalValues.answers?.length ? modalValues.answers[0].value : ''}
                                onChange={singleAnswer}
                                error={check('answers')}
                            />
                        </div>
                        <div className={st.textExample}>
                            <p>{localisationMaster('text_42', 'study/study-test')}</p>
                            <br/>
                            <p><strong>{localisationMaster('text_38', 'study/study-test')}:</strong></p>
                            <p className={st.exmpl}>They [is|are|was|were*] on holiday yesterday</p>
                        </div>
                    </div>
                )
            case questionsTypes.WORDS_IN_BLANKS:
            case questionsTypes.DRAG_WORDS_IN_BLANKS:
                return (
                    <div className="super-modal__form-field">
                        <FormLabel isRequired>Text:</FormLabel>
                        <div className={st.customTextareaWrap}>
                            <TextareaMarkup
                                stateName="text"
                                value={modalValues.answers?.length ? modalValues.answers[0].value : ''}
                                onChange={singleAnswer}
                                error={check('answers')}
                            />
                        </div>
                        <div className={st.textExample}>
                            <p>{localisationMaster('text_39', 'study/study-test')}</p>
                            <br/>
                            <p><strong>{localisationMaster('text_50', 'study/study-test')}:</strong></p>
                            <p className={st.exmpl}>{localisationMaster('text_52', 'study/study-test')}</p>
                                <p><strong>{localisationMaster('text_51', 'study/study-test')}:</strong></p>
                                <p className={st.exmpl}> {localisationMaster('text_53', 'study/study-test')}</p>
                        </div>
                    </div>
                )
            case questionsTypes.AUDIO_PLUS_VARIANTS:
                return (
                    <>
                        <FormField>
                            <FormLabel isRequired>{translate.audio_files}:</FormLabel>
                            <FileSelector
                                type={'audio'}
                                error={check('files')}
                                types={['mp3', 'wav']}
                                value={modalValues.files?.find(x => !x.is_question)?.file || ''}
                                onChange={(file) => {
                                    mediaChangeHandler(file)
                                }}
                            />
                        </FormField>
                        <NewVariants och={changeValues} data={modalValues.answers || []} error={check('answers')}/>
                    </>
                )
            case questionsTypes.VIDEO_PLUS_VARIANTS:
                return (
                    <>
                        <FormField>
                            <FormLabel isRequired>{translate.video_files}:</FormLabel>
                            <FileSelector
                                type={'video'}
                                error={check('files')}
                                types={['mp4', 'mkv']}
                                value={modalValues.files?.find(x => !x.is_question)?.file || ''}
                                onChange={(file) => {
                                    mediaChangeHandler(file)
                                }}
                            />
                        </FormField>

                        <NewVariants och={changeValues} data={modalValues.answers || []} error={check('answers')}/>
                    </>
                )
            case questionsTypes.WORD_MATCHING:
                return <MatchWords name="answers" values={modalValues.answers} onChange={changeValues}
                                   error={check('answers')}/>
            case 'make_word_from_letters':
                return (
                    <div className="super-modal__form-field">
                        <div>
                            <input placeholder="Word"
                                   type="text"
                                   value={modalValues.answers[0]?.value || ''}
                                   onChange={e => singleAnswer(e.target.value)}
                                   className={`${check('answers') ? 'super-modal__input--error' : ''} ${st.inpt}`}/>
                        </div>
                        <div>
                            <input placeholder="Hint"
                                   type="text"
                                   value={modalValues.hint || ''}
                                   onChange={e => changeValues(e.target.value, 'hint')}
                                   className={`${check('answers') ? 'super-modal__input--error' : ''} ${st.inpt}`}/>
                        </div>
                        <div className={st.notice}>
                            {localisationMaster('text_41', 'study/study-test')}
                        </div>
                    </div>
                )
            case questionsTypes.ESSAY:
                return (
                    <div className={`${st.textExample}`}>
                        {localisationMaster('text_40', 'study/study-test')}
                    </div>
                )
            case questionsTypes.SORT_SENTENCES:
                return <SortSentences name="answers" values={modalValues.answers} onChange={changeValues}
                                      error={check('answers')}/>
            case 'question_image_variants':
                return (
                    <ImageVariants
                        title={localisationMaster('table-row-title-6', 'tests/questions', 'question_plus_variants')}
                        subtitle={localisationMaster('table-row-title-7', 'tests/questions', 'question_plus_variants')}
                        error={check('answers')}
                    />
                )
            case questionsTypes.MATCH_WORDS_IMAGES:
                return (
                    <ImageVariants
                        title={localisationMaster('text_43', 'study/study-test')}
                        subtitle={localisationMaster('text_44', 'study/study-test')}
                        error={check('answers')}
                    />
                )
            case questionsTypes.IMAGE_MATCHING:
                return (
                    <MatchImages error={check('answers')}/>
                )
            default:
                break;
        }
    }

    useEffect(() => {
        const valid = defaultValid

        if (modalValues.type) {
            switch (modalValues.type) {
                case questionsTypes.AUDIO_PLUS_VARIANTS:
                    valid.answers = answers =>
                        answers.length > 1 &&
                        answers.some(a => a.is_correct) &&
                        answers.every(a => a.value.length > 0)
                    valid.files = files => files?.length > 0
                    break;
                case questionsTypes.VIDEO_PLUS_VARIANTS:
                    valid.answers = answers =>
                        answers.length > 1 &&
                        answers.some(a => a.is_correct) &&
                        answers.every(a => a.value.length > 0);
                    valid.files = (files = []) => files.some(item => item.file.includes('mkv') || item.file.includes('mp4'));
                    break;
                case questionsTypes.VARIANTS:
                case questionsTypes.QUESTION_IMAGE_VARIANTS:
                    valid.answers = answers =>
                        answers.length > 1 &&
                        answers.some(a => a.is_correct) &&
                        answers.every(a => a.value.length > 0)
                    break;
                case questionsTypes.AUDIO_PLUS_VARIANTS:
                    valid.answers = answers =>
                        answers.length > 1 &&
                        answers.some(a => a.is_correct) &&
                        answers.every(a => a.value.length > 0);
                    valid.files = (files = []) => files.some(item => item.file.includes('wav') || item.file.includes('mp3'));
                    break;
                case questionsTypes.WORD_MATCHING:
                    valid.answers = answers =>
                        answers.length >= 1 &&
                        answers.every(a => a.value.split('|').every(v => v.length >= 1))
                    break;
                case questionsTypes.IMAGE_MATCHING:
                case questionsTypes.MATCH_WORDS_IMAGES:
                    valid.answers = answers =>
                        answers.length > 1 &&
                        answers.every(a => a.value.split('|').every(v => v.length > 1))
                    break;
                case questionsTypes.ESSAY:
                    break;
                default:
                    valid.answers = answers => answers.length > 0 && answers.every(a => a.value.length > 1)
                    break;
            }
        }

        setValid(valid)
    }, [modalValues.type])


    return (
        <FormFieldset>
            <FormField>
                <FormLabel isRequired>Question type:</FormLabel>
                <FormSelectCustom>
                    <SelectCustom
                        placeholder={localisationMaster('text_45', 'study/study-test')}
                        options={questionTypesData}
                        valueID={modalValues.type}
                        onChange={o => changeTypeHandler(o.value)}
                        error={check('type')}
                        isDisabled={ms.is_put}
                    />
                </FormSelectCustom>
            </FormField>
            {testSections.length > 0 &&
                <FormField>
                    <FormLabel>Category:</FormLabel>
                    <FormSelectCustom>
                        <SelectCustom
                            placeholder={translate.select_category}
                            options={testSections}
                            valueID={modalValues.test_section}
                            onChange={o => {changeValues(o.id, 'test_section')}}
                            error={check('test_section')}
                        />
                    </FormSelectCustom>
                </FormField>
            }
            {
                !modalValues.type && <div className={st.addQuestionTypeHint}>
                    To begin, select question type. You can get acquainted with all types of question Here.
                </div>
            }

            {modalValues.type &&
                <>
                    <FormField>
                        <FormLabel>Main image:</FormLabel>
                        <FileSelector types={['jpg', 'gif', 'png']}
                                      value={modalValues.files?.find(x => x.is_question)?.file || ''}
                                      onChange={imageChangeHandler}
                        />
                    </FormField>
                    <FormField>
                        <FormLabel>{translate.audio_files}:</FormLabel>
                        <FileSelector
                            type={'audio'}
                            types={['mp3', 'wav']}
                            value={modalValues.audio_file || ''}
                            onChange={(file) => {
                                changeValues(file, 'audio_file')
                            }}
                        />
                    </FormField>
                    <FormField>
                        <FormLabel isRequired>Question or condition:</FormLabel>
                        <TextareaMarkup
                            placeholder={"Enter text"}
                            value={modalValues.text_question || ''}
                            onChange={changeValues}
                            stateName="text_question"
                            error={check('text_question')}/>
                    </FormField>

                    {renderBody(modalValues.type)}

                    <QuestionPoints label={getPointsLabel()} name="point" value={modalValues.point}
                                    onChange={changeValues}/>
                </>}
        </FormFieldset>
    )
}

export default observer(TestQuestion);
