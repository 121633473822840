import React from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {getInitAnswer} from "../../TestQuestion"
import Checkbox from "components/ContentBox/Global/Checkbox";
import ImageVariant from "./ImageVariant";
import st from './ImageVariants.module.scss';
import NewVariantDel from "../NewVariants/NewVariantDel/NewVariantDel";
import NewVariantAdd from "../NewVariants/NewVariantAdd/NewVariantAdd";
import asideModalStore from "Stores/asideModalStore";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";

const ImageVariants = props => {

    const {modalValues: md, changeValue: changeValues , changeValues: changeValuesArray} = asideModalStore;

    const action = (type, pl) => {
        let answers = []
        let files = []
        switch (type) {
            case 'check':
                answers = md.answers.map((x, i) => i === pl.index ? {...x, is_correct: !x.is_correct} : x)
                changeValues(answers, 'answers')
                break
            case 'change':
                answers = md.answers.map((x, i) => i === pl.index ? {...x, value: pl.value} : x)
                const file = pl.value.split('|')[0]
                files = md.files.filter(f => f.file !== file).concat([{file}])
                changeValuesArray({
                    ...md,
                    answers,
                    files,
                })
                break
            case 'add':
                answers = md.answers.concat([getInitAnswer(md.type)])
                changeValues(answers, 'answers')
                break
            case 'delete':
                answers = md.answers.filter((x, i) => i !== pl.index)
                //console.info('answers: ', answers)
                const aFileInAnswers = answers
                    .reduce((acc, {value}) => `${acc}|${value.split('|')[0]}`, '')
                    .split('|')
                    .filter(a => a.length > 0)
                //console.info('aFileInAnswers: ', aFileInAnswers)
                //console.info('md.files: ', md.files)
                files = md.files.filter(f => f.is_question || aFileInAnswers.includes(f.file))
                //console.info('files: ', files)
                changeValuesArray({
                    ...md,
                    answers,
                    files,
                })
                break
            // case 'clear':
            //     answers = md.answers.map((x, i) => i !== pl.index ? x : {value: '|'})
            //     files = md.files.filter((x, i) => i !== pl.index)
            //     changeValuesArray({
            //         ...md,
            //         answers,
            //         files
            //     })
        }
    }


    return (
        <div className={st.box}>
            <div className={st.title}>
                <FormLabel isRequired>{props.title}</FormLabel>
                <span>{props.subtitle}</span>
            </div>
            <div className={st.variants}>{md.answers.map((item, index) =>
                <div className={st.variant} key={item.uuid || item.value.split('|')[0]}>
                    {md.type === 'question_image_variants' &&
                    <div>
                        <Checkbox
                            id={`variant${index}`}
                            checked={item.is_correct}
                            isChecked={() => action('check', {index})}
                            error={props.error && !md.answers.some(a => a.is_correct)}
                        />
                    </div>}

                    <div className={st.cell}>
                                <ImageVariant value={item.value}
                                              onChange={value => action('change', {index, value})}
                                              error={props.error}
                                />
                    </div>
                    {md.answers.length > 2 &&
                        <div className={st.deleteVariantBtn}>
                            <NewVariantDel onClick={() => action('delete', {index})}/>
                        </div>
                    }
                </div>)}
            </div>
            <NewVariantAdd onClick={() => action('add')}/>
        </div>
    );
};

ImageVariants.propTypes = {
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    error: PropTypes.bool,
}

ImageVariants.defaultProps = {
    onChange: () => {}
}

export default observer(ImageVariants)
