import React from 'react';
import st from './PrevNextButtons.module.scss';
import {classNames as cn} from 'helpers'

export default function PrevNextButtons({isFirst, getBack, answer, checkIsTestReady, sendAnswer, isSending}) {
  return (
    <div className={st.buttons}>
      <button
        className={cn({
          'button-type1': true,
          'button-type1--disabled': isFirst || isSending,
        })}
        onClick={() => !isFirst && getBack()}>
        Предыдущий вопрос
      </button>

      <button
        className={cn({
          'button-type1': true,
          'button-type1--disabled': !answer[1]?.length > 0 || isSending,
        })}
        onClick={() => !isSending && sendAnswer()}>
        {checkIsTestReady() ? 'К результатам' : 'Следующий вопрос'}
      </button>
    </div>
  );
};

