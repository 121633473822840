import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import AsideFilterStore from "components/UI/AsideFilter/asideFilterStore";
import PageHeader from "../../../UI/PageHeader/PageHeader";
import {HeaderAddButton} from "components/UI/SuperButton/SuperButton";
import EmptyList from "../Common/EmptyList/EmptyList";
import Loader from "components/UI/Loader/Loader";
import SearchBooks from "components/Modules/Ebook/Books/components/SearchBooks/SearchBooks";
import useLocalization from "hooks/useLocalization";
import TabMenu from "components/UI/TabMenu/TabMenu";
import Pagination from "components/UI/Pagination/Pagination";
import StudyTest from "./StudyTest";
import AsideFilter from "components/UI/AsideFilter/AsideFilter";
import FilterTextValues from "components/UI/AsideFilter/FilterTextValues";
import st from "./StudyTests.module.scss";
import useLocalStorageFilter from "helpers/useLocalStorageFilter";
import {useHistory} from "react-router-dom";
import asideModalStore from "../../../../Stores/asideModalStore";

const initPagination = {
    page: 1,
    limit: 10
}

const StudyTests = () => {
    const filterConfig = [
        {
            nameState: 'name',
            label: localisationMaster('text_18', 'study/study-test'),
            placeholder: localisationMaster('text_18', 'study/study-test'),
            type: 'input'
        },
        {
            apiLink: 'db/companies',
            nameState: 'company',
            label: 'Company',
            placeholder: localisationMaster('text_8', 'study/study-test'),
            type: "select"
        },
        {
            apiLink: 'study/levels',
            nameState: 'level',
            label: 'Level',
            placeholder: localisationMaster('text_12', 'online-schedule/future-slots'),
            type: "select_father"
        },
        {
            nameState: 'sub_level',
            label: 'Sub level',
            fatherName:'level',
            optionsKey: 'sub_lvl',
            placeholder: localisationMaster('select-five', 'tests/questions', 'question_plus_variants'),
            type: "select_son"
        },
    ]

    const {isOpenFilter, closeFilter, setTextValues, changeTextValue, filterTextValues} = AsideFilterStore

    const [data, setData] = useState(null)
    const [filter, updateFilter] = useLocalStorageFilter({filter_type: 1})
    const [tabs, setTabs] = useState([])
    const [pagination, setPagination] = useState(initPagination)
    const history = useHistory()

    const {translate} = useLocalization({
        section: 'study',
        subsection: 'study_tests',
        dict: {
            "filter": "Filter",
            "search": "Search",
            "search_placeholder": "Search for tests",
            "reset_all": "Reset All",
            "apply": "Apply",
            "add":"Add"
        }
    })

    const filteredData = data?.result?.filter(test => filter.filter_type === 1 ? !test.request : test.request);

    const createTest = () => {
        const md = {
            request: false,
    }
        const ms = {
            title: localisationMaster('text_1', 'study/study-test'),
            type: 'add-study-test',
            button: translate?.add,
            valid: {
                company: "required",
                name: "required",
                level: "required",
                sub_lvl: "required",
            },
            link: 'study/study_test',
            func: setData,
            isResponsive: true,
        }
        // modalStore.activity(ms)
        asideModalStore.activity(ms, md)
    }

    useEffect(() => {
        return AsideFilterStore.unmountFilter
    }, []);

    useEffect(() => {
        setData(null)
        superAxiosRequest({
            method: 'get',
            link: 'study/study_test',
            params: {
                ...filter,
                ...(filter.level && {level: filter.level?.id}),
                offset: (pagination.page - 1) * pagination.limit,
                limit: pagination.limit
            }
        }).then(({data}) => {
            setData(data)
            setTabs(data._meta.group_statuses.map(x => ({...x, id: x.filter_type})))
        })
    }, [filter, pagination]);

    useEffect(() => {
        const unlisten = history.listen((location) => {
            // Проверяем, если произошел переход на другую страницу
            if (location.pathname !== '/study/study-tests') {
                updateFilter({})
            }
        })

        return () => {
            unlisten() // Отменяем прослушивание при размонтировании компонента
        }
    }, [history, updateFilter]);

    return (
        <div className={st.container}>
            <PageHeader title="Educational Tests">
                <HeaderAddButton title={"Add New Test"} onClick={createTest}/>
            </PageHeader>

            <div className={st.page}>
                <SearchBooks
                    onSearch={text => {
                        updateFilter({...filter, name: text})
                        setPagination(initPagination)
                        changeTextValue('name', {
                            key: 'name',
                            label: localisationMaster('text_18', 'study/study-test'),
                            value: text
                        })
                    }}
                    onClear={() => {
                        const {name, ...obj} = filter
                        updateFilter(obj)
                        setTextValues(filterTextValues.filter(field => field.key !== 'name'))
                    }}
                    value={filter?.name || ''}
                    translate={translate}
                    placeholder={`${translate.search_placeholder}...`}/>

                {!!filterTextValues.length && <FilterTextValues onDelete={key => {
                    const {[key]: _, ...obj} = filter
                    updateFilter(obj)
                }}/>}

                {isOpenFilter && <AsideFilter
                    translate={translate}
                    getFilterParams={e => {
                        updateFilter(e)
                        setPagination({...pagination, page: 1})
                    }}
                    config={filterConfig}
                    filterValue={filter}
                    onDropFilter={() => {
                        updateFilter({filter_type: filter?.filter_type})
                        closeFilter()
                    }}
                />}

                <TabMenu tabs={tabs} tabID={filter?.filter_type} onChange={tab => {
                    updateFilter({...filter, filter_type: tab.id})
                    setPagination(initPagination)
                }}/>

                <div className={st.content}>
                    {Array.isArray(data?.result) ? (
                        data.result.length > 0 ? (
                            <div className={st.list}>
                                 {data.result.map(item => (
                                    <StudyTest
                                        key={item.id}
                                        item={item}
                                        updateRow={newRow => {
                                            setData({
                                                ...data,
                                                result: data.result.map(row =>
                                                    row.id === newRow.id ? newRow : row
                                                ),
                                            });
                                        }}
                                        updateData={setData}
                                    />
                                ))}
                            </div>
                        ) : (
                            Object.keys(filter).length > 1 ? (
                                <EmptyList
                                    title="No results :("
                                    text1="Try changing the parameters."
                                />
                            ) : (
                                <EmptyList
                                    title="No tests :("
                                    text1="No one has added any tests yet, will you be the first?"
                                    button={{
                                        text: 'Add New Test',
                                        onClick: createTest,
                                    }}
                                />
                            )
                        )
                    ) : (
                        <Loader/>
                    )}
                </div>

                {data?._meta.count_items > 10 &&
                    <Pagination
                        limit={pagination.limit}
                        count={data._meta.count_items || 0}
                        page={pagination.page}
                        onChange={page => setPagination({...pagination, page})}
                        onChangePerPage={limit => {
                            setPagination({page: 1, limit})
                        }}/>}
            </div>
        </div>
    )
}

export default observer(StudyTests);
