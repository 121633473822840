import React from 'react';
import PropTypes from 'prop-types';
import st from "./NewVariantDel.module.scss";
import {ReactComponent as Delete } from "components/UI/icons/close-line.svg";
const NewVariantDel = ({onClick, isDisabled}) => {
    return (
        <div className={st.del} onClick={onClick} disabled={isDisabled}><Delete className={st.svg}/></div>
    );
};

NewVariantDel.propTypes = {
    onClick: PropTypes.func.isRequired
}

NewVariantDel.defaultProps = {
    onClick: () => {}
}

export default NewVariantDel
