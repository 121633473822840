import React, {useEffect, useRef, useState} from 'react';
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores";
import StudyStore from "Stores/studyStore";
import st from "./QuestionCard.module.scss"
import swal from "sweetalert";
import VariantUnit from "./VariantUnit";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
import {ReactComponent as StarIcon} from "components/UI/icons/star-line.svg";
import QuestionAudios from "components/Modules/Ebook/Common/Exercise/types/QuestionAudios/QuestionAudios";
import VideoPlayer from "components/ContentBox/Global/MediaPlayer/VideoPlayer";
import {ReactComponent as LineInformation} from "components/UI/icons/information-line.svg";
import {ReactComponent as CheckMark} from "components/UI/icons/system-linecheck-line.svg";

export default function QuestionCard(props) {
    const question = props.question

    const {studyTest, setTestQuestions, editQuestion, questionTypesData} = StudyStore

    const menu = [
        {name: localisationMaster('text_11', 'lessons/course'), action: 'edit'},
        {name: localisationMaster('text_13', 'lessons/course'), action: 'delete'},
    ]

    const refSlide = useRef(null);
    const refBody = useRef(null);
    const [open, setOpen] = useState(props.open)

    const replacer = data => {
        if (!data) return data
        const regex = /\[([^[\]]*)\]/g;
        data = data.replace(regex, '<strong>[</strong><span style="color:var(--color-success-1); font-weight: bold">$1</span><strong>]</strong>');
        data = data.replaceAll('|', ' ');
        return data
    }

    const replacerForMultipleAnswers = data => {
        if (!data) return data;

        const regex = /(\[.*?\])/g;
        data = data.replace(regex, (match, p1) => {
            const options = p1.slice(1, -1).split('|');
            const correctOption = options.find(option => option.trim().endsWith('*'));
            if (correctOption) {
                const coloredOption = `<span style="color: var(--color-success-1); font-weight: bold">${correctOption.replace('*', '')}</span>`;
                return `[${coloredOption}]`;
            }
            return match;

        });

        return data;
    };

    const answersList = q => {
        let answers = [...q.answers];
        let answerList = [];
        let tmp;

        if (q.type === 'text_missing_words' || q.type === 'words_in_blanks' || q.type === 'drag_words_in_blanks') {
            const re = /\[(.*?)]/g;
            const tags = q.answers[0].value?.match(re) || []
            tags.forEach(tag => {
                if (tag.includes('|') && tag.includes('*')) {
                    tmp = tag.split('|').find(str => str.includes('*'))
                } else {
                    tmp = tag
                    tmp = tmp.replaceAll('[', '').replaceAll(']', '').replaceAll('*', '')
                    answerList.push({
                        value: tmp,
                        is_correct: true
                    })
                }
            })
            answers = answerList;
        }

        return (
            <div className={st.variants}>{answers.map((a, i) =>
                <VariantUnit key={i} type={q.type} answer={a}/>)}
            </div>
        )
    }

    const qBody = q => {

        const renderQuestion =
            <div className={st.question}>
                {q.audio_file && <audio src={Stores.baseLink() + q.audio_file} controls/>}
                <div dangerouslySetInnerHTML={{__html: q.text_question}}/>
                {(q.type.includes('question_plus_text') || q.type === 'text_missing_words' || q.type === 'complete_sentence' || q.type === 'words_in_blanks' || q.type === 'drag_words_in_blanks') &&
                    <>
                        <br/>
                        <div dangerouslySetInnerHTML={{__html: replacer(q.answers[0]?.value)}} className={st.answer}/>
                    </>}
            </div>

        const renderVariants = answersList(q);

        const logo = q.files.find(x => x.is_question)

        const renderMediaBox = () => (
            logo && (
                <div className={st.mediaBox}>
                    <img src={Stores.baseLink() + logo.file} alt="picture"/>
                </div>
            )
        )
        switch (q.type) {
            case 'audio_plus_variants':
            case 'video_plus_variants':
            case 'variants':
                const isAudioPlusVariants = q.type === 'audio_plus_variants';
                const isVideoPlusVariants = q.type === 'video_plus_variants';

                const mappedCorrectAnswers = question.answers
                    .filter(answer => answer.is_correct)
                    .map((answer, index) => (
                        <div key={`${answer.value}${index}`} className={st.answersBlock}>
                            <div className={st.answer}>
                                <CheckMark/>
                                {answer.value}
                            </div>
                        </div>
                    ))
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={`${st.questionCol} ${st.removeMarginTop}`}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                        </div>
                        <div className={st.description}>
                            {question.text_question}
                        </div>
                        {isVideoPlusVariants && (
                            <div className={st.videoContainer}>
                                {q.files && <VideoPlayer
                                    v_link={Stores.baseLink() + q.files.filter(el => !el.is_question)[0]?.file}/>}
                            </div>
                        )}
                        {isAudioPlusVariants && (
                            <div className={`${st.questionCol} ${st.removeMarginTop}`}>
                                {q.files &&
                                    <QuestionAudios items={[{file: q.files.filter(el => !el.is_question)[0]?.file}]}/>}
                            </div>
                        )}
                        <div className={st.mappedCorrectAnswers}>
                            {mappedCorrectAnswers}
                        </div>
                    </div>
                )
            case 'make_word_from_letters':
                const answer = question.answers[0].value
                const letters = answer.split('')

                const separatedWord = letters?.map((letter, index) => (
                    <div className={st.separatedWordBlock} key={`${letter}${index}`}>
                        <span>{letter}</span>
                        {index !== letters.length - 1 && <span className={st.verticalSeparator}></span>}
                    </div>
                ));

                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                            <div className={st.answerTitle}>Make a Letter Word: <span>{answer}</span></div>
                            <div className={st.separatedWord}>
                                {separatedWord}
                            </div>
                        </div>
                    </div>
                )
            case 'complete_sentence':
                const completeSentenceAnswer = question.answers[0].value.replaceAll("&nbsp;", "")
                const words = completeSentenceAnswer.split("|")

                const separatedWords = words?.map((word, index) => (
                    <div className={st.separatedWordBlock} key={`${word}${index}`}>
                        <span>{word}</span>
                        {index !== words.length - 1 && <span className={st.verticalSeparator}></span>}
                    </div>
                ));
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                            <div className={st.answerTitle}>Put Together a Sentence From Words or
                                Phrases: <span>{words.join(" ")}</span></div>
                            <div className={st.separatedWord}>
                                {separatedWords}
                            </div>
                        </div>
                    </div>
                )
            case 'essay':
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                            <div className={st.essayInfo}>
                                <div className={st.icon}>
                                    <LineInformation/>
                                </div>
                                <div className={st.infoBlock}>
                                    <div className={st.minimumWords}>
                                        Minimum Words:
                                        <span>150</span>
                                    </div>
                                    <div>When answering, there will be an empty text field in which the client can enter
                                        the text or a
                                        form to download the essay in the format: JPG, PNG, PDF.
                                    </div>
                                    <div className={st.strong}>Please note that the essay will be automatically checked
                                        by AI.
                                    </div>
                                    <div>In case there is an essay that does not fit the standards of verification, then
                                        it will not be
                                        counted as correct. But the teacher can always change the decision made by the
                                        system.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 'sort_sentences':
                const mappedSentences = question.answers.map((sentence, index) => {
                    const number = index + 1;
                    return (
                        <div key={`${sentence.id}${index}`} className={st.sentenceBlock}>
                            <div className={st.count}>{number}</div>
                            <div className={st.sentence}>{sentence.value}</div>
                        </div>
                    )
                })
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                        </div>
                        <div className={st.mappedSentences}>
                            {mappedSentences}
                        </div>
                    </div>
                )
            case 'question_image_variants':
                const mappedImages = question.answers
                    .map((image, index) => {
                        const removeDescription = image.value.split('|');
                        const imgPath = removeDescription[0];
                        const blockClass = image.is_correct ? st.imageBlock : st.imageBlockGrey;
                        return (
                            <div key={`${image.id}${index}`} className={blockClass}>
                                <img src={Stores.baseLink() + imgPath} alt="picture"/>
                            </div>
                        )
                    });
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                        </div>
                        <div className={st.mappedImages}>
                            {mappedImages}
                        </div>
                    </div>
                )
            case 'match_words_images':
                const mappedMatchWordsImages = question.answers
                    .map((image, index) => {
                        const splitPathAndWord = image.value.split('|');
                        const imgPath = splitPathAndWord[0];
                        const imgWord = splitPathAndWord[1];

                        return (
                            <div key={`${image.id}${index}`} className={st.imageContainer}>
                                <div className={st.divForImage}>
                                    <img className={st.picture} src={Stores.baseLink() + imgPath} alt="picture"/>
                                </div>
                                <div className={st.imgWord}>{imgWord}</div>
                            </div>
                        )
                    });
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                        </div>
                        <div className={st.mappedMatchWordsImages}>
                            {mappedMatchWordsImages}
                        </div>
                    </div>
                )
            case 'image_matching':
                const mappedImageMatchingAnswers = question.answers
                    .map((value, index) => {
                        const splitPathAndWord = value.value.split('|');
                        const matchPhotoOne = splitPathAndWord[0];
                        const matchPhotoTwo = splitPathAndWord[1];

                        return (
                            <div key={`${value.id}${index}`} className={st.imageMatchingContainer}>
                                <img src={Stores.baseLink() + matchPhotoOne} alt="picture"/>
                                <div className={st.separator}></div>
                                <img src={Stores.baseLink() + matchPhotoTwo} alt="picture"/>
                            </div>
                        )
                    });
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                        </div>
                        <div className={st.mappedImageMatchingAnswers}>
                            {mappedImageMatchingAnswers}
                        </div>
                    </div>
                )
            case 'word_matching':
                const mappedWordMatchingAnswers = question.answers.map((value, index) => {
                    const splitPathAndWord = value.value.split('|')
                    const matchValueOne = splitPathAndWord[0]
                    const matchValueTwo = splitPathAndWord[1]

                    return (
                        <div key={`${value.id}${index}`} className={st.valueMatchingContainer}>
                            <div className={st.valueBlock}>{matchValueOne}</div>
                            <div className={st.valueSeparator}/>
                            <div className={st.valueBlock}>{matchValueTwo}</div>
                        </div>
                    )
                })

                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                        </div>
                        <div className={st.mappedValueMatchingAnswers}>
                            {mappedWordMatchingAnswers}
                        </div>
                    </div>
                )
            case 'drag_words_in_blanks':
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                            <div dangerouslySetInnerHTML={{__html: replacer(q.answers[0]?.value)}}
                                 className={st.answer}/>
                        </div>
                    </div>
                )
            case 'text_missing_words':
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                            <div dangerouslySetInnerHTML={{__html: replacerForMultipleAnswers(q.answers[0]?.value)}}
                                 className={st.missingWordsAnswer}/>
                        </div>
                    </div>
                )
            case 'words_in_blanks':
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {q.audio_file && <QuestionAudios items={[{file: q.audio_file}]}/>}
                            <div dangerouslySetInnerHTML={{__html: replacer(q.answers[0]?.value)}}
                                 className={st.answer}/>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className={st.questionBody}>
                        {renderMediaBox()}
                        <div className={st.questionCol}>
                            {renderQuestion}
                            {renderVariants}
                        </div>
                    </div>
                )
        }
    }

    const actions = type => {
        switch (type) {
            case 'edit':
                editQuestion(question)
                break;
            case 'delete':
                swal({
                    title: localisationMaster("text_2", "alert"),
                    text: `Удалить вопрос: "${question.text_question}"?`,
                    icon: "warning",
                    buttons: [
                        localisationMaster("button_2", "alert"),
                        localisationMaster("button_1", "alert"),
                    ],
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        const apiConf = {
                            method: "delete",
                            link: 'study/question_test/' + question.id,
                            params: {
                                study_test: studyTest.id,
                            }
                        };
                        superAxiosRequest(apiConf).then(res => {
                            setTestQuestions(res.data)
                        });
                        swal(
                            `${localisationMaster("text_3", "alert")} ${question.text_question} ${localisationMaster("text_4", "alert")}`,
                            {icon: "success",}
                        );
                    } else {
                        swal(localisationMaster("text_5", "alert"));
                    }
                });
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        const handleResize = () => {
            refSlide.current.style.height = open ? refBody.current.offsetHeight + "px" : "";
        };

        // Вызываем функцию handleResize сразу после монтирования компонента
        handleResize();

        // Добавляем слушатель события resize для отслеживания изменения ширины экрана
        window.addEventListener('resize', handleResize);

        // Функция очистки эффекта, которая удаляет слушатель события при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [open, window.innerWidth]); // Добавляем window.innerWidth в массив зависимостей

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className={`${st.container} ${open ? `${st.open}` : `${st.closed}`}`}>
            <div className={st.header}>
                <div className={st.clickZone} onClick={() => setOpen(!open)}>
                    <div className={st.testInfo}>
                        <div className={st.pos}>{props.pos}</div>
                        <div className={st.delimiter}></div>
                        <div>{questionTypesData.find(x => x.value === question.type)?.name}</div>
                        {/*<div className={st.headerTitle} dangerouslySetInnerHTML={{__html: question.text_question}}/>*/}
                        <div className={st.delimiter}></div>
                        <div>{question.test_section?.name || 'No category'}</div>
                    </div>
                </div>
                <div className={st.actionMenu}>
                    <div className={st.params}>
                        <div className={st.scoreBlock}>
                            <StarIcon/>
                            <p>{question.total_points}</p>
                        </div>
                    </div>
                    <ActionMenu menu={menu} action={actions}/>
                </div>
            </div>
            <div className={st.questionName} dangerouslySetInnerHTML={{__html: question.text_question}}/>
            <div ref={refSlide} className={st.slide}>
                <div className={st.body} ref={refBody}>
                    {qBody(question)}
                </div>
            </div>
        </div>
    );
};
