import React from 'react';
import styles from "./WordMatching.module.scss";

export const WordMatching = ({question, answer}) => {
    return <>
        <div className={`${styles.answerBody} ${styles.studentColor}`}>
            <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
            {
                answer.answers?.map((el, index)=>
                    <div key={index} className={styles.container}>
                        <div
                            className={`${styles.words} ${answer.correct_answers[index] ? styles.rightAnswer : styles.wrongAnswer}`}>
                            {el.split('|')[0]}
                        </div>
                        <div className={`${styles.line} ${answer.correct_answers[index] ? styles.rightAnswer : styles.wrongAnswer}`}></div>
                        <div className={`${styles.words} ${answer.correct_answers[index] ? styles.rightAnswer : styles.wrongAnswer}`}>{el.split('|')[1]}</div>
                    </div>
                )
            }
        </div>
        <div className={`${styles.answerBody} ${styles.correctColor}`}>
            <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>
            {
                question.answers?.map((el, index)=>
                    <div key={index} className={styles.container}>
                        <div
                            className={`${styles.words} ${styles.rightAnswer}`}>
                                {el.value.split('|')[0]}
                        </div>
                        <div className={`${styles.line} ${styles.rightAnswer}`}></div>
                        <div className={`${styles.words} ${styles.rightAnswer}`}>
                                {el.value.split('|')[1]}
                        </div>
                    </div>
                )
            }
        </div>
    </>
};