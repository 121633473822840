import React, {useState} from 'react';
import styles from "./Essay.module.scss";
import {ReactComponent as Card} from "components/UI/icons/article-line.svg";
import {ReactComponent as Atom} from "components/UI/icons/reactjs-line.svg";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";

export const Essay = ({question, answer, item}) => {

    const [aiResponse, setAiResponse] = useState('')
    const [isWaiting, setIsWaiting] = useState(false);
    const [isButtonClick, setIsButtonClick] = useState(false);

    const onAiResponseClick = () => {
        setIsButtonClick(true)
        setIsWaiting(true);
        superAxiosRequest({link: 'study/study_test_ai_response', method: 'post'},
            {condition_exercise: question.text_question, text_exercise: answer.answers[0], study_test: item.study_test})
            .then(({data}) => {
                setAiResponse(data);
            })
            .finally(() => setIsWaiting(false))

    }

    function countWords(text) {
        const words = text.split(' ').filter(word => word.trim() !== '');
        return words.length;
    }

    return (
        <>
            <div className={`${styles.countWords} ${styles.dashedBorder}`}>
                <Card/>
                <span className={`${styles.totalWords} ${styles.span}`}>Total Words: <span className={styles.font}>{countWords(answer.answers?.[0])}</span></span>
            </div>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                <span className={styles.span}>{answer.answers?.[0]}</span>
            </div>
            <div className={`${styles.answerBody} ${isButtonClick ? styles.correctColor : ""}`}>
                <button
                    onClick={onAiResponseClick}
                    className={`${styles.answerTitle} ${styles.ai} `}
                    disabled={isWaiting}
                    style={{cursor: isWaiting ? 'not-allowed' : 'pointer'}}>
                    <Atom/>
                    AI Response
                </button>
                {
                    isWaiting ? <Loader/> :
                        <>
                            {
                                aiResponse && <div className={styles.writtenCorrect}>It's written correctly</div>
                            }
                            <span className={styles.span}>{aiResponse}</span>
                        </>
                }
            </div>
        </>
    );
};

