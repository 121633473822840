import {action, computed, makeObservable, makeObservable as decorate, observable, toJS} from "mobx";
import Store from "Stores"
import {superAxiosRequest} from "axiosApi";

class StudyTestResultStore {
    resultData = null
    isClient = Store.isClient

    constructor() {
        makeObservable(this, {
            resultData: observable,

            fetchTestResult: action,
            setAnswerData: action,

            result: computed,
            sortedAnswers: computed,
        })
    }

    fetchTestResult = id => {
        superAxiosRequest({
            link: `${this.isClient ? 'study/student_test_result_by_init_test' : 'study/teacher_test_result_by_init_test'}/${id}`,
            method: "get",
        }).then(({ data }) => {
            this.resultData = data
        }).catch(() => {
            this.resultData = {}
        })
    }

    fetchTestResultAsync = async id => {
        await superAxiosRequest({
            link: `${this.isClient ? 'study/student_test_result_by_init_test' : 'study/teacher_test_result_by_init_test'}/${id}`,
            method: "get",
        })
    }

    setAnswerData = (data, id) => {
        this.resultData = {
            ...this.resultData,
            answers: this.resultData.answers.map(a => a.id === id ? {...a, ...data} : a)
        }
    }

    get result() {
        return toJS(this.resultData)
    }

    get sortedAnswers() {
        // Сортируем полные ответы в соответствии с краткими
        const {answers, is_correct_answers: sorting} = this.resultData
        return sorting.map(x => answers.find(ans => ans.question.id === x.id)).slice(0, answers.length)
    }
}

// decorate(new StudyTestResultStore(), {
//     resultData: observable,
//
//     fetchTestResult: action,
//     setAnswerData: action,
//
//     result: computed,
//     sortedAnswers: computed,
// })

export default new StudyTestResultStore()
