import React from 'react';
import style from "./TestResult.module.scss";
import {dateFormat} from "helpers/date";
import Stores from "Stores";
import {NavLink} from "react-router-dom";
import {EditIconButton} from "components/UI/SuperButton/SuperButton";
import {ReactComponent as Delete} from "components/UI/icons/delete-bin-6-line.svg";

const TestResult = ({item, actions, listing, translate}) => {
    const correctAnswers = item?.stats?.correct_answers || 0;
    const totalPoints = item?.stats?.total_points || 0;
    const studentPoints = item?.stats?.points || 0;

    const percentage = totalPoints === 0 ? 0 : Math.round((studentPoints / totalPoints) * 100);

    return (
        <div className={style.testResult} style={listing && {border: 'none'}}>
          <div className={style.box}>
              <div className={style.info}>
              {item.student && item.student.last_name && item.student.first_name && (
                    <NavLink target="_blank" to={'/account_clients/' + item.student.user_id}>
                        {Stores.transliterate (`${item.student.last_name} ${item.student.first_name}`)}
                    </NavLink>
                )}
                  <div className={style.date}>{dateFormat(new Date(item.date), 'd MMM YYYY')} {translate.yr}</div>
              </div>

              <div className={style.medium}>
                  <span>
                      {item.category}
                  </span>

                  <span>
                      {item.test}
                  </span>

                  <div className={style.score}>
                      <span>
                          <b>{translate.total}:</b>{item.test_scores}%
                      </span>
                  </div>

                  <div className={style.description} dangerouslySetInnerHTML={{__html: item.description}}/>
              </div>
          </div>
            {
                !Stores.isClient && <div className={style.buttons}>
                    <div className={style.buttonsControl}>
                        <EditIconButton onClick={(e) => actions('edit', e, item)}/>
                        <div className={style.deleteBasket} onClick={(e) => actions('delete', e, item)}>
                            <Delete/>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default TestResult;
