import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../helpers';
import styles from './Input.module.scss';
import { validateEmail } from 'components/Modules/Slots/utils';

function Input({
  tag,
  type,
  label,
  name,
  placeholder,
  value,
  withLabel,
  isError,
  isRequired,
  onChange,
  onSubmit,
  classes,
  ...args
}) {
  const Tag = tag;
  const [error, setError] = useState(isError);

  const handleKeydown = (evt) => {
    if (evt.key === 'Enter' && onSubmit !== null) {
      evt.preventDefault();
      onSubmit(evt);
    }
  };

  const handleChange = (evt) => {
    onChange(evt);
  };

  const handleBlur = () => {
    if (isRequired && !value) {
      setError(true);
    }
    if (type === 'email' && !validateEmail(value)) {
      setError(true);
    }
  };

  useEffect(() => {
    if ((type !== 'email' && value || type === 'email' && validateEmail(value)) && error) {
      setError(false);
    }
  }, [value, error]);

  useEffect(() => {
    setError(isError);
  }, [isError])

  return (
    <Tag className={cn({
      [styles.wrapper]: true,
      [classes]: classes,
    })}>
      <label aria-label={label}>
        { withLabel &&
          <span
            className={cn({
              [styles.label]: true,
              [styles.required]: isRequired
            })}>
            {`${label}:`}
          </span>
        }
        <input
          className={cn({
            [styles.input]: true,
            [styles.error]: error,
						[classes]: !!classes,
          })}
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={handleKeydown}
          onBlur={handleBlur}
          {...args}
        />
      </label>
      {error && <span className={styles.error_text}>
        {!value && isRequired ? 'Обязательное поле' : 'Некорректное значение'}
      </span>
      }
    </Tag>
  );
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tag: PropTypes.oneOf(['div', 'li']),
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'tel', 'time']),
  withLabel: PropTypes.bool,
  isError: PropTypes.bool,
  isRequired: PropTypes.bool,
  onSubmit: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([null]),
  ]),
  classes: PropTypes.string,
};

Input.defaultProps = {
  value: '',
  tag: 'div',
  type: 'text',
  name: 'input-name',
  isError: false,
  isRequired: false,
  withLabel: true,
  onSubmit: null,
  classes: '',
};

export default Input;
