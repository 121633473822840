import React, {useState, useEffect} from 'react'
import {superAxiosRequest} from 'axiosApi'
import {observer} from 'mobx-react'
import SelectCustom from 'components/ContentBox/Global/Select/SelectCustom'
import {localisationMaster} from 'Localisation/Localisation';
import styles from './AddTest.module.scss'
import ToggleCheckbox from "components/UI/ToggleCheckbox/ToggleCheckbox";
import './studytest.scss';
import asideModalStore from "Stores/asideModalStore";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import FormField from "components/UI/AsideModal/Form/FormField";

const AddTest = () => {
    const {modalValues: modalValue, changeValue: changeValues, check} = asideModalStore
    const [levels, setLevels] = useState([false, []])

    const [rgList, setRgList] = useState([
        {id: 1, value: false, name: 'request', label: localisationMaster('text_29', 'study/study-test'), checked: true},
        {id: 2, value: true, name: 'request', label: localisationMaster('text_30', 'study/study-test'), checked: false}

    ]);

    useEffect(() => {
        let apiConf = {
            method: 'get',
            link: 'study/levels'
        }
        superAxiosRequest(apiConf).then(res => {
            setLevels([true, res.data.result])
        })
    }, [])

    useEffect(() => {
        if (rgList.length > 0 && modalValue?.under_request === undefined) {
            changeValues(rgList[0].value, 'under_request');
        }
    }, [rgList, modalValue, changeValues]);

    return (
        <div className={styles.groupForm}>
            <div className={styles.fieldSet}>
                <FormField>
                    <FormLabel isRequired>{localisationMaster('text_21', 'modal_templates')}:</FormLabel>
                    <SelectCustom
                        placeholder={localisationMaster('text_8', 'study/study-test')}
                        apiLink="db/companies"
                        sortKey="name"
                        error={check('company')}
                        valueID={modalValue?.company}
                        onChange={o => changeValues(+o.id, 'company')}
                        isSearchable
                    />
                </FormField>
                <FormField>
                    <FormLabel isRequired>{localisationMaster('table-row-title-1', 'company/age')}:</FormLabel>
                    <FormInput isError={check("name")}>
                        <input name="name"
                               onChange={(e) => changeValues(e.target.value, 'name')}
                               placeholder={"Name"}
                               value={modalValue?.name || ""}
                        />
                    </FormInput>
                </FormField>
                <FormField>
                    <FormLabel isRequired>{localisationMaster('name_4', 'tests/widget-clients')}:</FormLabel>
                    <SelectCustom
                        error={check('level')}
                        placeholder={localisationMaster('name_4', 'tests/widget-clients')}
                        options={levels[1] || []}
                        sortKey="name"
                        valueID={modalValue?.level}
                        onChange={o => changeValues(o.id, 'level')}
                        isSearchable
                    />
                </FormField>
                <FormField>
                    <FormLabel isRequired>{localisationMaster('table-row-title-5', 'tests/tests')}:</FormLabel>
                    <SelectCustom
                        placeholder={localisationMaster('table-row-title-5', 'tests/tests')}
                        error={check('sub_lvl')}
                        options={levels[1].find(x => x.id === modalValue.level)?.sub_lvl || []}
                        sortKey="name"
                        isDisabled={!modalValue?.level}
                        valueID={modalValue?.sub_lvl}
                        onChange={o => changeValues(o.id, 'sub_lvl')}
                        isSearchable
                    />
                </FormField>
            </div>
            <FormField>
                <FormLabel>{localisationMaster('table-row-title-2', 'company/companies')}:</FormLabel>
                <textarea className={styles.placeholder} onChange={(e) => changeValues(e.target.value, 'description')} value={modalValue.description}
                          placeholder={'Comment'}/>
            </FormField>
            <FormField>
                <FormLabel>Access to test:</FormLabel>
                {rgList.map(item => (
                    <div className={styles.formField} key={item.id}>
                        <div className={styles.toggleCheckboxWrapper}>
                        <ToggleCheckbox
                            label={item.label}
                            checked={item.value === modalValue?.under_request}
                            onChange={() => changeValues(item.value, 'under_request')}
                        />
                    </div>
                    </div>
                ))}
            </FormField>
        </div>
    )
}
export default observer(AddTest);
