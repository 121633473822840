import React from 'react';
import styles from "./Variants.module.scss";

export const Variants = ({question, answer: currentAnswer}) => {

    return (
        <>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                {currentAnswer.answers.map((el, index) => (
                    <div key={index} className={`${styles.variant} ${currentAnswer.correct_answers[0] ? styles.checkedRight : styles.checkedWrong }`}>
                        <div className={currentAnswer.correct_answers[0] ? styles.checkedRightCheckbox : styles.checkedWrongCheckbox}/>
                        {el}
                    </div>
                ))}
            </div>
            <div className={`${styles.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>
                {question.answers?.map((answer, index) => (
                    <div key={index} className={`${styles.variant} ${answer.is_correct ? styles.checkedRight : styles.unchecked}`}>
                        <div className={answer.is_correct ? styles.checkedRightCheckbox : styles.uncheckedCheckbox}/>
                        {answer.value}
                    </div>
                ))}
            </div>
        </>
    );
};

