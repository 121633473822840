import React from 'react';
import PropTypes from 'prop-types';
import Stores from "Stores";
import useFileUpload from "hooks/useFileUpload";
import st from './ImageSelector.module.scss'
import UploadIcon from "components/ContentBox/img/upload-cloud-icon.svg";
import VariantDelete from "components/ContentBox/Global/GlobalModalSingle/templates/Ebooks/ModalExercise/VariantDelete/VariantDelete";

const ImageSelector = props => {
    const {refInput, progress, file, setFile} = useFileUpload({onUploadEnd: props.onChange})

    const deleteHandler = () => {
        setFile(null)
        props.onChange(null)
    }

    return (
        <div className={props.error && !props.value ? st.boxError : st.box} onClick={() => { refInput.current?.click()}}>
            {(file || props.value) ?
                <div className={st.variant}>
                    <div className={st.wrapImg}>
                        <img src={file ? URL.createObjectURL(file) : `${Stores.baseLink()}${props.value}`}
                             alt="Загруженное изображение"/>
                    </div>
                    <div className={st.remove}>
                        <VariantDelete onClick={deleteHandler}/>
                    </div>
                </div>
                :
                <div className={st.choose}>
                    <input type="file" ref={refInput} onChange={e => {
                        setFile(e.target.files[0])
                    }} accept="image/*"/>
                    <img className={st.uploadIcon} src={UploadIcon} alt="Загрузка изображения" />
                    <div className={st.tip}>Поддерживаемые форматы:&nbsp;<span> JPG, GIF, PNG.</span></div>

                    {progress > 0 && progress < 100 &&
                    <div className={st.progress}>
                        <progress max="100" value={progress}/>
                        <div>{progress} %</div>
                    </div>}
                </div>
            }
        </div>
    );
};

ImageSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default ImageSelector
