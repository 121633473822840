import React from 'react';
import {classNames as cn} from "helpers";
import './NewCheckbox.scss';
import {ReactComponent as CheckMark} from "components/UI/icons/checkmark.svg";

export default function NewCheckbox(props) {
    return (
        <div>
            <div className='invisible'>
                <CheckMark/>
            </div>
            <div className={`form-container ${props.extraClass || ''}`}>
                <div
                    className={cn({
                        "promoted-checkbox": true,
                        "promoted-checkbox--error": props.error
                    })}
                >
                    <input id={props.id ? props.id : "tmp"}
                           type="checkbox"
                           className="promoted-input-checkbox"
                           checked={props.checked}
                           disabled={props.isDisabled}
                           onChange={(e) => props.isChecked(e, props.key_name)}
                    />
                    <label htmlFor={props.id ? props.id : "tmp"}>
                        <svg>
                            <use xlinkHref="#checkmark"/>
                        </svg>
                        {props.label}
                    </label>
                </div>
            </div>
        </div>
    )
}
