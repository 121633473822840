import React from 'react'
import styles from './CustomAlert.module.scss'

const CustomAlert = ({ icon: Icon, title, text }) => {
	return (
		<div className={styles.alert}>
			<div>{Icon && <Icon />}</div>
			<div className={styles.textContainer}>
				<span className={styles.title}>{title}</span>
				<span className={styles.text}>{text}</span>
			</div>
		</div>
	)
}

export default CustomAlert
