import React from 'react';
import styles from "./WordsInBlanks.module.scss";
import {clearHtmlTagsAndSymbols} from "../utils";

export const WordsInBlanks = ({question, answer}) => {

    const getPartSentencesAndStyle = (sentence, func) => {
        return sentence.replace(/<[^>]*>/g, '\n').split(/(\[|\])/).reduce((acc, curr, index, array) => {
            if (acc.at(-1)?.name === '[') {
                return [...acc, {name: curr, style: func(index, array)}];
            }
            return [...acc, {name: curr, style: styles.usualText}];
        }, []);
    }

    const questionAnswer = getPartSentencesAndStyle(clearHtmlTagsAndSymbols(question.answers?.[0].value), () => styles.rightAnswer)

    const partsArray = getPartSentencesAndStyle(
        clearHtmlTagsAndSymbols(answer.answers?.[0]),
        (index, array) => answer.correct_answers[(index - array.indexOf('[') - 1) / 4]
            ? styles.rightAnswer
            : styles.wrongAnswer
    )

    return (
        <>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                <span className={styles.span}>
                    {
                        partsArray.map(el => <span className={el.style}>{el.name}</span>)
                    }
                </span>
            </div>
            <div className={`${styles.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>
                <span className={styles.span}>
                      {
                          questionAnswer?.map(el => <span className={el.style}>{el.name}</span>)
                      }
                </span>
            </div>
        </>
    )
}