import React from 'react';
import PropTypes from 'prop-types';
import Stores from "Stores";
import useFileUpload from "hooks/useFileUpload";
import st from "./ImageVariant.module.scss";
import UploadIcon from 'components/ContentBox/img/upload-cloud-icon.svg'
import FormInput from "components/UI/AsideModal/Form/FormInput";
import FormField from "components/UI/AsideModal/Form/FormField";

const ImageVariant = props => {
    const [src, text] = props.value.split('|')

    const {refInput, progress, setFile} = useFileUpload({
        onUploadEnd: file => props.onChange(`${file}|${text}`)
    })

    const deleteHandler = () => {
        setFile(null)
        props.onRemove()
    }

    return (
        <>
            <div className={`${props.error && !src ? st.boxError : st.box} ${st.crsPointer}`} onClick={() => {
                refInput.current && refInput.current.click()
            }}>
                {src ?
                        <div className={st.variant}>
                            <div className={st.wrapImg}>
                                {/*<img src={file ? URL.createObjectURL(file) : `${Stores.baseLink()}${src}`} alt=""/>*/}
                                <img src={`${Stores.baseLink()}${src}`} alt="Иконка загрузки"/>
                            </div>
                            {/*<div className={st.remove}>*/}
                            {/*    <VariantDelete onClick={deleteHandler}/>*/}
                            {/*</div>*/}
                        </div>
                    :
                    <div className={st.choose}>
                    <input type="file" ref={refInput} onChange={e => {
                            setFile(e.target.files[0])
                        }} accept="image/*"/>
                        <img className={st.uploadIcon} src={UploadIcon} alt=""/>
                        <div className={st.tip}>Поддерживаемые форматы:&nbsp;<span> JPG, GIF, PNG.</span></div>
                        {progress > 0 && progress < 100 &&
                            <div className={st.progress}>
                                <progress max="100" value={progress}/>
                                <div>{progress} %</div>
                            </div>}
                    </div>
                }
            </div>
            {src &&
                <div style={{marginTop: '10px'}}>
                    <FormField>
                        <FormInput isError={props.error}>
                            <input type="text"
                                   name="variant"
                                   value={text || ''}
                                   placeholder='Image Description'
                                   onChange={e => props.onChange(`${src}|${e.target.value}`)}
                                   className={props.error && text.length < 2 ? 'super-modal__input--error' : undefined}
                            />
                        </FormInput>
                    </FormField>
                </div>
            }
        </>

    );
};

ImageVariant.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
}

export default ImageVariant;
