import React from 'react';
import PropTypes from "prop-types";
import Stores from "Stores";
import st from "./VariantUnit.module.scss";
import {ReactComponent as TrueAnswer} from "components/ContentBox/img/true.svg";
import {ReactComponent as WrongAnswer} from "components/ContentBox/img/false.svg";
import {ReactComponent as Exchange} from "components/ContentBox/img/exchange.svg";

const VariantUnit = props => {

  const {is_correct, value} = props.answer
  const [val1, val2] = value.split('|')

  const getSrc = src => Stores.baseLink() + src

  const renderValue = () => {
    switch (props.type) {
      case 'complete_sentence':
        return <div>{value.replaceAll('|', ' ')}</div>
      case 'word_matching':
        return (
          <div className={st.valueWords}>
            <span>{val1}</span>
            <span><Exchange/></span>
            <span>{val2}</span>
          </div>
        )
      case 'question_image_variants':
        return (
          <div className={st.valueImage}>
            <div><img src={getSrc(val1)} alt=""/></div>
            <div>{val2}</div>
          </div>
        )
      case 'image_matching':
        return (
          <div className={st.valueImages}>
            <span><img src={getSrc(val1)} alt=""/></span>
            <span><Exchange/></span>
            <span><img src={getSrc(val2)} alt=""/></span>
          </div>
        )
      case 'match_words_images':
        return (
          <div className={st.valueImages}>
            <span><img src={getSrc(val1)} alt=""/></span>
            <span><Exchange/></span>
            <span>{val2}</span>
          </div>
        )
      case 'question_plus_text':
         return <div>{props.answer?.value?.replace(/&nbsp;/g, '')?.trim()}</div>
      default:
        return <div>{value}</div>
    }
  }

  return (
    <div className={st.variant}>
      <div className={st.icon}>{is_correct ? <TrueAnswer/> : <WrongAnswer/>}</div>
      {renderValue()}
    </div>
  );
};

VariantUnit.propTypes = {
  type: PropTypes.string,
  answer: PropTypes.object.isRequired,
}

export default VariantUnit;
