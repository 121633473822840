import React from 'react';
import { ReactComponent as Watch } from "components/ContentBox/img/watch.svg";
import st from "./MainTableRowTime.module.scss";
import RunTime from "./RunTime/RunTime";
import {dateFormat} from "helpers/date";

export default function MainTableRowTime(props) {

    return (
        <div className={st.watch}>
            <Watch/>
            <div className={st.runTime}>
                <div className={st.stylesTime}>
                    <div className={st.clrTime}>Run Time:</div>
                    <div className={st.formTime}>
                        {   props.timeLeft.seconds_until_finish < 0
                            ? <span className={st.colorTime}>Время теста истекло</span>
                            : <RunTime className={st.timeLeft} delta={props.timeLeft.seconds_until_finish}/>
                        }
                    </div>
                </div>
                <div>
                    <span className={st.clrTime}>Date:</span>
                    <strong className={`${st.finishTime} ${st.clrTime}`}>{props.timeLeft.finish_at}</strong>
                    <span className={st.clrLine}> | </span>
                    <span className={`${st.colorDate} ${st.time}`}>{dateFormat(props.timeLeft.date_until, "d MMM YYYY")}</span>
                </div>
            </div>
        </div>
    );
}