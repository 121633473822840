import React, { memo } from 'react'
import styles from './ChatMessage.module.scss'
import classNames from 'classnames'
import { ReactComponent as RobotIcon } from 'components/UI/icons/robot.svg'
// import ProfileAvatar from 'components/CRM/UI/components/ProfileAvatar/ProfileAvatar'
import { formatDateChatAI } from '../helpers'

const ChatMessage = memo(({ message, alien, type, date }) => {
	// const dataStorage = localStorage.getItem('ud')
	// const profile = JSON.parse(dataStorage)

	return (
		<div className={styles.container}>
			{/* {!alien && <ProfileAvatar name={profile?.user_data?.profile.first_name} width='24px' height='24px' />} */}

			<div
				className={classNames(styles.messageContainer, {
					[styles.alienMessage]: alien,
					[styles.userMessage]: !alien,
					[styles.errorMessage]: type === 'error',
					[styles.warningMessage]: type === 'warning',
				})}
			>
				<span className={styles.message}>{message}</span>
				<span className={styles.date}>{formatDateChatAI(date, 'message')}</span>
			</div>

			{alien && <RobotIcon width={24} height={24} />}
		</div>
	)
})

export default ChatMessage
