import { useState } from 'react'
import aiChatStore from 'Stores/aiChatStore'
import { superAxiosRequest } from 'axiosApi'

export const useAIChat = (identifier, type) => {
	const { aiChatData, messages, nextUrl } = aiChatStore
	const [inputValue, setInputValue] = useState('')
	const [isAIChatOpen, setAIChatOpen] = useState(false)
	const [isAIChatInitialized, setAIChatInitialized] = useState(false)
	const [isMessagesLoaded, setMessagesLoaded] = useState(false)
	const [isLoadingMoreMessages, setLoadingMoreMessages] = useState(false)
	const [isAwaitingResponse, setAwaitingResponse] = useState(false)

	const dataStorage = localStorage.getItem('ud')
	const profile = JSON.parse(dataStorage)
	const userId = profile.user_data.id

	const toggleChat = async () => {
		setAIChatOpen(prev => !prev)

		if (!isAIChatOpen && !isAIChatInitialized) {
			try {
				let endpoint
				switch (type) {
					case 'lesson':
						endpoint = `ebooks/assistants/from_initlesson/${identifier}`
						break
					case 'homework':
						endpoint = `ebooks/assistants/from_homework/${identifier}`
						break
					case 'book':
						endpoint = `ebooks/assistants/from_section/${identifier}`
						break
					default:
						throw new Error('Invalid chat type')
				}

				aiChatStore.clearMessages()

				const response = await superAxiosRequest(
					{
						method: 'post',
						link: endpoint,
					},
					null,
					'json'
				)

				aiChatStore.setAiChatData(response.data)
				setAIChatInitialized(true)

				if (!isMessagesLoaded) {
					await fetchChatMessages()
					setMessagesLoaded(true)
				}
			} catch (error) {
				console.error('Error fetching chat data:', error.response ? error.response.data : error)
			}
		} else if (isAIChatInitialized && aiChatData && !isMessagesLoaded) {
			await fetchChatMessages()
			setMessagesLoaded(true)
		}
	}

	const fetchChatMessages = async url => {
		url = url || `ebooks/dialogues`

		const assistantId = aiChatStore.aiChatData?.id
		const params = {
			assistant: assistantId,
		}

		try {
			const response = await superAxiosRequest(
				{
					method: 'get',
					link: url,
					params,
				},
				null,
				'json'
			)

			const messagesFromServer = response.data.result
			const fullUrl = response.data._meta.next
			const trimmedUrl = fullUrl ? fullUrl.replace(/.*?(ebooks\/dialogues\/\?[^#]*)/, '$1') + '&order_by=-created_at' : ''

			aiChatStore.setNextUrl(trimmedUrl)

			messagesFromServer.forEach(item => {
				const userMessage = {
					message: item.message,
					alien: false,
					date: item.created_at,
				}

				aiChatStore.fetchResponseMessage(item.response, item.thread, item.created_at)
				aiChatStore.fetchMessage(userMessage)
			})
		} catch (error) {
			console.error('Error fetching chat messages:', error.response ? error.response.data : error)
		}
	}

	const loadMoreMessages = async () => {
		if (nextUrl) {
			setLoadingMoreMessages(true)
			try {
				await fetchChatMessages(aiChatStore.nextUrl)
			} catch (error) {
				console.error('Error loading more messages:', error)
			} finally {
				setLoadingMoreMessages(false)
			}
		}
	}

	const handleInputChange = e => setInputValue(e.target.value)

	const handleSubmit = async () => {
		if (inputValue.trim() === '' || isAwaitingResponse) return

		const newMessage = {
			message: inputValue,
			alien: false,
			date: new Date().toISOString(),
		}

		aiChatStore.addNewMessage(newMessage)
		setInputValue('')

		const assistantId = aiChatData.id

		const body = {
			assistant: assistantId,
			user: userId,
			message: inputValue,
			thread: messages.length === 1 ? '' : aiChatStore.currentThread,
		}

		setAwaitingResponse(true)

		try {
			const response = await superAxiosRequest(
				{
					method: 'post',
					link: 'ebooks/dialogues',
				},
				body,
				'json'
			)

			aiChatStore.addNewResponseMessage(response.data.response, response.data.thread, new Date().toISOString())
		} catch (error) {
			console.error('Error sending message:', error.response ? error.response.data : error)
		} finally {
			setAwaitingResponse(false)
		}
	}

	return {
		inputValue,
		messages,
		handleInputChange,
		handleSubmit,
		toggleChat,
		isAIChatOpen,
		loadMoreMessages,
		isLoadingMoreMessages,
		isAwaitingResponse,
	}
}
