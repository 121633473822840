import React from 'react';
import styles from './QuestionPlusText.module.scss'

export const QuestionPlusText = ({question, answer}) => {

    return <>
        <div className={`${styles.answerBody} ${styles.pdn} ${styles.studentColor} ${styles.gapCntr}`}>
            <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
            <span className={`${answer.correct_answers[0] ? styles.rightAnswer : styles.wrongAnswer} ${styles.span} ${styles.answText}`}>{answer.answers?.[0]}</span>
        </div>
        <div className={`${styles.answerBody} ${styles.correctColor}`}>
            <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>
            <span className={`${styles.rightAnswer} ${styles.span} ${styles.answText}`}>{question.answers?.[0]?.value}</span>
        </div>
    </>
};
