import React from "react";
import CustomerData from "components/UI/AsideModal/templates/CustomerData";
import EmployerWorkInfo from "components/UI/AsideModal/templates/EmployerWorkInfo";
import Trio from "./templates/Trio";
import SendDocument from "./templates/SendDocument";
import LocationsForm from "./templates/LocationsForm";
import AddAvatar from "./templates/AddAvatar";
import AddTestResult from "./templates/groups/AddTestResult/AddTestResult";
import ModalDateRange from "./templates/ModalDateRange/ModalDateRange";
import DiscountMarkup from "./templates/groups/DiscountMarkup";
import SingleWorkTableModal from "./templates/SingleWorkTableModal";
import ModalFields from "./templates/ModalFields/ModalFields";
import Salary from "./templates/Salary/Salary";
import Informer from "./templates/Informer";
import CreatePoolTest from "./templates/CreatePoolTest";
import BTS from "./templates/BTS";
import TimeTableForm from "./templates/groups/TimeTableForm";
import ClassroomForm from "./templates/ClassroomForm";
import ClientInformation from "./templates/ClientInformation";
import TimeTableDay from "./templates/TimeTableDay";
import MultiPhoneNumbers from "./templates/MultiPhoneNumbers/MultiPhoneNumbers";
import SettingPayments from "./templates/SettingPayments";
import EmployeeContract from "./templates/WorkPlain/EmployeeContract";
import WorkSchedule from "./templates/WorkPlain/WorkSchedule";
import MultiSalary from "./templates/Salary/MultiSalary";
import AddTest from "./templates/StudyTest/AddTest";
import CreateTestPool from "./templates/StudyTest/CreateTestPool";
import TestRequest from "./templates/StudyTest/TestRequest";
import TestQuestion from "./templates/StudyTest/TestQuestion/TestQuestion";
import FundTransfer from "./templates/groups/FundTransfer";
import ClientContactPerson from "./templates/ClientContactPerson";
import CourseForm from "./templates/CourseForm";
import BeginLesson from "./templates/groups/BeginLesson";
import DayStatus from "./templates/TeacherSchedule/DayStatus";
import CloseSchedule from "./templates/groups/CloseSchedule";
import AddMedia from "./templates/AddMedia";
import LessonsQuestion from "./templates/LessonsQuestion/LessonsQuestion";
import Certificates from "./templates/groups/Certificates";
import AddPayment from "./templates/Pays/AddPayment";
import FilesAndComments from "./templates/files/FilesAndComments";
import Refunds from "./templates/Pays/Refunds";
import ConfirmPayment from "./templates/Pays/ConfirmPayment";
import AddBundle from "./templates/widget/AddBundle";
import AddQuestionsPlacementTest from "./templates/widget/AddQuestionsPlacementTest";
import ModalCompany from "./templates/ModalCompany/ModalCompany";
import AddUser from "./templates/AddUser";
import ModalCRM from "./templates/ModalCRM/ModalCRM";
import StudentSense from "./templates/groups/StudentSense";
import Quad from "./templates/Quad";
import BookForm from "./templates/Ebooks/BookForm";
import QuestionTestResult from "./templates/QuestionTestResult";
import GroupsDayStatus from "./templates/GroupsDayStatus";
import ModalSchedule from "components/Modules/UniDance/ModalSchedule";
import AddCertificate from "./templates/AddCertificate";
import ClientBarcodeForm from "./templates/UniDance/ClientBarcodeForm/ClientBarcodeForm";
import FormGenerator from "./templates/FormGenerator";
import ChangeClientGroupComment from "./templates/groups/ChangeClientGroupComment/ChangeClientGroupComment";
import AddSectionInBook from "./templates/ModalEbookSection/ModalEbookSection";
import AddBookByCode from "./templates/Ebooks/AddBookByCode";
import ChangingBarcodeClientUnidance from "./templates/UniDance/ClientBarcodeForm/ChangingBarcodeClientUnidance";
import AsideQuad from "./templates/AsideQuad";


export function templates(ms){
  //if (!ms.link) return

  if (ms.modalBodyComponent)
    return ms.modalBodyComponent()

  switch (ms.type) {
    case 'customerData':
      return <CustomerData />
    case 'employerWorkInfo':
      return <EmployerWorkInfo />
    case 'SelectorAndInput':
      return <Trio />
    case 'SendDocsToUser':
      return <SendDocument />
    case 'LocationsForm':
      return <LocationsForm />
    case 'AddAvatar':
      return <AddAvatar />
    case 'groupTestResult':
      return <AddTestResult />
    case 'set-data-range':
      return <ModalDateRange />
    case 'add-dors':
      return <DiscountMarkup />
    case 'work-table-modal-in':
      return <SingleWorkTableModal actionType='in'/>
    case 'work-table-modal-out':
      return <SingleWorkTableModal actionType='out'/>
    case 'change_active_fields':
      return <ModalFields />
    case 'salary':
      return <Salary />
    case 'Informer':
      return <Informer />
    case 'Informer_vb':
      return <Informer />
    case 'create_poll_test':
      return <CreatePoolTest />
    case 'bts':
      return <BTS modal_settings={ms.data} location={ms.location} />
    case 'time_table':
      return <TimeTableForm />
    case 'classroom':
      return <ClassroomForm />
    case 'client_info':
    case 'user_to_group':
    case 'add_client':
      return <ClientInformation />
    case 'time_table_note':
      return <TimeTableDay />
    case 'milti_phone':
      return <MultiPhoneNumbers />
    case 'setting-payments':
      return <SettingPayments />
    case 'employee_contract':
      return <EmployeeContract />
    case 'workSchedule':
      return <WorkSchedule />
    case 'multiSalary':
      return <MultiSalary />
    case 'add-study-test':
      return <AddTest />
    case 'create-study-test':
      return <CreateTestPool />
    case 'request-study-test':
      return <TestRequest />
    case 'add-question-study-test':
      return <TestQuestion />
    case 'fund-transfer':
      return <FundTransfer />
    case 'client_contact_person':
      return <ClientContactPerson />
    case 'CourseForm':
      return <CourseForm />
    case 'BeginLesson':
      return <BeginLesson />
    case 'day-status':
      return <DayStatus />
    case 'close_schedule':
      return <CloseSchedule />
    case 'AddMedia':
      return <AddMedia />
    case 'AddCertificate':
      return <AddCertificate />
    case 'lessons_question':
      return <LessonsQuestion />
    case 'certificates':
      return <Certificates />
    case 'addPayment':
      return <AddPayment />
    case 'files-comments':
      return <FilesAndComments />
    case 'refundModal':
      return <Refunds />
    case 'confirmPayment':
      return <ConfirmPayment />
    case 'addBundle':
      return <AddBundle />
    case 'addQuestionsPlacementTest':
      return <AddQuestionsPlacementTest />
    case 'ModalCompany':
      return <ModalCompany />
    case 'add_user':
      return <AddUser />
    case 'change_crm_fields':
      return <ModalCRM />
    case 'group_student_sense':
      return <StudentSense />
        case 'quad':
      return <Quad />
    case 'quadForAsideModal':
      return <AsideQuad />
    case 'generator':
      return <FormGenerator />
    case 'ebookBookForm':
      return <BookForm/>
    case 'show_question_test_result':
      return <QuestionTestResult />
    case 'groups_day_status':
      return <GroupsDayStatus />
    case 'unidance_schedule_form':
      return <ModalSchedule />
    case 'unidance_client':
      return <ClientBarcodeForm />
    case 'change_client_group_comment' :
      return <ChangeClientGroupComment/>
    case 'add_section_in_book' :
      return <AddSectionInBook/>
    case 'add_book_by_code' :
      return <AddBookByCode/>
    case 'changeBarcodeClientUnidance':
      return <ChangingBarcodeClientUnidance/>
    default:
      return "Ошибка шаблона модального окна"
  }
}
