import React from 'react';
import styles from "./CompleteSentence.module.scss";

export const CompleteSentence = ({question, answer}) => {
    const isCorrect = question.answers?.[0]?.value === answer.answers.join(' | ')
    return (
        <>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                <div className={styles.answers}>
                    {
                        answer.answers.map((el, index) =>
                            <div key={index}>
                        <span
                            className={isCorrect ? styles.rightAnswer : styles.wrongAnswer}>
                            {el}
                        </span>
                                {index < answer.answers.length - 1 &&
                                    <span className={styles.delimitr}>&nbsp;|&nbsp;</span>}
                            </div>)
                    }
                </div>
            </div>
            <div className={`${styles.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>
                <div className={styles.answers}>
                    {
                        question.answers?.[0]?.value.split('|')?.map((el, index) =>
                            <div key={index}>
                        <span
                            className={styles.rightAnswer}>
                            {el}
                        </span>
                                {index < answer.answers.length - 1 &&
                                    <span className={styles.delimitr}>&nbsp;|&nbsp;</span>}
                            </div>)
                    }
                </div>
            </div>
        </>
    );
};
