import React from 'react';
import styles from './QuestionImageVariants.module.scss'
import Stores from "../../../../../../../../../../../Stores";

export const QuestionImageVariants = ({question, answer}) => {
    return (
        <>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                <div className={styles.mappedImages}>
                    {
                        answer.answers?.map((image, index) => (
                                <div key={`${image.id}${index}`} className={`${styles.imageBlock} ${answer.correct_answers[index]
                                    ? styles.checkedRightCheckbox
                                    : styles.checkedWrongCheckbox}`}>
                                    <img src={Stores.baseLink() + image.split('|')[0]} alt="picture"/>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
            <div className={`${styles.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>

                <div className={styles.mappedImages}>
                    {
                        question.answers?.map((image, index) => (
                                <div key={`${image.id}${index}`} className={`${styles.imageBlock} ${ question.answers[index].is_correct
                                    ? styles.checkedRightCheckbox
                                    : styles.checkedWrongCheckbox}`}>
                                    <img src={Stores.baseLink() + image.value.split('|')[0]} alt="picture"/>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </>
    );
};