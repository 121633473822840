import React, { useState, useEffect } from 'react';
import st from './RunTime.module.scss'

const TimeLeft = ({ delta, className = 'timeLeft' }) => {
    const [sec, setSec] = useState(delta);

    const convertSecondsToHMS = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return { hours, minutes, seconds: remainingSeconds };
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setSec((prevSec) => prevSec - 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (sec < 1) {
            window.location.reload();
        }
    }, [sec]);

    const { hours, minutes, seconds } = convertSecondsToHMS(sec);

    return (
        <div className={className}>
            <div>
               <span><strong className={st.timeSize}>{hours}</strong><strong className={st.colorRunTime}>h.</strong></span>
            </div>
            <div>
                <span><strong className={st.timeSize}>{minutes}</strong><strong className={st.colorRunTime}>m.</strong></span>
            </div>
            <div>
                <span><strong className={st.timeSize}>{seconds < 60 ? 1 : seconds}</strong><strong className={st.colorRunTime}>s.</strong></span>
            </div>
        </div>
    );
};

export default TimeLeft;