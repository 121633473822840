import React from 'react';
import {getInitAnswer} from "../TestQuestion"
import st from "./SortSentences.module.scss";
import NewVariantAdd from "./NewVariants/NewVariantAdd/NewVariantAdd";
import NewVariantDel from "./NewVariants/NewVariantDel/NewVariantDel";

export default function SortSentences(props) {
    const action = (type, pl) => {
        let arr = []
        switch (type) {
            case 'change':
                arr = props.values.map((x, i) => i === pl.index ? {...x, value: pl.val} : x)
                break
            case 'add':
                arr = props.values.concat([getInitAnswer('sort_sentences')])
                break
            case 'delete':
                arr = props.values.filter((x, i) => i !== pl.index)
                break
        }
        props.onChange(arr, props.name)
    }

    return (
        <div className="super-modal__form-column">
            <div className={st.box}>
                <div className={st.tip}>
                    Занесите предложения в правильном порядке, после сохранения они автоматически перемешаются
                </div>
                {props.values.map((item, index) =>
                    <div key={index} className={st.variant}>
                            <input type="text"
                                   placeholder={`Предложение ${index + 1}`}
                                   value={item.value}
                                   onChange={e => action('change', {index, val: e.target.value})}
                                   className={`${props.error && item.value.length < 2 ? 'super-modal__input--error' : undefined} ${st.inputSentenc}`}
                            />
                        {props.values.length > 2 &&
                        // <div className={st.delete} onClick={() => action('delete', {index})}><Delete className={st.svg}/></div>
                            <NewVariantDel onClick={() => action('delete', {index})}/>
                        }
                    </div>)}
            </div>
            {/*<div className="modal-question-add">*/}
            {/*    <div className="modal-question-add-button-add" onClick={() => action('add')}>+</div>*/}
            {/*</div>*/}
            <NewVariantAdd onClick={() => action('add')}/>
        </div>
    );
};
