import React from 'react';
import {QuestionPlusText} from "./QuestionPlusText/QuestionPlusText";
import {Variants} from "./Variants/Variants";
import {CompleteSentence} from "./CompleteSentence/CompleteSentence";
import {TextMissingWords} from "./TextMissingWords/TextMissingWords";
import {WordMatching} from "./WordMatching/WordMatching";
import {WordsInBlanks} from "./WordsInBlanks/WordsInBlanks";
import {MatchWordsImages} from "./MatchWordsImages/MatchWordsImages";
import {MakeWordFromLetters} from "./MakeWordFromLetters/MakeWordFromLetters";
import {Essay} from "./Essay/Essay";
import {ImageMatching} from "./ImageMatching/ImageMatching";
import {SortSentences} from "./SortSentences/SortSentences";
import {QuestionImageVariants} from "./QuestionImageVariants/QuestionImageVariants";

export const CustomAnswer = ({question, answer, item}) => {

    const customAnswers = {
        'question_plus_text': <QuestionPlusText question={question} answer={answer} />,
        "variants": <Variants question={question} answer={answer} />,
        "audio_plus_variants": <Variants question={question} answer={answer} />,
        "video_plus_variants": <Variants question={question} answer={answer} />,
        "complete_sentence": <CompleteSentence question={question} answer={answer} />,
        "text_missing_words": <TextMissingWords question={question} answer={answer}/>,
        "word_matching": <WordMatching question={question} answer={answer}/>,
        "words_in_blanks": <WordsInBlanks question={question} answer={answer} />,
        "match_words_images": <MatchWordsImages question={question} answer={answer}/>,
        "image_matching": <ImageMatching question={question} answer={answer}/>,
        "drag_words_in_blanks": <WordsInBlanks question={question} answer={answer}/>,
        "make_word_from_letters": <MakeWordFromLetters question={question} answer={answer}/>,
        "essay": <Essay question={question} answer={answer} item={item}/>,
        "sort_sentences": <SortSentences question={question} answer={answer}/>,
        "question_image_variants": <QuestionImageVariants question={question} answer={answer}/>
    }

    return customAnswers[question.type]
};

