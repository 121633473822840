import React, {useEffect, useState} from "react";
import s from "./AttemptsScreen.module.scss";
import {ControlAttempts} from "./ControlAttempts/ControlAttempts";
import {Attempt} from "./Attempt/Attempt";

export const AttemptsScreen = (props) => {
    const handleAttemptClick = (index) => {
        if(index >= 0 && index < props?.attempts?.length) {
            props?.setShowedTry(index)
        }
    };

    const calculateVisibleAttempts = () => {
        const minWidth = 320
        const minAttemptsCount = 3
        const attemptWidth = 45
        const realWidth =  window.innerWidth;

        const possibleVisibleAttemptsCountPart = minWidth < realWidth ? Math.floor(((realWidth - minWidth) / attemptWidth)) : 0

        const possibleVisibleAttemptsCount = minAttemptsCount + possibleVisibleAttemptsCountPart

        const attemptsCount = Math.min(possibleVisibleAttemptsCount, props.allAttemptsCount);

        return Array(attemptsCount).fill({})
    }

    const [visibleAttempts, setVisibleAttempts] = useState(calculateVisibleAttempts())

    const handleResize = () => {
        setVisibleAttempts(calculateVisibleAttempts())
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

  return (
    <div className={s.sizeWrapper}>
      <ControlAttempts className={s.controlAttempsInstance} direction="left"
                       handleClick={() => handleAttemptClick(props?.showedTry-1)}/>
      <div className={s.div}>
        <div className={s.textWrapper}>Attempts: {props?.showedTry+1}</div>
        <div className={s.attemptsTwo} >
          {visibleAttempts?.map((a, index) => (
              <Attempt
                  key={index}
                  className={s.attemptsInstance}
                  onClick={() => handleAttemptClick(index)}
                  selected={index === props?.showedTry || (props?.showedTry > visibleAttempts.length - 1 && index === visibleAttempts.length - 1 )}
              />)
                      )}
            </div>
      </div>
      <ControlAttempts className={s.controlAttempsInstance} direction="right"
                       handleClick={() => handleAttemptClick(props?.showedTry+1)}/>
    </div>
  );
};
