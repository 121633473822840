import React from 'react';
import PropTypes from "prop-types";
import ImageSelector from "./ImageSelector";
import st from "./MatchPair.module.scss"

const MatchPair = props => {

    const [val0, val1] = props.value.split('|')

    const changeHandler = (val, num) => {
        props.onChange(num ? `${val0}|${val || ''}` : `${val || ''}|${val1}`)
    }

    return (
        <div className={st.pair}>
            <ImageSelector error={props.error} value={val0} onChange={val => {changeHandler(val, 0)}}/>
            <ImageSelector error={props.error} value={val1} onChange={val => {changeHandler(val, 1)}}/>
        </div>
    );
};

MatchPair.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default MatchPair
