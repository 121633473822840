import React, {useEffect, useState} from 'react';
import {dateFormat} from "helpers/date";
import AsideFilterStore from "./asideFilterStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import {SuperButton} from 'components/UI/SuperButton/SuperButton';
import {CloseButton} from 'components/UI/SuperButton/SuperButton';
import FormInput from "components/UI/AsideModal/Form/FormInput";
import DatePicker from "react-datepicker";
import RadioButton from "components/UI/RadioButtons/RadioButton";
import FormField from "../AsideModal/Form/FormField";
import FormLabel from "../AsideModal/Form/FormLabel";
import styles from "./AsideFilter.module.scss";
import {ReactComponent as Calendar} from "components/UI/icons/calendar-line.svg";
import Checkbox from "components/ContentBox/Global/Checkbox/Checkbox";

const AsideFilter = ({config, filterValue, getFilterParams, onDropFilter, translate}) => {
  const {closeFilter, dropTextValues, filterTextValues} = AsideFilterStore

  const [inputsValues, setInputsValues] = useState(filterValue || {});
  const [textValues, setTextValues] = useState(filterTextValues || []);

  const getFilterValue = () => {
    closeFilter()
    AsideFilterStore.setTextValues(textValues)
    if (getFilterParams) getFilterParams(inputsValues)
  }

  const dropFilter = () => {
    setInputsValues({});
    dropTextValues()
    if (onDropFilter) onDropFilter()
  }

  const returnValue = key => inputsValues[key] ?? ''

  const changeHandler = (e, key) => {
      const value = e?.target ? e.target.value : e;
      setInputsValues({...inputsValues, [key]: value});
  }

  const changeTextValuesHandler = (key, label, value) => {
    const field = textValues.find(x => x.key === key)
    if (field)
      setTextValues(textValues.map(field => field.key === key ? {key, label, value} : field))
    else
      setTextValues([...textValues, {key, label, value}])
  }

  const changeTextValuesHandlerMulti = (key, label, value) => {
    const field = textValues.find(x => x.key === key)
    const values = value.map((el) => el.label).join(', ')
    if (field) {
      setTextValues(textValues.map(field => field.key === key ? {key, label, value: values} : field))
    } else
      setTextValues([...textValues, {key, label, value: values}])
  }

  const objectChangeHandler = (value, key_name, subMenu_key) => {
    if (subMenu_key !== undefined) {
      setInputsValues({
        ...inputsValues,
        [key_name]: value,
        [subMenu_key]: subMenu_key,
        is_active: true,
      });
    } else {
      setInputsValues({ ...inputsValues, [key_name]: value });
    }
  };
  const renderFilterFields = () => {
    const selectArr = [];
    config.forEach((item, index) => {
      switch (item.type) {
        case "select":
          selectArr.push(
            <FormField key={index}>
              <FormLabel>{item.label}:</FormLabel>
              <SelectCustom
                placeholder={item.placeholder}
                apiLink={item.apiLink}
                apiLinkCRM={item.apiLinkCRM}
                apiConfParams={item.apiConfParams}
                apiGetResult={
                  item.key_name ? (data) => data[item.key_name].items : null
                }
                valueID={returnValue(item.nameState)}
                onChange={(o) => {
                  changeHandler({target: o}, item.nameState)
                  changeTextValuesHandler(item.nameState, item.label, o.label)
                }}
                isSearchable={item?.isSearch}
                options={item?.values}
                optionLabelKey={item?.option_label_key}
                optionValueKey={item?.option_value_key}
              />
            </FormField>
          );
          break;
        case "select_father":
          selectArr.push(
              <FormField key={index}>
                  <FormLabel>{item.label}:</FormLabel>
                  <SelectCustom
                      placeholder={item.placeholder}
                      apiLink={item.apiLink}
                      apiGetResult={item.key_name ? data => data[item.key_name].items : null}
                      valueID={inputsValues[item.nameState]?.id}
                      optionLabelKey={item.option_label_key}
                      onChange={o => setInputsValues({ ...inputsValues, [item.nameState]: o })}
                      isSearchable
                      />
              </FormField>
          )
          break;
        case "select_son":
          selectArr.push(
              <FormField key={index}>
                  <FormLabel>{item.label}:</FormLabel>
                  <SelectCustom
                      placeholder={item.placeholder}
                      valueID={inputsValues[item.nameState]}
                      options={inputsValues[item.fatherName]?.[item.optionsKey] || []}
                      optionLabelKey={item.option_label_key}
                      onChange={o => changeHandler(o.id, item.nameState)}
                      isSearchable
                  />
              </FormField>
          )
          break;
        case "select-multi":
          selectArr.push(
              <FormField key={index}>
                <FormLabel>{item.label}:</FormLabel>
                <SelectCustom
                    placeholder={item.placeholder}
                    apiLink={item.apiLink}
                    apiLinkCRM={item.apiLinkCRM}
                    apiConfParams={item.apiConfParams}
                    apiGetResult={
                      item.key_name ? (data) => data[item.key_name].items : null
                    }
                    valueID={inputsValues[item.nameState] || []}
                    onChange={opts => {
                      setInputsValues({...inputsValues, [item.nameState]: opts.map(opt => opt.id)})
                      changeTextValuesHandlerMulti(item.nameState, item.label, opts)
                    }}
                    isSearchable={item?.isSearch}
                    options={item?.values}
                    optionLabelKey={item?.option_label_key}
                    isMulti
                />
              </FormField>
          );
          break;
        case "select_request" :
          selectArr.push(
              <FormField key={index}>
                <FormLabel>{item.label}:</FormLabel>
                <SelectCustom
                   placeholder={item?.placeholder}
                   apiLink={item.apiLink}
                   apiGetResult={item?.key_name ? data => data[item?.key_name].items : item?.apiGetResult}
                   sortKey={item?.sort_key}
                   valueID={inputsValues[item?.nameState] || []}
                   optionValueKey={item?.option_value_key}
                   optionLabelKey={item?.option_label_key}
                   apiConfParams={item?.params}
                   onChange={o => {changeHandler(item?.option_value_key ? o[item?.option_value_key] : o.id, item?.nameState)}}
                   isSearchable
                />
              </FormField>
          );
          break;
        case "input":
          selectArr.push(
            <FormField key={index}>
              <FormLabel>{item.label}:</FormLabel>
              <FormInput>
                <input
                  type={item.type || "text"}
                  placeholder={item.placeholder}
                  value={returnValue(item.nameState) || ''}
                  onChange={e => {
                    changeHandler(e, item.nameState)
                    changeTextValuesHandler(item.nameState, item.label, e.target.value)
                  }}
                  className="filter-box__input"
                />
              </FormInput>
            </FormField>
          );
          break;
        case "type_selector":
          selectArr.push(
            <FormField key={index}>
              <FormLabel>{item.label}:</FormLabel>
              <RadioButton
                values={item.values}
                like_obj={true}
                objectChangeHandler={(e, key) => {
                  changeHandler(e, key)
                  changeTextValuesHandler(item.nameState, item.label, item.values.find(x => x.id === e).name)
                }}
                selected={returnValue(item.nameState)}
                name={item.nameState}
              />
            </FormField>
          );
          break;
        case "date_day_picker":
          selectArr.push(
            <FormField key={index}>
              <FormLabel>{item.label}:</FormLabel>
              <div className={`${styles.filterField__montPicker} ${styles.filterField__monthRangePicker}`}>
                <div className={styles.datePikerContainer}>
                  <Calendar className={styles.calendarIcon}/>
                  <DatePicker
                    selected={returnValue(item.nameState[0]) && new Date(returnValue(item.nameState[0]))}
                    onChange={date => {
                      changeHandler(dateFormat(date, "YYYY-mm-dd"), item.nameState[0])
                      //changeTextValuesHadler(item.nameState, item.label, o.label)
                    }}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    maxDate={returnValue(item.nameState[1]) && new Date(returnValue(item.nameState[1]))}
                    placeholderText={translate.start_date}
                    withPortal
                  />
                </div>
                <div className={styles.datePikerContainer}>
                  <Calendar className={styles.calendarIcon}/>
                  <DatePicker
                    selected={returnValue(item.nameState[1]) && new Date(returnValue(item.nameState[1]))}
                    onChange={date => changeHandler(dateFormat(date, "YYYY-mm-dd"), item.nameState[1])}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    minDate={returnValue(item.nameState[0]) && new Date(returnValue(item.nameState[0]))}
                    placeholderText={translate.end_date}
                    withPortal
                  />
                </div>
              </div>
            </FormField>
          );
          break;
        case "checkbox":
          selectArr.push(
            <div key={index}>
              <Checkbox
                label={item.label}
                checked={inputsValues[item.name]}
                name={item.name}
                onChange={() => objectChangeHandler(!inputsValues[item.name], item.name)}
              />
            </div>
          );
            break;
        default :
          break
      }
    })
    return selectArr;
  }

  const onKeyDown = e => {
    if (e.key === 'Enter') getFilterValue()
  }

    useEffect(() => {
        document.body.classList.add("modalOpen")
        return () => {
            document.body.classList.remove("modalOpen")
        }
    }, [])

  return (
    <div onClick={closeFilter}
         className={styles.wrapper}>
      <div className={styles.box}
           onClick={(e) => e.stopPropagation()}
           onKeyDown={onKeyDown}>
        <div className={styles.header}>
          <div className={styles.title}>
            {translate.filter}
          </div>
          <CloseButton onClick={closeFilter}/>
        </div>
        <div className={styles.mobileWrapper}>
          <div className={styles.body}>
            {renderFilterFields()}
          </div>
          <div className={styles.btnBox}>
            <SuperButton onClick={dropFilter} variant='reset'>
              {translate.reset_all}
            </SuperButton>
            <SuperButton onClick={getFilterValue} variant='confirm'>
              {translate.apply}
            </SuperButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AsideFilter;