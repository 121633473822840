import React from 'react';
import styles from  './MatchWordsImages.module.scss'
import Stores from "Stores";

export const MatchWordsImages = ({question, answer}) => {

    return (
         <>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                <div className={styles.conteinerStAnswr}>
                    {
                        answer.answers?.map((image, index) => {
                            const splImgAndWord = image.split('|')
                            const imgPath = splImgAndWord[0];
                            const imgWord = splImgAndWord[1];
                            return (
                                <div key={`${image.id}${index}`} className={styles.imageContainer}>
                                    <img className={styles.imgSize} src={Stores.baseLink() + imgPath} alt="picture"/>
                                    <div className={`${styles.imgWord} ${answer.correct_answers[index] 
                                        ? styles.checkedRight 
                                        : styles.checkedWrong}`}>
                                        {imgWord}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={`${styles.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>
                <div className={styles.conteinerStAnswr}>
                {
                    question.answers
                        ?.map((image, index) => {
                            const splitPathAndWord = image.value.split('|');
                            const imgPath = splitPathAndWord[0];
                            const imgWord = splitPathAndWord[1];
                            return (
                                <div key={`${image.id}${index}`} className={styles.imageContainer}>
                                    <img className={styles.imgSize} src={Stores.baseLink() + imgPath} alt="picture"/>
                                    <div className={`${styles.imgWord} ${styles.checkedRight}`}>{imgWord}</div>
                                </div>
                            )
                        })
                }
                </div>
            </div>
        </>
    );
};