import React from 'react'
import PropTypes from 'prop-types'
import st from "./NewVariantAdd.module.scss"
import {ReactComponent as Plus} from "components/UI/icons/add-line.svg";

const NewVariantAdd = ({onClick, isDisabled}) => <button className={st.button} onClick={onClick} disabled={isDisabled}>
    <div className={st.plus}><Plus/></div>
    <div>Add</div>
</button>

NewVariantAdd.propTypes = {
    onClick: PropTypes.func.isRequired
}

NewVariantAdd.defaultProps = {
    onClick: () => {}
}

export default NewVariantAdd
