import { makeAutoObservable, observable } from 'mobx'

class AiChatStore {
	aiChatData = null
	messages = observable.array([])
	currentThread = ''
	nextUrl = ''

	constructor() {
		makeAutoObservable(this)
	}

	setAiChatData(data) {
		this.aiChatData = data
	}

	fetchMessage(message) {
		this.messages.push(message)
	}

	addNewMessage(message) {
		this.messages.unshift(message)
		this.messages = [...this.messages]
	}

	fetchResponseMessage(responseMessage, thread, createdAt) {
		this.messages.push({
			message: responseMessage,
			alien: true,
			date: createdAt,
			thread,
		})
		this.currentThread = thread
	}

	addNewResponseMessage(responseMessage, thread, createdAt) {
		this.messages.unshift({
			message: responseMessage,
			alien: true,
			date: createdAt,
			thread,
		})
		this.currentThread = thread
		this.messages = [...this.messages]
	}

	setNextUrl(url) {
		this.nextUrl = url
	}

	clearMessages() {
		this.messages.clear()
	}
}

const aiChatStore = new AiChatStore()
export default aiChatStore
