import React from "react";
import s from "./ControlAttempts.module.scss";
import {ReactComponent as RightArrow} from "components/ContentBox/img/arrow-right-s-line.svg";
import {ReactComponent as LeftArrow} from "components/ContentBox/img/arrow-right-s-line.svg";

export const ControlAttempts = ({ direction, handleClick }) => {
  return (
    <div onClick={handleClick} className={s.controlAttemps}>
      {direction === "left" && (
          <LeftArrow className={`${s.systemLineArrow} ${s.rotate}`}/>
      )}
      {direction === "right" && (
          <RightArrow className={`${s.systemLineArrow}`}/>
      )}
    </div>
  );
};
