import React, {useEffect, useState} from 'react';
import {ReactComponent as LeftOlive} from "components/ContentBox/img/left_olive.svg";
import {ReactComponent as RightOlive} from "components/ContentBox/img/right_olive.svg";
import styles from "./MainTableRowScore.module.scss";
import StudyTestResultStore from "Stores/studyTestResultStore";
import {superAxiosRequest} from "axiosApi";

export default function MainTableRowScore({currentTry}) {
    
    const {isClient} = StudyTestResultStore
    const [data, setData] = useState(null);
    let studentPoints = isNaN(data?.stats?.points) || data?.stats?.points === null ? 0 : data?.stats?.points;
    let maximumPoints = isNaN(data?.stats?.total_points) || data?.stats?.total_points === null ? 0 : data?.stats?.total_points;
    let percentage = (maximumPoints === 0) ? 0 : Math.round((studentPoints / maximumPoints) * 100);

    useEffect(() => {
            const fetchFunc = async () => {
                await superAxiosRequest({
                    link: `${isClient ? 'study/student_test_result_by_init_test' : 'study/teacher_test_result_by_init_test'}/${currentTry?.id}`,
                    method: "get",
                }).then(({ data }) => {
                    setData(data)
                }).catch(() => {
                    setData({})
                })
            }
            fetchFunc();
    }, [currentTry?.id]);

    return (
        <div className={styles.mainTableRowScore}>
            <LeftOlive/>
            <div className={styles.bigNumbers}>
                <div className={styles.largeNumber}>{data?.stats?.correct_answers || 0}</div>
                <div className={styles.secondaryNumber}>{`${percentage}%`}</div>
            </div>
            <RightOlive/>
            <div className={styles.scoreInfo}>
                <div className={styles.questionInfo}>Questions:
                    <span className={styles.fontWeightNumbers}>{data?.stats?.correct_answers || 0}</span>
                    <span className={styles.verticalLine}>|</span>
                    <span className={styles.numbers}>{data?.stats?.total_answers || 0}</span>
                </div>
                <div className={styles.scoreNumbers}>Score:
                    <span className={styles.fontWeightNumbers}>{data?.stats?.points || 0}</span>
                    <span className={styles.verticalLine}>|</span>
                    <span className={styles.numbers}>{data?.stats?.total_points || 0}</span>
                    <span className={styles.verticalLine}>|</span>
                    <span className={styles.percentNumbers}>{`${percentage}%`}</span>
                </div>
            </div>
        </div>
    );
}
