import React, {useEffect, useState} from 'react';
import styles from "components/ContentBox/StudyTest/ShowTest/ShowTest.module.scss";
import '../ShowInitTest.scss';
import {ReactComponent as StarIcon} from "components/UI/icons/star-line.svg";
import MainTableRowScore from "./MainTableRowScore/MainTableRowScore";
import MainTableRowTime from "./MainTableRowTime/MainTableRowTime";
import Stores from "Stores";
import {AttemptsScreen} from "./AttemptsScreen/AttemptsScreen";
import NoResults from "../NoResults";
import {localisationMaster} from "Localisation/Localisation";
import AnswerQuestionNew
    from "components/Modules/Study/TeacherResult/components/ResultAnswers/CompleteAnswers/CompleteAnswer/AnswerQuestionNew/AnswerQuestionNew";
import StudyTestResultStore from "Stores/studyTestResultStore";


export default function ShowTest(props) {

    const {result, fetchTestResult} = StudyTestResultStore
    const [showedTry, setShowedTry] = useState(0)
    const [open, setOpen] = useState(props.open)
    const [qValue, setqValue] = useState({id: 0, value: null})

    const changeQuestionHandler = id => {
        let a = props.item.list[showedTry]?.answers_student?.find(el => el.question.id === id);
        if (!a) return;
        setqValue({id, value: a});
    }
    const getQuestionButtonClassname = id => {
        let a = props.item.list[showedTry]?.answers_student?.find(el => el.question.id === id);
        if (a?.question?.type === 'essay') {
            return `showInitTest__questBtn showInitTest__questBtn--yellow ${qValue.id === id ? 'showInitTest__questBtn--yellow--selected' : ''}`;
        }
        if (!a || props.disableAnswers) return 'showInitTest__questBtn';
        if (!a?.correct_answers?.some((el) => el === false)) {
            return `showInitTest__questBtn showInitTest__questBtn--correct ${qValue.id === id ? 'showInitTest__questBtn--correct--selected' : ''}`;
        } else {
            return `showInitTest__questBtn showInitTest__questBtn--wrong ${qValue.id === id ? 'showInitTest__questBtn--wrong--selected' : ''}`;
        }
    }

    useEffect(() => {
        fetchTestResult(props.item.list[showedTry]?.id);
    }, [props.item.list[showedTry]?.id,  fetchTestResult]);

    
    return <div>
        <div className={styles.header}>
            <div className={styles.clickZone} onClick={() => setOpen(!open)}>
                <div className={styles.testInfo}>
                    <div>{Stores.stqt.find(el => el.value === qValue?.value?.question?.type)?.name || 'No type question'}</div>
                    <div className={styles.delimiter}></div>
                    {props.item.list[showedTry]?.questions_map && 
                      props.item.list[showedTry].questions_map[qValue?.value?.question?.id]?.test_section?.name || 'No category'}

                </div>
            </div>
            <div className={styles.actionMenu}>
                <div className={styles.params}>
                    <div className={styles.scoreBlock}>
                        <StarIcon />
                        <p>{result?.stats?.points || 0}</p>
                        <div>/</div>
                        <p>{result?.stats?.total_points || 0}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.delemetr}></div>
        <div className={styles.attempts}>
            <AttemptsScreen attempts={props.item.list} allAttemptsCount={props.item.tryCount} showedTry={showedTry} setShowedTry={setShowedTry}/>
        </div>
        <div>
            <div className={styles.delemetr}></div>
            <div className={styles.rowScoreAndTime}>
                <MainTableRowScore currentTry={props.item.list[showedTry]}/>
                <MainTableRowTime timeLeft={props.item.timeLeft} attempts={props.item.list} showedTry={showedTry}/>
            </div>

        </div>
        <div className={styles.delemetr}></div>
        <div className={styles.showTest__questions}>
            {props.item.list[showedTry]?.is_correct_answers.map((el, index) => {
                return (
                    <div key={index} className={getQuestionButtonClassname(el.id)}
                         onClick={() => changeQuestionHandler(el.id)}>{index + 1}</div>
                )
            })}
        </div>

        <div className="showInitTest_question">
            <div className="showInitTest_questionBody">{!props.disableAnswers && qValue.value !== null ?
                <AnswerQuestionNew answer={qValue.value} item={props.item} modal/> :
                <NoResults text={localisationMaster('text_14', 'study/test')}/>}
            </div>
        </div>
    </div>
}

