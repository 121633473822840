import React from 'react';
import styles from "./TextMissingWords.module.scss";
import {clearHtmlTagsAndSymbols} from "../utils";

export const TextMissingWords = ({question, answer}) => {

    function removeHtmlTagsAndEntities(input) {

        let dummy = document.createElement('div');

        dummy.innerHTML = input;

        let text = dummy.textContent || dummy.innerText || "";

        text = text.replace(/&nbsp;/g, ' ');

        return text.split(/(\[|\])/);;
    }

    //const parts = question.answers?.[0].value.replace(/<\/?[^>]+(>|$)/g, "").split(/(\[|\])/);
    const parts = removeHtmlTagsAndEntities(question.answers?.[0].value)

    return (
        <>
            <div className={`${styles.answerBody} ${styles.studentColor}`}>
                <div className={`${styles.answerTitle} ${styles.studentColor}`}>Student Answer</div>
                <span className={styles.span}>
                    {parts.map((part, index) => {
                        return part.includes('|') ?
                            <span
                                key={index}
                                className={`${answer.correct_answers[(index - parts.indexOf('[') - 1) / 4]
                                    ? styles.rightAnswer
                                    : styles.wrongAnswer} 
                                    ${styles.spnFont}`}>
                                        {answer.answers[(index - parts.indexOf('[') - 1) / 4]}
                                    </span> :
                            <span key={index} className={styles.span}>{part}</span>
                    })}
                </span>
            </div>
            <div className={`${styles.answerBody} ${styles.correctColor}`}>
                <div className={`${styles.answerTitle} ${styles.correctColor}`}>Correct Answer</div>
                <span className={styles.span}>
                    {parts.map(part => {
                        if (part.includes('|')) {
                            const variants = clearHtmlTagsAndSymbols(part).split('|');
                            return variants.map((variant, index) => {
                                return (
                                    <>
                                        <span key={index} className={`${variant.includes('*') ? styles.rightAnswer : styles.wrongAnswer} ${styles.spnFont}`}>
                                            {variant.replace('*', '')}
                                        </span>
                                        {index !== variants.length - 1 && <span>&nbsp;|&nbsp;</span>}
                                    </>
                                );
                            });
                        } else {
                            return <span className={styles.span}>{clearHtmlTagsAndSymbols(part)}</span>
                        }
                    })}
                </span>
            </div>
        </>
    );
};