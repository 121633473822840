import React, {useEffect, useState} from 'react';
import st from "./StudyTestResult.module.scss"
import {localisationMaster} from "Localisation/Localisation";
import {Link} from "react-router-dom";
import {Timer} from "./Timer";

function Period({values}) {
    if (!values || !values?.date_from || !values?.date_until) return null;

    const formatDate = (dateString, time) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        //TODO if you will remove "year" ,data will be undefined
        const [year, month, day] = dateString.split('-');
        return ` ${day} ${months[parseInt(month) - 1]}`;
    };

    return (
        <div className={st.containerForPeriod}>
            <div className={st.period}>
                <span className={st.blueDate}>{values?.start_at}</span>
                <div className={st.grayLine}></div>
                <span className={st.grayText}>{formatDate(values?.date_from)}</span>
            </div>
            <div className={st.period}>
                <span className={st.blueDate}>{values?.finish_at}</span>
                <div className={st.grayLine}></div>
                <span className={st.grayText}>{formatDate(values?.date_until)}</span>
            </div>
        </div>
    );
}

export const TextTruncate = ({text = '', length = 30}) => {
    const [isTruncated, setIsTruncated] = useState(text.lenght >  length);
    const resultText = isTruncated ? text : `${text.slice(0, length)}...`;
    const handleShowToggle = () => {
        setIsTruncated(true)
    }

    if(!text) {
        return
    }

    return isTruncated ? (
        <>
            <div dangerouslySetInnerHTML={{ __html: resultText }} />
        </>
    ) : (
        <>
            <div dangerouslySetInnerHTML={{ __html: resultText }} />
            <div className={st.textTruncate}  onClick={handleShowToggle}> show all</div>
        </>
    )

}

export default function StudyTestResult({values}) {

    const [backgroundColor, setBackgroundColor] = useState('#EAFFF8');
    const [borderColor, setBorderColor] = useState('#1ABC97');
    const [circleBorderColor, setCircleBorderColor] = useState('#98FFE0');


    useEffect(() => {
        if (values?.seconds_until_finish > 0 && values?.seconds_until_start < 0) {
            setBackgroundColor('var(--color-warning-4)');
            setBorderColor('var(--color-warning-1)');
            setCircleBorderColor('var(--color-danger-4)');

        } else if (values?.seconds_until_finish < 0 && values?.seconds_until_start < 0) {
            setBackgroundColor('var(--color-black-0)');
            setBorderColor('var(--color-black-60)');
            setCircleBorderColor('var(--color-black-40)');
        } else {
            setBackgroundColor('var(--color-success-4)');
            setBorderColor('var(--color-success-1)');
            setCircleBorderColor('var(--color-success-3)');
        }
    }, [values]);

    function calculateSecondsValues(seconds, startDateString) {
        // Преобразование начальной даты из строки в объект Date
        let startDate = new Date(startDateString);

        // Расчет конечной даты
        let endDate = new Date();
        endDate.setSeconds(endDate.getSeconds() + seconds);

        // Расчет maxValue и value в секундах
        let maxValue = (endDate - startDate) / 1000;
        let value = (new Date() - startDate) / 1000;

        // Возвращение объекта с переменными maxValue и value
        return {maxValue, value};
    }

    const getTimer = () => {
        if ((values?.seconds_until_finish > 0 && values?.seconds_until_start < 0)) {
            const {maxValue, value} = calculateSecondsValues(values?.seconds_until_finish, values?.created_at);
            return (
                <Timer color={'var(--color-danger-1)'} timeLeft={values?.seconds_until_finish} maxValue={maxValue} value={value}
                       description={localisationMaster('text_2', 'study/test')}/>
            )
        } else if (values?.seconds_until_finish < 0 && values?.seconds_until_start < 0) {
            return (
                <Timer color={'var(--color-black-80)'} isOutdated={true} timeLeft={0} value={100} maxValue={100}
                       description={`${localisationMaster('text_3', 'study/test')}`}/>
            )
        } else {
            const {maxValue, value} = calculateSecondsValues(values?.seconds_until_start, values?.created_at);
            return (
                <Timer color={'var(--color-success-1)'} timeLeft={values?.seconds_until_start} maxValue={maxValue} value={value}
                       description={localisationMaster('text_4', 'study/test')}/>
            )
        }
    }

    return (
        <div className={st.info} style={{backgroundColor, border: `1px dashed ${borderColor}`}}>
            <div className={st.block} style={{borderColor}}>
                <div className={st.circle} style={{border: `5px solid ${circleBorderColor}`}}>
                    {getTimer()}
                </div>
                <Period values={values}/>

            </div>
            <div className={st.blockPadding} style={{borderColor}}>
                    <div className={st.testSettings}>Test Settings</div>
                <div className={st.lines}>
                    <p>
                        <span>{localisationMaster('table-row-title-3', 'tests/settings')}</span>
                        <span className={st.spanText}>{values?.study_test?.quantity_questions}</span>
                    </p>
                    <p>
                        <span>Score:</span>
                        <span className={st.spanText}>{values?.total_points}</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_5', 'study/test')}:</span>
                        <span
                            className={st.spanText}>{values?.during} {localisationMaster('mins', 'groups/group')}.</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_6', 'study/test')}:</span>
                        <span className={st.spanText}>{values?.try_count}</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_7', 'study/test')}:</span>
                        <span
                            className={st.spanText}>{values?.shuffle ? localisationMaster('text_18', 'study/study-test', 'modal') : localisationMaster('text_19', 'study/study-test', 'modal')}</span>
                    </p>
                </div>
            </div>
            <div className={st.blockForTest} style={{borderColor}}>
                <div>
                    <div className={st.testSettings}>Test Information</div>
                    <div className={st.level}>
                        <div className={st.infoOfLevel}>
                            <div>{values?.sub_lvl?.lvl?.name}</div>
                            <div className={st.horizontalLine}></div>
                            <div>{values?.sub_lvl?.lvl?.subname}</div>
                            <div className={st.horizontalLine}></div>
                            <div>{values?.sub_lvl?.name}</div>
                        </div>
                        {values?.group?.number && values?.group?.name && (
                            <Link target="_blank" to={`/groups/group/${values?.group?.id}`} className={st.group}>
                                {values?.group?.number} - {values?.group?.name}
                            </Link>
                        )}
                        {values?.group?.comment && (
                            <div className={st.text}>
                                <TextTruncate text={values?.group?.comment} lenght={30}/>
                            </div>
                        )}
                    </div>


                    <div className={`${st.testSettings} ${st.testSettingsForSecurity}`}>Test Security</div>
                    <div className={st.securityBlock}>
                    <p>
                        <span>{localisationMaster('text_8', 'study/test')}:</span>
                        <span
                            className={st.spanText}>{values?.focus_live ? `${values?.focus_live_during} ${localisationMaster('sec', 'study/test')}.` : localisationMaster('no', 'study/test')}</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_16', 'study/test')}:</span>
                        <span
                            className={st.spanText}>{values?.mouse_freeze ? `${values?.mouse_freeze_during} ${localisationMaster('sec', 'study/test')}.` : localisationMaster('no', 'study/test')}</span>
                    </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
