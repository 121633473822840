import React, {useEffect, useState} from 'react';
import st from './AssignToGroup.module.scss'
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import FormField from "components/UI/AsideModal/Form/FormField";
import styles from "components/UI/AsideFilter/AsideFilter.module.scss";
import {ReactComponent as Calendar} from "components/UI/icons/calendar-line.svg";
import DatePicker from "react-datepicker";
import {localisationMaster} from "Localisation/Localisation";
import asideModalStore from "Stores/asideModalStore";
import TypeSelector from 'components/ContentBox/Global/GlobalModalSingle/templates/WorkPlain/TypeSelector';
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";
import {classNames as cn} from "helpers";
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import useLocalization from "hooks/useLocalization";
import ToggleCheckbox from "components/UI/ToggleCheckbox/ToggleCheckbox";
import {observer} from "mobx-react";
import SelectFilter from "../SelectFilter/SelectFilter";
import {ReactComponent as DeleteIcon} from "components/UI/icons/close-line.svg";

const AssignToGroup = () => {

    // const { listInKeyChangeHandler } = modalStore;
    const errorColor = '#db3031'
    const {modalSettings: data, modalValues: getModalValues, check, changeValue, changeValues} = asideModalStore
    const listInKeyChangeHandler = (array, key_name, in_key) => {
        let a = array?.map(el => el);
        if (in_key) {
            changeValues({...getModalValues, [in_key]: {...getModalValues[in_key], [key_name]: [...a]}})
        } else {
            changeValues({...getModalValues, [key_name]: [...a]});
        }
    }


    const {translate} = useLocalization({
        section: 'groups',
        subsection: 'all_groups',
        dict: {
            "start_date": "Start Date",
            "end_date": "End Date",
        }
    })

    const [studentList, setStudentList] = useState(getModalValues.purposed_test?.students || [])
    const isRequest = data.under_request

    function getStudentList(value) {
        if (data.students) {
            setStudentList([...studentList, ...value]);
        } else {
            const {id, students} = value[0];
            changeValue(id, 'group');
            setStudentList(students.filter(el => el.status === 1));
        }
    }

    const changeHandler = (d, k, out) => {
        if (isRequest && !out) {
            changeValue(d, k, 'purposed_test')
        } else {
            changeValue(d, k)
        }
    }

    const returnTypeSelector = (index, sn) => {
        changeValue(index === 1, sn)
    }

    const dataStorage = localStorage.getItem('ud'),
        ud = JSON.parse(dataStorage),
        user_data = ud.user_data,
        profile = user_data.profile,
        time_zone = profile.time_zone

    useEffect(() => {
        if (!studentList.length) return
        if (isRequest) {
            listInKeyChangeHandler(studentList.map(item => item.id_profile), 'students', 'purposed_test')
        } else {
            listInKeyChangeHandler(studentList.map(item => item.id_profile), 'students')
        }
    }, [studentList])

    useEffect(() => {
        data.valid = {...data?.valid, name: 'required', students: 'required'};
    }, [])

    const cleanTime = (date) => {
        let h = date.getHours()
        if (+h < 10) h = '0' + h
        let m = date.getMinutes()
        if (+m < 10) m = '0' + m
        return `${h}:${m}`
    }

    return (
        <div className={st.container}>
            <FormField>
                <FormLabel isRequired> {!data.students ?
                    localisationMaster('text_2', 'study/study-test', 'modal'):
                    localisationMaster('text_3', 'study/study-test', 'modal')}:</FormLabel>
                {!data.students ?
                    <SelectFilter
                        link="groups/students"
                        placeholder={localisationMaster('text_2', 'study/study-test', 'modal')}
                        backFunction={getStudentList}
                        getApiList={list => { // показываем группы
                            const allGroups = list.filter(g => g.students.length > 0); // у которых есть студенты
                            return allGroups.filter(g => g.students.filter(s => s.status === 1).length); // и у студентов активный статус (status === 1)
                        }}
                        error={check('students')}
                    />
                    :
                    <SelectFilter
                        link="accounts/students_flat_list"
                        placeholder={localisationMaster('text_4', 'study/study-test', 'modal')}
                        new_key="full_name"
                        backFunction={getStudentList}
                        error={check('students')}
                    />
                }
            </FormField>
            {studentList.length > 0 &&
                <div>
                    <FormLabel> {localisationMaster('text_5', 'study/study-test', 'modal')}:</FormLabel>
                    <div className={st.studentList}>
                        <ul>{studentList.map((item, index) =>
                            <li key={index}>
                                <strong>{index + 1}</strong>. {item.full_name}
                                <span onClick={() => {
                                        const studentsWithoutRemoved = studentList.filter(x => x.id !== item.id)
                                        changeValue(studentsWithoutRemoved?.length ? studentsWithoutRemoved : undefined, "students")
                                        setStudentList(studentList.filter(x => x.id !== item.id))
                                }}>
                                        <DeleteIcon className={st.deleteIcon}/>
                                </span>
                            </li>)}
                        </ul>
                    </div>
                </div>}
            <FormField>
                <FormLabel isRequired> {localisationMaster('text_1', 'study/study-test', 'modal')}:</FormLabel>
                <FormInput>
                    <input
                        placeholder={localisationMaster('text_1', 'study/study-test', 'modal')}
                        onChange={(e) => changeHandler(e.target.value, 'name')}
                        value={isRequest ? getModalValues.purposed_test.name : getModalValues.name}
                        className={cn({
                            'super-modal__input--error': check('name') && errorColor,
                        })}
                    />
                </FormInput>
            </FormField>
            <div>
                <FormLabel> Testing action for clients:</FormLabel>
                <div className={st.gmtText}>You are requesting testing time in your time zone: GMT 5</div>
            </div>
            <div className={st.time}>
                <FormField>
                    <FormLabel>{"Start & Finish Time:"}</FormLabel>
                    <FormInput>
                        <div className={st.datetimeBox}>
                            <DatePicker
                                selected={new Date('2020-01-01 ' + getModalValues.start_at)}
                                timeCaption="Heure"
                                onChange={date => changeHandler(cleanTime(date), 'start_at')}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption={'Начало'}
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                            />
                            <div className={st.datetimeBoxMiddle}>-</div>
                            <DatePicker
                                selected={new Date('2020-01-01 ' + getModalValues.finish_at)}
                                timeCaption="Heure"
                                onChange={date => changeHandler(cleanTime(date), 'finish_at')}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption={'Конец'}
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                            />
                        </div>
                    </FormInput>
                </FormField>
                <FormField>
                    <FormLabel>Start & Finish Date:</FormLabel>
                    <div className={`${styles.filterField__montPicker} ${styles.filterField__monthRangePicker}`}>
                        <div className={styles.datePikerContainer}>
                            <Calendar className={styles.calendarIcon}/>
                            <DatePicker
                                selected={getModalValues.date_from && new Date(getModalValues.date_from)}
                                onChange={date => changeHandler(timeConverter(date, 'today'), 'date_from')}
                                dateFormat="dd.MM.yyyy"
                                locale="ru"
                                maxDate={getModalValues.date_until && new Date(getModalValues.date_until)}
                                placeholderText={translate.start_date}
                                withPortal
                            />
                        </div>
                        <div className={styles.datePikerContainer}>
                            <Calendar className={styles.calendarIcon}/>
                            <DatePicker
                                selected={getModalValues.date_until && new Date(getModalValues.date_until)}
                                onChange={date => changeHandler(timeConverter(date, 'today'), 'date_until')}
                                dateFormat="dd.MM.yyyy"
                                locale="ru"
                                minDate={getModalValues.date_from && new Date(getModalValues.date_from)}
                                placeholderText={translate.end_date}
                                withPortal
                            />
                        </div>
                    </div>
                </FormField>
            </div>
            <div className={st.line}></div>
            <div>
                <FormLabel> {localisationMaster('text_8', 'study/study-test', 'modal')}</FormLabel>
                <div className={st.gmtText}>{localisationMaster('text_9', 'study/study-test', 'modal')}</div>
            </div>
            <div className={st.time}>
                <div className={st.order}>
                    <FormLabel>Test execution time, min:</FormLabel>
                    <DummyCounter
                        value={isRequest ? getModalValues.purposed_test.during : getModalValues.during}
                        onChange={(value) => changeHandler(value, 'during', true)}
                        step={1} min={1} max={2000}/>
                </div>
                <div className={st.order}>
                    <FormLabel>Number of attempts:</FormLabel>
                    <DummyCounter
                        value={isRequest ? getModalValues.purposed_test.try_count : getModalValues.try_count}
                        onChange={(value) => changeHandler(value, 'try_count', true)}
                        step={1} min={1} max={2000}/>
                </div>
            </div>
            <div className={st.line}></div>
            <div className={st.order}>
                <FormLabel>{localisationMaster('text_12', 'study/study-test', 'modal')}:</FormLabel>
                <TypeSelector
                    values={[localisationMaster('text_18', 'study/study-test', 'modal'), localisationMaster('text_19', 'study/study-test', 'modal')]}
                    objectChangeHandler={returnTypeSelector}
                    selected={isRequest ? getModalValues.purposed_test.shuffle === true ? 1 : 2 : getModalValues.shuffle === true ? 1 : 2}
                    name='shuffle'/>
            </div>
            <div className={st.option}>
                <div className={st.optionBox}>
                    <ToggleCheckbox
                        label={`${localisationMaster('text_13', 'study/study-test', 'modal')}:`}
                        checked={isRequest ? getModalValues.purposed_test.focus_live : getModalValues.focus_live}
                        onChange={(status => changeHandler(status, 'focus_live'))}
                    />
                    <div className={st.optionsNumber}>
                        <DummyCounter
                            value={isRequest ? getModalValues.purposed_test.focus_live_during : getModalValues.focus_live_during}
                            disabled={isRequest ? !getModalValues.purposed_test.focus_live : !getModalValues.focus_live}
                            onChange={(value) => changeHandler(+value, 'focus_live_during', true)}
                            step={1} min={1} max={2000}/>
                    </div>
                </div>
                <div className={st.optionBox}>
                    <span
                        className={st.lineText}>{localisationMaster('text_14', 'study/study-test', 'modal')}:</span>
                    <div className={st.optionsNumber}>
                        <DummyCounter
                            value={isRequest ? getModalValues.purposed_test.focus_live_count : getModalValues.focus_live_count}
                            disabled={isRequest ? !getModalValues.purposed_test.focus_live : !getModalValues.focus_live}
                            onChange={(value) => changeHandler(+value, 'focus_live_count', true)}
                            step={1} min={1} max={2000}
                        />
                    </div>
                </div>
                <div className={st.optionBox}>
                    <ToggleCheckbox
                        label={`${localisationMaster('text_17', 'study/study-test', 'modal')}:`}
                        checked={isRequest ? getModalValues.purposed_test.mouse_freeze : getModalValues.mouse_freeze}
                        onChange={(status => changeHandler(status, 'mouse_freeze'))}
                    />
                    <div className={st.optionsNumber}>
                        <DummyCounter
                            value={isRequest ? getModalValues.purposed_test.mouse_freeze_during : getModalValues.mouse_freeze_during}
                            disabled={isRequest ? !getModalValues.purposed_test.mouse_freeze : !getModalValues.mouse_freeze}
                            onChange={(value) => changeHandler(+value, 'mouse_freeze_during', true)}
                            step={1} min={1} max={2000}
                        />
                    </div>
                </div>
                <div className={`${st.optionBox} ${st.optionBoxFs}>`}>
                    <span
                        className={st.lineText}>{localisationMaster('text_14', 'study/study-test', 'modal')}:</span>
                    <div className={st.optionsNumber}>
                        <DummyCounter
                            value={isRequest ? getModalValues.purposed_test.mouse_freeze_count : getModalValues.mouse_freeze_count}
                            disabled={isRequest ? !getModalValues.purposed_test.mouse_freeze : !getModalValues.mouse_freeze}
                            onChange={(value) => changeHandler(+value, 'mouse_freeze_count', true)}
                            step={1} min={1} max={2000}
                        />
                    </div>
                </div>
            </div>
            <div className={st.order}>
                <FormLabel>Results display type:</FormLabel>
                <TypeSelector
                    values={[localisationMaster('text_20', 'study/study-test', 'modal'), localisationMaster('text_21', 'study/study-test', 'modal')]}
                    objectChangeHandler={returnTypeSelector}
                    selected={isRequest ? getModalValues.purposed_test.type_show_test === true ? 1 : 2 : getModalValues.type_show_test === true ? 1 : 2}
                    name='type_show_test'/>
            </div>
        </div>
    );
};

export default observer(AssignToGroup);