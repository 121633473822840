import React, {useState} from 'react';
import styles from "./Timer.module.scss"
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {localisationMaster} from "../../../../Localisation/Localisation";

export const Timer = (props) => {
    const [sec, setSec] = useState(props?.timeLeft)

    let left = sec;

    const days = Math.floor(left / 86400);
    left -= days * 86400;

    const hours = Math.floor(left / 3600) % 24;
    left -= hours * 3600;

    const minutes = Math.floor(left / 60) % 60;

    return (
        <CircularProgressbarWithChildren
            className={styles.progressBar}
            maxValue={props?.maxValue || 100}
            value={props?.value || 100}
            counterClockwise={false}
            styles={buildStyles({
                textColor: 'white',
                pathColor: props.color,
                trailColor: 'none'
            })}
        >
            <div className={styles.container}>
                {!props?.isOutdated && (
                    <div className={styles.timerBlock}>
                        <div>
                            <strong className={styles.timerValue}>{days}</strong>
                            <span className={styles.timerLabel}>{localisationMaster('d', 'study/test')}</span>
                        </div>
                        <div>
                            <strong className={styles.timerValue}>{hours}</strong>
                            <span className={styles.timerLabel}>{localisationMaster('ch', 'study/test')}</span>
                        </div>
                        <div>
                            <strong className={styles.timerValue}>{sec < 60 ? 1 : minutes}</strong>
                            <span className={styles.timerLabel}>{localisationMaster('min', 'study/test')}</span>
                        </div>
                    </div>
                )}
                <div className={styles.timerDescription}>{props.description}</div>
            </div>
        </CircularProgressbarWithChildren>
    );
};
